/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DeployPopupComponent } from '../components/deploy-popup/deploy-popup.component';
import { DeployTypes } from '../models/deploy-types.model';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root',
})
export class DeployService {
  private socketSubscription: Subscription;

  private secondsLeft = 15;

  constructor(private webSocketSvc: WebSocketService, private dialog: MatDialog) {}

  startTracking() {
    this.socketSubscription = this.webSocketSvc.sockJsSubject
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (msg: any) => {
          if (!msg) {
            return;
          }

          const obj = JSON.parse(msg);

          if (Object.values(DeployTypes).some((type) => type === obj.type)) {
            this.setSecondsLeft(obj);
            this.openWarningDeployPopup(obj);

            setTimeout(() => {
              this.refreshApp();
            }, this.secondsLeft * 1000);
          }
        },
      });
  }

  stopTracking() {
    this.socketSubscription.unsubscribe();
  }

  private openWarningDeployPopup(socketMsg: any) {
    this.dialog.open(DeployPopupComponent, {
      maxWidth: '400px',
      data: {
        closeBtn: true,
        socketMsg,
      },
    });
  }

  private refreshApp() {
    // For the frontend deploy, just refresh is enough, for backend deploy, on the server, the deploy_page_disabled.html file is changed to deploy_page.html (Error Status 503)
    window.location.reload();
  }

  private setSecondsLeft(socketMsg: any) {
    this.secondsLeft = socketMsg.delay;
  }
}
