/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScreenService {
  private resizeSource = new BehaviorSubject<null>(null);

  public resize$ = this.resizeSource.asObservable();

  largePixels = 780;

  screenWidth: number;

  screenHeight: number;

  constructor() {
    try {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      window.addEventListener('resize', (event) => this.onResize(event));
    } catch (e) {
      console.log(e);
    }
  }

  private onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.resizeSource.next(null);
  }

  isLarge(): boolean {
    return this.screenWidth >= this.largePixels;
  }
}
