<div class="dialog-title">
  <h2 mat-dialog-title>Hi {{ user.firstName }} {{ user.lastName }}, how can we help you?</h2>

  <button mat-button mat-dialog-close class="close-btn-icon">
    <i class="material-icons">close</i>
  </button>
</div>

<mat-dialog-content class="content-block">
  <div class="help-center-section" *ngIf="showHelpCenterItem">
    <ul class="common-questions-list">
      <li class="common-questions-list__item">
        <a routerLink="/dashboard/articles" (click)="closeSupportModal()">{{ projectInfo.name }} Help Center</a>
      </li>
    </ul>
  </div>

  <div class="action-list-section">
    <ul class="actions-list">
      <li class="actions-list__item">
        <div class="item-icon">
          <img src="/assets/img/supportIcon1.png" height="55vmin" width="70vmin" />
        </div>

        <div class="item-content">
          <div class="item-title">Need system support ?</div>
          <span class="item-link" (click)="openContactUsModal('support')">Contact us</span>
        </div>
      </li>

      <li *ngIf="showContactUsItem" class="actions-list__item">
        <div class="item-icon">
          <img src="/assets/img/supportIcon2.png" height="55vmin" width="70vmin" />
        </div>

        <div class="item-content">
          <div class="item-title">Need to speak to a {{ projectInfo.name }} Specialist ?</div>
          <span class="item-link" (click)="openContactUsModal('utmc')">Contact us</span>
        </div>
      </li>
    </ul>
  </div>
</mat-dialog-content>
