<cms-modal-layout>
  <span title>CMS Application is out of date</span>

  <div content>
    CMS Application has been upgraded and requires to be reloaded.<br /><br />
    You will have {{ secondsLeft }} seconds to save your work and then you will be logged out of the
    CMS. Please click <b>OK</b> to proceed.
  </div>

  <span closeBtnTitle>OK</span>
</cms-modal-layout>
