import { Injectable } from '@angular/core';
import { RootModuleType } from 'src/app/models/root-module.model';
import { Observable } from 'rxjs';
import { ClientDocumentListResponse } from 'src/app/cm/modules/client-documents/models/client-document-response.model';
import { ApiService } from 'src/app/services/api.service';
import { ScreenService } from './screen.service';
import { MenuType } from 'src/app/app.menu';

export interface MenuItem {
  materialIcon: string;
  text: string;
  route?: string;
  modal?: { component: any; config: any };
  activeLinks?: string[];
  activeBy?: {
    path: string;
    afterPaths: string[];
    doesNotInclude?: string;
  }[];
  cssClass?: string;
  callback?: any;
  rootModule?: RootModuleType;
  type: MenuType;
  subMenu?: MenuItem[];
  accessRules?: any;
}

@Injectable()
export class MenuService {
  items: MenuItem[];

  isMinimized: boolean;

  constructor(
    public screenService: ScreenService,
    private apiService: ApiService,
  ) {
    screenService.resize$.subscribe(() => {
      this.isMinimized = !screenService.isLarge();
    });
  }

  toggleMenu(): void {
    this.isMinimized = !this.isMinimized;
  }

  getOCRGLink(params: {
    email: string;
    firstName: string;
    lastName: string;
  }): Observable<ClientDocumentListResponse> {
    return this.apiService.post('ocrg/auth-user/sso', params);
  }

  getAccessRulesByType(type: string, items: MenuItem[], parentAccessRules?: any): any | null {
    let accessRulesChain = [...(parentAccessRules ?? [])];
    for (const item of items) {
      if (item.accessRules) {
        accessRulesChain.push(item.accessRules)
      }
      if (item.type === type) {
        return accessRulesChain;
      }
      if (item.subMenu) {
        const result = this.getAccessRulesByType(type, item.subMenu, accessRulesChain)

        if (result) {
          return result;
        }

        accessRulesChain = [];
      }

      accessRulesChain = [];
    }
    return null;
  }
}
