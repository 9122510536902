<div class="title-and-title-info">
  <cm-title-bar
    [title]="headerService.currentTitle"
    [titleChain]="headerService.titleChain"
    [titlecase]="headerService.titlecase"
  ></cm-title-bar>

  <div class="after-title-info" [innerHTML]="headerService.afterTitleInfo || ''"></div>
</div>

<div class="d-flex align-items-center">
  <cm-info-bar [fields]="headerService.infoFields"></cm-info-bar>
  <cm-action-buttons-bar
    [buttons]="headerService.actionButtons"
    [isLarge]="screenService.isLarge()"
  ></cm-action-buttons-bar>
</div>
