/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// eslint-disable-next-line import/no-cycle
import { CmPopupComponent } from '../cm-popup/cm-popup.component';

export class DialogConfig extends MatDialogConfig {
  data: DialogData;
}

export interface DialogData {
  actions?: DialogAction[];
  confirm?: () => {};
  cancel?: () => {};
  title: string;
  content: string;
  titleClass?: string;
  isNotification?: boolean;
}

export interface DialogAction {
  title: string;
  action: () => any;
  isFocus?: boolean;
  id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(public dialog: MatDialog) {}

  openDialog(config?: DialogConfig) {
    const dialogRef = this.dialog.open(CmPopupComponent, config);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result ${result}`);
    });

    return dialogRef;
  }

  openWarningDialog(content: string, isNotification?: boolean) {
    const matDialogRef = this.openDialog(<DialogConfig>{
      maxWidth: '750px',
      data: {
        actions: [
          {
            title: 'Ok',
            action: () => {
              matDialogRef.close();
            },
            isFocus: true,
          },
        ],
        title: 'Warning',
        content,
        isNotification,
        titleClass: !isNotification ? 'warning' : '',
      },
    });
    return matDialogRef;
  }
}
