import { Injectable } from '@angular/core';
import { IsUserRegistered } from 'src/app/models/register/is-user-registered.model';
import { RegisterPayload } from 'src/app/models/register/register-payload.model';
import { ApiService } from '../api.service';

@Injectable()
export class RegisterService {
  constructor(private apiSvc: ApiService) {}

  isUserRegistered(token: string) {
    const url = `invites?token=${token}`;
    return this.apiSvc.get<IsUserRegistered>(url, {}, false);
  }

  register(payload: RegisterPayload) {
    const url = 'users/register';
    return this.apiSvc.post(url, payload, {}, false);
  }
}
