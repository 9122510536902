/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { CmInputComponent } from 'form-controls';
import { takeUntil } from 'rxjs/operators';
import { IsUserRegistered } from 'src/app/models/register/is-user-registered.model';
import { RegisterPayload } from 'src/app/models/register/register-payload.model';
import { RegisterService } from 'src/app/services/general/register.service';
import { MetaService } from 'src/app/services/meta.service';
import { ComponentDestroyClass } from 'src/app/shared/classes/component-destroy.class';
import {
  checkLoginMail,
  checkPasswords,
  checkPasswordSpaces,
  customPatternValidator,
  customRequiredValidator,
  isValidPin,
} from 'src/app/shared/utils/form-validators';
import { environment } from 'src/environments/environment';
import { PrivatePolicyComponent } from './modals/private-policy/private-policy.component';
import { TermsUseAgreementComponent } from './modals/terms-use-agreement/terms-use-agreement.component';
import { TutorialComponent } from './modals/tutorial/tutorial.component';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'cms-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends ComponentDestroyClass implements OnInit, OnDestroy {
  @ViewChild('passwordControl', { static: false }) passwordControl: CmInputComponent;

  @ViewChild('repeatpassControl', { static: false }) repeatpassControl: CmInputComponent;

  isRegistered: boolean;

  form: FormGroup;

  loading = false;

  error: string = null;

  passwordVisibility = false;

  repeatpassVisibility = false;

  showPasswordValidationRules = false;

  projectName = environment.project.name;

  private readonly routeQueryParams: Params;

  private readonly dataRegistered: IsUserRegistered;

  private readonly REQUIRED_MESSAGE = 'The field should be filled in';

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private metaSvc: MetaService,
    private notificationSvc: NotificationsService,
    private router: Router,
    private registerSvc: RegisterService,
    private projectService: ProjectService,
  ) {
    super();
    this.routeQueryParams = this.route.snapshot.queryParams;
    this.dataRegistered = this.route.snapshot.data.registered;
    this.isRegistered = this.dataRegistered.isUserRegistered;
  }

  ngOnInit(): void {
    this.checkRegistrationStatus();
    this.metaSvc.setTitle('Registration');
    this.buildForm();
    this.subscribePasswordChange();
    this.formPatchValue();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get registerBtnDisabled() {
    return this.loading || !this.form.valid;
  }

  get passwordErrors() {
    return this.form.get('password').errors || {};
  }

  get showRegistrationVideoTutorialLink() {
    return this.projectService.validAccessRuleByProject(
      this.projectService.accessRules.common.register.show.registration_video_tutorial_link,
    );
  }

  onSubmit() {
    this.loading = true;
    const formValue = this.form.value;

    this.registerSvc
      .register(this.formToPayload(formValue))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (response: any) => {
          this.notificationSvc.success(
            'Success',
            'You are successfully registered! Enter your username and password to login.',
          );

          this.router
            .navigate(['login'])
            .then(() => (this.loading = false))
            .catch((err) => console.error(err));
        },
        error: (err: any) => {
          console.error(err);
          this.notificationSvc.error('Warning', err.message);
          this.loading = false;
        },
      });
  }

  onChangePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
    const passwordEl: HTMLInputElement = this.passwordControl.inputElement.nativeElement;
    passwordEl.focus();
    this.putCursorEndText(passwordEl, this.form.get('password'));
  }

  onChangeRepeatpassVisibility() {
    this.repeatpassVisibility = !this.repeatpassVisibility;
    const passwordEl: HTMLInputElement = this.repeatpassControl.inputElement.nativeElement;
    passwordEl.focus();
    this.putCursorEndText(passwordEl, this.form.get('repeatpass'));
  }

  onPasswordFocus() {
    this.showPasswordValidationRules = true;
  }

  onOpenTermsUseAgreement() {
    this.dialog.open(TermsUseAgreementComponent, {
      maxWidth: '600px',
      closeOnNavigation: true,
      data: {
        closeBtn: true,
      },
    });
  }

  onOpenPrivacyPolicy() {
    this.dialog.open(PrivatePolicyComponent, {
      maxWidth: '600px',
      closeOnNavigation: true,
      data: {
        closeBtn: true,
      },
    });
  }

  openRegistrationTutorialModal() {
    this.dialog.open(TutorialComponent, {
      closeOnNavigation: true,
      panelClass: 'modal-video',
    });
  }

  private formToPayload(formValue: any): RegisterPayload {
    return {
      login: formValue.login,
      pin: formValue.pin,
      password: formValue.password,
      email: formValue.email,
      token: this.routeQueryParams.token,
    };
  }

  private checkRegistrationStatus() {
    const registrationStatus = this.dataRegistered.status;

    if (registrationStatus === 'REGISTERED') {
      this.notificationSvc.success(
        'Information',
        'User for this invite has already been registered. Please login.',
      );

      this.router.navigate(['login']);
    } else if (registrationStatus === 'MAIL_SENT' && this.isRegistered) {
      this.router.navigate(['new-client'], { queryParams: this.route.queryParams });
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      login: [
        '',
        {
          validators: [
            customRequiredValidator(this.REQUIRED_MESSAGE),
            Validators.minLength(6),
            Validators.maxLength(24),
            customPatternValidator('^[A-Za-z0-9_.-]{6,24}$', 'Wrong format'),
          ],
        },
      ],
      pin: ['', { validators: [customRequiredValidator(this.REQUIRED_MESSAGE), isValidPin] }],
      check2: [false, { validators: [Validators.requiredTrue] }], // required true
      email: [
        '',
        {
          validators: [
            customRequiredValidator(this.REQUIRED_MESSAGE),
            customPatternValidator(
              /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
              'Email is not valid',
            ),
          ],
        },
      ],
      password: [
        '',
        {
          validators: [
            customRequiredValidator(this.REQUIRED_MESSAGE),
            customPatternValidator(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#!%*?&_^\[\]\\>=<;:/\-,+()'"])([A-Za-z\d$@#!%*?&_^\[\]\\>=<;:/\-,+()'"]*)+$/,
              'Wrong format',
            ),
            Validators.minLength(8),
            Validators.maxLength(24),
            checkLoginMail,
            checkPasswordSpaces,
          ],
        },
      ],
      repeatpass: [
        '',
        { validators: [customRequiredValidator(this.REQUIRED_MESSAGE), checkPasswords] },
      ],
    });
  }

  private putCursorEndText(element: HTMLInputElement, formControl: AbstractControl) {
    const { value } = formControl;
    const len = (value && value.length) || 0;
    setTimeout(() => {
      element.setSelectionRange(len, len);
    }, 0);
  }

  private formPatchValue() {
    this.form.patchValue({
      email: this.routeQueryParams.email,
      pin: this.routeQueryParams.pin || '',
    });
  }

  private subscribePasswordChange() {
    this.form
      .get('password')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: () => {
          this.form.get('repeatpass').updateValueAndValidity({ onlySelf: true });
        },
      });
  }
}
