<div class="cm-title" *ngIf="!titleChain">
  {{ title }}
</div>

<div class="cm-title" *ngIf="titleChain">
  <div
    class="title-item"
    [class.primary]="last"
    *ngFor="let title of titleChain; let last = last; let first = first"
  >
    <i class="material-icons" [class.primary]="last" *ngIf="!first">chevron_right</i
    ><span>
      {{ title  }}
    </span>
  </div>
</div>
