/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { LoginResponse } from '../models/login/login-response.model';
import { ApiService } from '../services/api.service';
import { AppService } from '../services/app.service';
import { LogoutService } from '../services/logout.service';
import { SessionService } from '../services/session.service';
import { REQUEST_AUTH_HEADER_KEY } from '../shared/app.const';

@Injectable()
export class RefreshSessionInterceptor implements HttpInterceptor {
  private sessionAlreadyRefreshed = false;

  private refreshSessionSubject: Subject<string>;

  constructor(
    private sessionSvc: SessionService,
    private apiSvc: ApiService,
    private logoutSvc: LogoutService,
    private appSvc: AppService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((reqError: HttpErrorResponse) => {
        if (this.requestNeedsRefreshSession(req, reqError)) {
          return this.refreshSessionSubject.pipe(
            switchMap((accessToken: string) =>
              accessToken
                ? next.handle(this.updateAuthHeader(req, accessToken))
                : throwError(reqError),
            ),
          );
        }

        if (this.noNeedToRefreshSession(req, reqError)) {
          return throwError(reqError);
        }

        // if the refresh token is not set, then the refresh token request cannot be made and
        // in this case the user is thrown on the login page to create a new session
        if (!this.sessionSvc.refreshToken) {
          this.appSvc.goToLogin();
          return throwError(reqError);
        }

        this.sessionAlreadyRefreshed = true;
        this.initRefreshSessionSubject();

        return this.refreshSession().pipe(
          switchMap((response: LoginResponse) => {
            this.setNewSession(response);
            this.sessionAlreadyRefreshed = false;
            this.completeRefreshSessionSubject(response.access_token);

            return next.handle(this.updateAuthHeader(req, response.access_token));
          }),
          catchError((refreshSessionError: HttpErrorResponse) => {
            this.sessionAlreadyRefreshed = false;
            this.completeRefreshSessionSubject(null);
            this.appSvc.goToLogin();

            return throwError(reqError);
          }),
          finalize(() => {
            this.sessionAlreadyRefreshed = false;
          }),
        );
      }),
    );
  }

  private conditionForRefreshToken(err: HttpErrorResponse) {
    const apiError = this.apiSvc.errorHandler(err);

    return apiError.status === 401 && apiError.error === 'invalid_token';
  }

  private updateAuthHeader(req: HttpRequest<any>, token: string) {
    return req.clone({ headers: req.headers.set(REQUEST_AUTH_HEADER_KEY, `Bearer ${token}`) });
  }

  private refreshSession() {
    const url = 'users/token';

    return this.apiSvc.get(
      url,
      {
        params: {
          token: `${this.sessionSvc.refreshToken}`,
        },
      },
      false,
    );
  }

  private isLogoutReq(reqUrl: string) {
    return reqUrl.includes(this.logoutSvc.BASE_URL);
  }

  private noNeedToRefreshSession(req: HttpRequest<any>, reqError: HttpErrorResponse) {
    return (
      !this.conditionForRefreshToken(reqError) ||
      this.sessionAlreadyRefreshed ||
      this.isLogoutReq(req.url)
    );
  }

  private requestNeedsRefreshSession(req: HttpRequest<any>, reqError: HttpErrorResponse) {
    return (
      this.conditionForRefreshToken(reqError) &&
      this.sessionAlreadyRefreshed &&
      !this.isLogoutReq(req.url)
    );
  }

  private setNewSession(response: LoginResponse) {
    this.sessionSvc.token = response.access_token;
    this.sessionSvc.tokenExpiresIn = response.expires_in;
  }

  private initRefreshSessionSubject() {
    this.refreshSessionSubject = new Subject();
  }

  private completeRefreshSessionSubject(accessToken: string) {
    this.refreshSessionSubject.next(accessToken);
    this.refreshSessionSubject.complete();
    this.refreshSessionSubject = null;
  }
}
