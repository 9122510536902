import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner-rect',
  templateUrl: 'spinner-rect.component.html',
  styleUrls: ['spinner-rect.component.scss'],
})
export class SpinnerRectComponent {
  @Input() color: 'primary';
}
