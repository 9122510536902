import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-private-policy',
  templateUrl: './private-policy.component.html',
  styleUrls: ['./private-policy.component.scss'],
})
export class PrivatePolicyComponent {
  projectInfo = environment.project;
}
