import { Injectable } from '@angular/core';
import { MatomoService } from 'form-controls';
import { MeResponse } from '../models/profile/me-response.model';
import { ApiService } from './api.service';
import { Base64 } from '../models/base64.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private currentUser: MeResponse;

  private readonly BASE_URL = 'users/me';

  constructor(private apiSvc: ApiService, private matomoSvc: MatomoService) {}

  set me(me: MeResponse) {
    this.matomoSvc.setUserId(me.email);
    this.matomoSvc.setCustomVariable(1, 'username', me.login);
    this.currentUser = me;
  }

  get user() {
    return this.currentUser;
  }

  get roles() {
    return this.user.roles;
  }

  get rolesNames() {
    return this.roles.map((item) => item.name);
    // return [UserIs.PARTNER, UserIs.INTERNAL];
  }

  get tags() {
    return this.user.refs || [];
  }

  getMe() {
    return this.apiSvc.get<MeResponse>(this.BASE_URL);
  }

  getDefaultAvatar() {
    const url = `${this.BASE_URL}/avatar/default`;
    return this.apiSvc.get<Base64>(url);
  }

  deleteUserAvatar() {
    const url = `${this.BASE_URL}/avatar`;
    return this.apiSvc.delete<Base64>(url);
  }

  updateUserAvatar(avatarSrc) {
    const url = `${this.BASE_URL}/avatar`;

    const bodyString = JSON.stringify({
      base64Data: avatarSrc,
    });

    return this.apiSvc.put(url, bodyString, { headers: { 'Content-Type': 'application/json' } });
  }

  setUserAvatar(avatarSrc) {
    const url = `${this.BASE_URL}/avatar`;

    const body = JSON.stringify({
      base64Data: avatarSrc,
    });

    return this.apiSvc.post(url, body, { headers: { 'Content-Type': 'application/json' } });
  }
}
