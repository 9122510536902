/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { HttpOptions } from 'src/app/models/http-options.model';
import { LoginPayload } from 'src/app/models/login/login-payload.model';
import { LoginResponse } from 'src/app/models/login/login-response.model';
import { RememberMeData } from 'src/app/models/login/remember-me-data.model';
import { ApiService } from '../api.service';
import { StorageService } from '../storage.service';

@Injectable()
export class LoginService {
  private readonly LS_REMEMBER_ME_DATA_KEY = 'remember.me.data';

  constructor(private apiSvc: ApiService, private storageSvc: StorageService) {}

  get rememberMeData(): RememberMeData {
    return this.storageSvc.get(this.LS_REMEMBER_ME_DATA_KEY);
  }

  set rememberMeData(value: RememberMeData) {
    this.storageSvc.set(this.LS_REMEMBER_ME_DATA_KEY, value);
  }

  login(body: LoginPayload): Observable<LoginResponse> {
    const url = 'users/login';
    const options: HttpOptions = {
      headers: {
        'Content-Type': 'application/json',
        UserDateTime: moment().format(),
      },
    };

    return this.apiSvc.post<LoginResponse>(url, body, options, false);
  }

  removeRememberMeData() {
    this.storageSvc.removeItem(this.LS_REMEMBER_ME_DATA_KEY);
  }
}
