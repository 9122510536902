<div class="title-bar">
  <div
    class="title"
    [class.expanded]="expanded"
    [class.expandable]="!!menuItems?.length"
    [ngClass]="!!menuItems?.length ? 'expandable' : 'not-expandable'"
    (click)="!!menuItems?.length && (expanded = !expanded)"
  >
    {{ title }}
    <i class="material-icons rotated-down" *ngIf="!!menuItems?.length" [class.rotated-up]="expanded">play_arrow</i>
  </div>
  <div class="menu-panel" *ngIf="!!menuItems?.length && expanded">
    <div *ngFor="let item of menuItems" class="menu-item title" (click)="onMenuItemClick(item)">
      {{ item.title }}
    </div>
  </div>
</div>
