/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { MeResponse } from '../models/profile/me-response.model';
import { AppService } from '../services/app.service';
import { ProfileService } from '../services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard implements CanActivate {
  constructor(private profileSvc: ProfileService, private appSvc: AppService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.profileSvc.getMe().subscribe({
        next: (response: MeResponse) => {
          this.profileSvc.me = response;
          resolve(true);
        },
        error: (err: any) => {
          console.error('USER PROFILE IS NOT GET', err);
          this.appSvc.goToLogin();
          resolve(false);
        },
      });
    });
  }
}
