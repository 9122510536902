import { Injectable } from '@angular/core';
import { MatomoService } from 'form-controls';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  readonly BASE_URL = 'users/logout';

  constructor(
    private apiSvc: ApiService,
    private matomoSvc: MatomoService,
    private sessionSvc: SessionService,
    private appSvc: AppService,
  ) {}

  logout(matomoMessage: string) {
    this.logoutRequest().subscribe({
      next: () => {
        console.log('SUCCESSFULLY LOGOUT');
      },
      error: (err: any) => {
        console.error('LOGOUT REQUEST ERROR', err);
      },
    });

    this.matomoSvc.trackEvent({
      category: `Logout`,
      action: `Logout time: ${new Date()} [${matomoMessage}], Token: ${
        this.sessionSvc.token || 'No token'
      }`,
    });

    this.appSvc.goToLogin();
  }

  private logoutRequest() {
    return this.apiSvc.get(this.BASE_URL);
  }
}
