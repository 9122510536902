/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { isEqual } from 'lodash';
import { FormControlsService } from '../services/form-controls.service';

@Component({
  selector: 'lib-cm-select',
  templateUrl: './cm-select.component.html',
  styleUrls: ['./cm-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmSelectComponent),
      multi: true,
    },
  ],
})
export class CmSelectComponent implements ControlValueAccessor {
  val: any;

  itemsList;

  placeholderText: string;

  onChange: (val) => {};

  onTouched: (val) => {};

  @Output() selectionChange = new EventEmitter<MatSelectChange>();

  @Input() formControl: FormControl;

  @Input() highlightIfSet: boolean;

  @Input() clearable: boolean;

  @Input() deselectable = true;

  @Input() disabled: boolean;

  @Input() viewValue: string;

  @Input() valueForRequest: string;

  @Input() keyValue: string;

  @Input() defaultValue: any;

  @Input() placeholderDisabled = false;

  @Input() panelClass: string | string[] | Set<string> | { [key: string]: any };

  // this is for compare values for old cases (some cases have fields type string, when they would be number)
  @Input() compareWith: (fromOption: any, fromSelection: any) => boolean = (
    fromOption: any,
    fromSelection: any,
  ) => {
    if (typeof fromSelection === 'string' && /^\d+$/.test(fromSelection)) {
      return fromSelection == fromOption;
    }

    return isEqual(fromOption, fromSelection);
  };

  @Input() set placeholder(value: string) {
    this.placeholderText = value || 'Select Item';
  }

  get placeholder() {
    return this.placeholderText;
  }

  @Input() set collection(collection) {
    this.itemsList = collection;
  }

  @Input() set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;

      if (this.onChange) {
        this.onChange(val);
      }

      if (this.onTouched) {
        this.onTouched(val);
      }
    }
  }

  get value() {
    return this.val;
  }

  constructor(public formControlsService: FormControlsService) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    if (this.keyValue) {
      this.val = value;
      return;
    }

    if (typeof value !== 'string' && value !== null && value !== undefined) {
      this.value = this.itemsList ? this.itemsList.find((item) => isEqual(item, value)) : '';
    } else {
      this.value = value;
    }
  }

  reset() {
    if (!this.disabled) {
      const setValue = this.defaultValue || '';

      if (this.keyValue) {
        this.value = setValue;
      }

      this.writeValue(setValue);
    }
  }

  getOptionValue(option: any) {
    return this.keyValue
      ? option[this.keyValue]
      : this.valueForRequest
      ? option[this.valueForRequest]
      : option;
  }

  get showCloseIcon() {
    return this.clearable && !this.isPlaceholder;
  }

  get isPlaceholder() {
    return (
      !this.value ||
      (!this.deselectable &&
        this.defaultValue !== undefined &&
        isEqual(this.value, this.defaultValue))
    );
  }
}
