/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Host,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { EMPTY, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FORM_ERRORS } from 'src/app/shared/form-errors';
import { FormSubmitDirective } from './form-submit.directive';
import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';

@Directive({
  selector: '[formControl], [formControlName]',
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  @Input() titlecase = true;

  controlValueChangesSubscription: Subscription;

  submit$: Observable<Event>;

  ref: ComponentRef<ControlErrorComponent>;

  container: ViewContainerRef;

  private setErrorTimeout: NodeJS.Timeout;

  constructor(
    @Optional() @Host() private form: FormSubmitDirective,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Self() private control: NgControl,
    @Inject(FORM_ERRORS) private errors,
    private resolver: ComponentFactoryResolver,
    private vcr: ViewContainerRef,
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : this.vcr;

    if (this.form) {
      this.submit$ = this.form.submit$ ? this.form.submit$ : EMPTY;
    }
  }

  ngOnInit(): void {
    this.controlValueChangesSubscription = merge(
      this.form ? this.form.submit$ : new Observable(),
      this.control.valueChanges,
      this.control.statusChanges,
      this.updateErrors$ || of(),
    )
      .pipe(debounceTime(50))
      .subscribe(() => {
        const controlErrors = this.control.errors;

        this.clearSetErrorTimeout();

        this.setErrorTimeout = setTimeout(() => {
          if (controlErrors) {
            const firstKey = Object.keys(controlErrors)[0];
            const getError = this.errors[firstKey];

            if (this.control.dirty && this.control.touched && typeof getError === 'function') {
              const text = getError(controlErrors[firstKey]);
              this.setError(text);
            }
          } else if (this.ref) {
            this.setError(null);
          }
        }, 0);
      });
  }

  setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory, 0);
    }
    this.ref.instance.text = text;
    this.ref.instance.titlecase = this.titlecase;
  }

  ngOnDestroy(): void {
    this.clearSetErrorTimeout();
    this.controlValueChangesSubscription && this.controlValueChangesSubscription.unsubscribe();

    if (this.updateErrors$) {
      this.updateErrors$.complete();
    }
  }

  private get updateErrors$() {
    return (this.control.control as any)?.updateErrors$ as Subject<any>;
  }

  private clearSetErrorTimeout() {
    if (this.setErrorTimeout) {
      clearTimeout(this.setErrorTimeout);
    }
  }
}
