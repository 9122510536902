import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorsDirective } from './directives/control-errors/control-errors.directive';
import { FormSubmitDirective } from './directives/control-errors/form-submit.directive';
import { ControlErrorContainerDirective } from './directives/control-errors/control-error-container.directive';
import { ControlErrorComponent } from './directives/control-errors/control-error/control-error.component';

@NgModule({
  declarations: [
    ControlErrorsDirective,
    FormSubmitDirective,
    ControlErrorContainerDirective,
    ControlErrorComponent,
  ],
  imports: [CommonModule],
  exports: [
    ControlErrorsDirective,
    FormSubmitDirective,
    ControlErrorContainerDirective,
    ControlErrorComponent,
  ],
  providers: [],
  entryComponents: [ControlErrorComponent],
})
export class ControlsErrorsModule {}
