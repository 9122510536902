import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AiSocketMessages } from '../cms/modules/action-items/models/ai.model';
import { UserIs } from '../models/user-is.model';
import { Statuses } from '../shared/app.const';
import { AppService } from './app.service';
import { AuthorizationService } from './authorization.service';
import { ProfileService } from './profile.service';
import { StorageService } from './storage.service';
import { WebSocketService } from './web-socket.service';

@Injectable({ providedIn: 'root' })
export class ReceiveNewActionItemsService {
  newActionItemsCount = 0;

  private receiveSubscription: Subscription;

  private aisObj = {};

  constructor(
    private webSocketSvc: WebSocketService,
    private appSvc: AppService,
    private storageSvc: StorageService,
    private authorizationSvc: AuthorizationService,
    private profileSvc: ProfileService,
  ) {}

  get userIsInternal() {
    return this.authorizationSvc.userIs([UserIs.INTERNAL]);
  }

  get defaultParams() {
    return {
      skip: 0,
      take: 10,
      sortField: this.userIsInternal ? 'dueDateExternal' : 'dueDateInternal',
      sortDirection: 'asc',
      status: this.userIsInternal ? Statuses.REVIEW : Statuses.CLIENT,
      assign:
        (this.authorizationSvc.userIs([UserIs.INTERNAL.ANALYST]) &&
          this.profileSvc.user.analyst &&
          this.profileSvc.user.id) ||
        '',
    };
  }

  receive() {
    const messageTypes = [
      AiSocketMessages.ACTION_ITEM_CREATE,
      AiSocketMessages.ACTION_ITEM_UPDATE,
      // AiSocketMessages.ACTION_ITEM_DELETE,
    ];

    this.receiveSubscription = this.webSocketSvc.sockJsSubject
      .pipe(distinctUntilChanged())
      .subscribe((msg: any) => {
        if (!msg) {
          return;
        }

        const obj = JSON.parse(msg);

        if (messageTypes.some((type) => type === obj.type)) {
          if (this.appSvc.openPageIsDashboard() || !this.checkByFilters(obj.actionItemDto)) {
            this.resetNewActionItemsCount();
            return;
          }

          // to prevent duplicates
          if (obj.actionItemDto?.id) {
            this.aisObj[obj.actionItemDto?.id] = obj.type;
            this.newActionItemsCount = Object.keys(this.aisObj).length;
          }
        }
      });
  }

  unsubscribeReceive() {
    this.resetNewActionItemsCount();

    if (this.receiveSubscription) {
      this.receiveSubscription.unsubscribe();
    }
  }

  resetNewActionItemsCount() {
    this.aisObj = {};
    this.newActionItemsCount = 0;
  }

  private checkByFilters(ai: any) {
    const sfilters = this.storageSvc.get('table.ai.list', 'SS');
    let filters = this.defaultParams;

    if (sfilters && typeof sfilters === 'object') {
      filters = sfilters;
    }

    const result = [];

    result.push(filters.status === ai.status);

    return result.every((item) => item);
  }
}
