/* eslint-disable import/no-cycle */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../services/popup.service';
import { MatomoService } from '../services/matomo.service';

@Component({
  selector: 'lib-cm-popup',
  templateUrl: './cm-popup.component.html',
  styleUrls: ['./cm-popup.component.css'],
})
export class CmPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matomoService: MatomoService,
  ) {}

  trackAction(button: { title: string }): void {
    this.matomoService.trackEvent({
      category: this.data.title,
      action: `${this.data.content} Action:${button.title}`,
    });
  }
}
