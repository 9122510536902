<div>
    <div *ngIf="isLarge" class="buttons-container">
        <ng-container *ngFor="let button of buttons">
            <lib-utmc-button
                *ngIf="button"
                [color]="button.color"
                (onClick)="button.onClick()"
                [class]="'action-button ' + (button.class || '')"
                [disabled]="button.disabled && button.disabled()"
                [hidden]="button.hidden && button.hidden()"
                [triggerId]="button.id"
                [loading]="button.loading"
                [title]="button.title"
                matomoTrigger
            >
                <ng-container *ngIf="!button.loading">
                    <i *ngIf="button.icon" class="material-icons">{{ button.icon }}</i>
                    <span *ngIf="button.titlecase === false; else dButtonLabel" class="button-label">{{ button.label }}</span>
                    <ng-template #dButtonLabel>
                        <span class="button-label">{{ button.label }}</span>
                    </ng-template>
                </ng-container>
            </lib-utmc-button>
        </ng-container>
    </div>
    <div class="minimized" *ngIf="!isLarge">
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <i class="material-icons">more_vert</i>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let button of buttons">
                <div
                    mat-menu-item
                    class="button-item"
                    *ngIf="button"
                    style="display: flex; align-items: center; justify-content: center"
                >
                    <lib-utmc-button
                        [color]="button.color"
                        (onClick)="button.onClick()"
                        [class]="'action-button'"
                        [disabled]="button.disabled && button.disabled()"
                        [hidden]="button.hidden && button.hidden()"
                        [triggerId]="button.id"
                        matomoTrigger
                    ><i *ngIf="button.icon" class="material-icons">{{ button.icon }}</i>
                        <span class="button-label">{{ button.label | titlecase }}</span></lib-utmc-button
                    >
                </div>
            </ng-container>
        </mat-menu>
    </div>
</div>
