<cms-modal-video>
  <div content>
    <iframe
      src="https://www.youtube.com/embed/xvjt6S1gK7s?rel=0&modestbranding=1&showinfo=0"
      frameborder="0"
      width="1280"
      height="720"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</cms-modal-video>
