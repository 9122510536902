import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ClaimantSearchReqQueryParams } from '../models/claimant-search-req-query-params.model';
import { ClaimantSearchResponse } from '../models/claimant-search-response.model';

@Injectable()
export class CmsClaimantService {
  private readonly BASE_URL: string = 'claimants';

  constructor(private apiSvc: ApiService) {}

  searchClaimant(params?: ClaimantSearchReqQueryParams): Observable<ClaimantSearchResponse[]> {
    return this.apiSvc.get(`${this.BASE_URL}/search`, { params: params as any });
  }

  getClaimantItem(id: number) {
    return this.apiSvc.get(`${this.BASE_URL}/${id}`);
  }

  updateClaimant(body: ClaimantSearchResponse) {
    return this.apiSvc.put(`${this.BASE_URL}/${body.id}`, body);
  }
}
