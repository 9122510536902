/* eslint-disable default-case */
/* eslint-disable class-methods-use-this */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[restrictPattern]',
})
export class RestrictPatternDirective {
  @Input() restrictPattern: boolean;

  inputElement: HTMLElement;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  constructor(private element: ElementRef) {
    this.inputElement = this.element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (!this.restrictPattern) {
      return;
    }

    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      this.isShortCutCommand(e) // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X, Cmd+A (Mac), Cmd+C (Mac), Cmd+V (Mac), Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    const regExp = new RegExp('[ -~]');

    if (!e.key.match(regExp)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (!this.restrictPattern) {
      return;
    }

    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/[^ -~]/g, ''); // get a string only with valid symbols
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (!this.restrictPattern) {
      return;
    }

    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/[^ -~]/g, '');
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }

  isShortCutCommand(e) {
    if (e.ctrlKey || e.metaKey) {
      switch (e.key) {
        case 'a':
        case 'c':
        case 'v':
        case 'x':
          return true;
      }
    }
    return false;
  }
}
