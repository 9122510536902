import { Environment, ProjectIs } from 'src/app/models/projects.model';

export const environment: Environment = {
  production: false,
  isStage: false,
  coreUrl: '',
  matomoSiteId: 9,
  matomoUrlBase: 'https://wd1.urchinsys.com',
  userId: '',
  project: {
    name: 'UTMC',
    abbreviation: ProjectIs.UTMC,
    phone: '(781) 245-5353',
    fax: '(781) 245 3007',
    email: 'get.info@UTMCorp.com',
    address_line_one: 'Lakeside Office Park, Suite 12, P.O. Box 4074',
    address_line_two: 'Wakefield, MA 01880-5374',
    url: 'www.utmcorp.com',
    parentProjectAbbreviation: ProjectIs.UTMC,
  },
};
