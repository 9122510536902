import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerRectComponent } from './spinner-rect.component';

@NgModule({
  declarations: [SpinnerRectComponent],
  imports: [CommonModule],
  exports: [SpinnerRectComponent],
})
export class SpinnerRectModule {}
