<div class="logo-big">
  <img src="assets/app/img/logo_big.png" />
</div>
<div class="password-container">
  <h3 class="text-center">Create Password</h3>
  <div class="form-container">
    <form [formGroup]="form" novalidate (submit)="onSubmit()">
      <div class="margin-bottom">Please check your email for the PIN to be entered below.</div>
      <div class="control">
        <label for="pin">PIN</label>
        <lib-cm-input
          placeholder=""
          formControlName="pin"
          id="pin"
          [titlecase]="false"
        ></lib-cm-input>
      </div>
      <div *ngIf="email" class="link margin-bottom">
        <a (click)="resendPin()">Resend PIN to Email</a>
      </div>
      <div class="control password-input margin-bottom">
        <label for="password">New Password</label>
        <lib-cm-input
          #passwordControl
          placeholder=""
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="password"
          id="password"
          [titlecase]="false"
        ></lib-cm-input>
        <div class="password-visibility" (click)="onChangePasswordVisibility()">
          <span *ngIf="passwordVisibility" class="material-icons"> visibility </span>
          <span *ngIf="!passwordVisibility" class="material-icons"> visibility_off </span>
        </div>
        <span
          [tooltip]="passwordInfo"
          contentType="template"
          placement="right"
          class="material-icons password-info-icon"
          tooltipClass="password-info-message"
        >
          help
        </span>
      </div>

      <div class="control password-input margin-bottom">
        <label for="repeatpass">Re-Enter New Password</label>
        <lib-cm-input
          #repeatpassControl
          placeholder=""
          [type]="repeatpassVisibility ? 'text' : 'password'"
          formControlName="repeatpass"
          id="repeatpass"
          [titlecase]="false"
        ></lib-cm-input>
        <div class="password-visibility" (click)="onChangeRepeatpassVisibility()">
          <span *ngIf="repeatpassVisibility" class="material-icons"> visibility </span>
          <span *ngIf="!repeatpassVisibility" class="material-icons"> visibility_off </span>
        </div>
      </div>

      <button type="submit" class="submit-btn" [ngClass]="{'margin-bottom': !error}" [disabled]="submitBtnDisabled">
        <div class="spinner-rect-container" [class.show]="loading">
          <spinner-rect color="white"></spinner-rect>
        </div>
        <div class="title" [class.not-visible]="loading">Create Password</div>
      </button>

      <div *ngIf="error" class="error-container">
        <span class="error">
          <span class="error-message">
            {{ 'ERROR: ' + error }}
          </span>
        </span>
      </div>
    </form>
  </div>
</div>

<ng-template #passwordInfo>
  <span>
    <b>Password Creation Requirements:</b><br />
    Minimum Length – 8 characters<br />
    Maximum Length – 24 characters<br />
    At least one of each: upper case, lower case, numeric, special character<br />
    Special Characters – exclude spaces<br />
    Passwords are case sensitive<br />
    Cannot contain the Username or Email Address
  </span>
</ng-template>
