import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable()
export class ForgotPasswordService {
  constructor(private apiSvc: ApiService) {}

  forgotPassword(email: string) {
    const url = `users/restore/password?email=${email}`;
    return this.apiSvc.get(url, {}, false);
  }
}
