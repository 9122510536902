import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatomoService } from 'form-controls';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  prevTitle: string;

  constructor(private title: Title, private matomoService: MatomoService) {}

  setTitle(value: string): void {
    this.prevTitle = this.title.getTitle();
    this.title.setTitle(value);
    this.matomoService.trackPageView(value);
  }
}
