import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent {
  @Input() titlecase: boolean;

  errorMessage: string;

  hidden: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() set text(value) {
    if (value !== this.errorMessage) {
      this.errorMessage = value;
      this.hidden = !value;
      this.cdr.detectChanges();
    }
  }
}
