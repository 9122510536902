import { Component, Input } from '@angular/core';
import { RawsInformation } from '../../../app/models/raws-info.model';

@Component({
  selector: 'cm-raws-info-panel',
  templateUrl: './raws-info-panel.component.html',
  styleUrls: ['./raws-info-panel.component.scss'],
})
export class RawsInfoPanelComponent {
  @Input() rawsInfo: RawsInformation[];
}
