import { Injectable, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { from } from 'rxjs';
import { first, map, reduce, tap } from 'rxjs/operators';
import {
  SidebarButton,
  SidebarFilter,
  SidebarResetButton,
} from '../../app/models/sidebar-filter.model';
import { ScreenService } from './screen.service';
import { SidebarActionTab } from '../../app/models/sidebar-action-tab.model';
import { RawsInformation } from '../../app/models/raws-info.model';

@Injectable()
export class SidebarService {
  sidebarFilters: SidebarFilter[];

  template: TemplateRef<any>;

  filtersForm: FormGroup;

  // filterFormControls: FormControl[];
  filterFormControls: { K: FormControl };

  controlEntries: [string, FormControl][];

  actionTabs: SidebarActionTab[];

  rowsInformation: RawsInformation[];

  sidebarButton: SidebarButton;

  sidebarTitle: string;

  sidebarFilterTitle: string;

  sidebarCssClass: string;

  resetButton: SidebarResetButton;

  // tslint:disable-next-line:variable-name
  isMinimized: boolean;

  isLarge: boolean;

  defaultFormState: any;

  sidebarEnabled = true;

  constructor(private screenService: ScreenService) {
    screenService.resize$.subscribe(() => {
      this.isMinimized = !screenService.isLarge();
    });
  }

  toggleSidebar() {
    this.isMinimized = !this.isMinimized;
  }

  setFilters(form: FormGroup) {
    this.filtersForm = form;
    // this.filterFormControls = form ? Object.values(form.controls) as FormControl[] : null;
    this.filterFormControls = form ? (form.controls as { K: FormControl }) : null;
    this.controlEntries = form ? (Object.entries(form.controls) as [string, FormControl][]) : null;
  }

  // eslint-disable-next-line consistent-return
  initFiltersForm(filters: SidebarFilter[]): Promise<FormGroup> {
    this.sidebarFilters = filters;
    if (filters) {
      return from(filters)
        .pipe(
          reduce((acc, { formControl }: SidebarFilter) => {
            acc[formControl.key] = formControl.value;
            return acc;
          }, {}),
          first(),
          tap((controlsConfig) => (this.filterFormControls = controlsConfig as { K: FormControl })),
          map((controlsConfig) => new FormGroup(controlsConfig)),
          tap((formGroup: FormGroup) => {
            this.filtersForm = formGroup;
            const initialFormState = this.filtersForm.value;
            this.filtersForm.reset();
            this.defaultFormState = initialFormState;
            this.filtersForm.patchValue(initialFormState);
            this.filtersForm.markAsDirty();
          }),
        )
        .toPromise();
    }
  }

  resetFiltersForm() {
    this.filtersForm.patchValue(this.defaultFormState);
    this.filtersForm.updateValueAndValidity();
  }
}
