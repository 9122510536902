import { Component, Input } from '@angular/core';
import { SidebarActionTab } from '../../../app/models/sidebar-action-tab.model';

@Component({
  selector: 'cm-action-tabs-panel',
  templateUrl: './action-tabs-panel.component.html',
  styleUrls: ['./action-tabs-panel.component.scss'],
})
export class ActionTabsPanelComponent {
  @Input() actionTabs: SidebarActionTab[];
}
