/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { PopupService } from 'form-controls';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ProfileService } from "src/app/services/profile.service";

@Injectable({
  providedIn: 'root',
})
export class CheckProjectVersionGuard implements CanLoad {
  private dialogRef: MatDialogRef<any>;

  constructor(
    private httpClient: HttpClient,
    private popupSvc: PopupService,
    private profileSvc: ProfileService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkProjectVersion();
  }

  checkProjectVersion() {
    if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
      return of(false);
    }

    return this.httpClient.get('/', { responseType: 'text' }).pipe(
      switchMap((data) => {
        if (this.isScriptHashEqual(data)) {
          return of(true);
        }

        this.showConfirmPageReload();
        return of(false);
      }),
      catchError((err: any) => {
        console.error('CHECK PROJECT VERSION', err);
        return of(true);
      }),
    );
  }

  private isScriptHashEqual(remoteIndexHtml: string) {
    const appReg = /src="runtime\.([A-Za-z0-9]*)\.js\?([0-9]*)"/;
    const execArr = appReg.exec(remoteIndexHtml);

    if (!Array.isArray(execArr)) {
      return true;
    }

    const hash = execArr[1];
    const hash2 = execArr[2];
    const scriptSrc = `runtime.${hash}.js?${hash2}`;
    const scripts = Array.from(document.getElementsByTagName('script'));

    return scripts.find((script) => script.src.includes(scriptSrc));
  }

  private showConfirmPageReload() {
    this.dialogRef = this.popupSvc.openWarningDialog(
      'The page has been updated and will be refreshed',
    );

    this.dialogRef.afterClosed().subscribe({
      next: () => {
        window.location.reload();
      },
    });
  }
}
