import { Component, Input } from '@angular/core';

@Component({
  selector: 'cm-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent {
  @Input() title = 'title';

  @Input() titleChain: string[];

  @Input() titlecase: boolean;
}
