<div class="label-list d-flex flex-wrap">
    <div class="label-list-row col-6" *ngFor="let item of [].constructor(14); let i = index">
      <div class="row">
        <div class="label-list-row_label pointer d-flex align-items-center justify-content-center" (click)="selectLabel(i)">
            <lib-cm-checkbox [checked]="mailingLabels[i]" style="pointer-events: none"></lib-cm-checkbox>
            <span>Click to select</span>
        </div>
      </div>
    </div>
</div>
<lib-cm-checkbox [checked]="allLabels" (change)="changeAllLabels($event)" label="All Labels"></lib-cm-checkbox>
