/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment_ from 'moment';
import { Moment } from 'moment';
import { FormControlsService } from '../services/form-controls.service';

const moment = moment_;

@Component({
  selector: 'lib-cm-datepicker',
  templateUrl: './cm-datepicker.component.html',
  styleUrls: ['./cm-datepicker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmDatepickerComponent),
      multi: true,
    },
  ],
})
export class CmDatepickerComponent implements OnInit, ControlValueAccessor {
  val: Moment;

  @Input() options: any;

  @Input() disabled: boolean;

  @Input() placeholder = 'Choose Date';

  @Input() minDate: Date | null = null;

  @Input() maxDate: Date | null = null;

  @Input() startAt: Date | null = null;

  @Input() highlightIfSet: boolean;

  @Input() clearable: boolean;

  @Input() convertToISO = true;

  @Input() dateFilterFn = (date: Date | Moment | null) => true;

  @Output() dateChange = new EventEmitter<MatDatepickerInputEvent<any>>();

  onChange: (val) => {};

  onTouched: (val) => {};

  constructor(public formControlsService: FormControlsService) {}

  set value(val: any) {
    if (val !== undefined && JSON.stringify(val) !== JSON.stringify(this.val)) {
      this.val = val;
      if (this.onChange) {
        this.onChange(!val ? val : this.convertToISO ? val.toISOString() : val || '');
      }
      if (this.onTouched) {
        this.onTouched(!val ? val : this.convertToISO ? val.toISOString() : val || '');
      }
    }
  }

  get value() {
    return this.val;
  }

  get stringVal() {
    return this.val && this.val.isValid() ? this.val : '';
  }

  ngOnInit() {
    this.writeValue('false');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.val = moment(value).utc();
  }

  onDateChange(event: MatDatepickerInputEvent<any>) {
    this.value = event.value;
    this.dateChange.emit(event);
  }

  reset() {
    if (!this.disabled) {
      this.writeValue(null);
      this.value = '';
    }
  }
}
