<cms-modal-layout id="atqa-welcome-popup">
  <div title class="title">Welcome to CMS!</div>
  <div content>
    <div class="main-info">
      Please visit our
      <span (click)="goToHelpCenter()" class="cm-primary font-weight-bold cursor-pointer"
        >Help Center</span
      >
      where you can find <br />articles to help you get started with CMS
    </div>

    <lib-cm-checkbox
      id="atqa-not-show-again"
      (change)="changeDontShowAgain($event)"
      label="Do not show again"
      >Do not show again
    </lib-cm-checkbox>
  </div>
</cms-modal-layout>
