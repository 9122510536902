/* eslint-disable @typescript-eslint/no-unused-vars */
import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: (error) => 'Required Field',
  addressState: (error) => 'Address State Should Match The Client State',
  minlength: ({ requiredLength, actualLength }) =>
    `Expected Length is ${requiredLength} but got ${actualLength}`,
  maxlength: ({ requiredLength, actualLength }) =>
    `The length shouldn't be more than ${requiredLength} characters`,
  maxLength: ({ requiredLength, actualLength }) =>
    `${actualLength} of ${requiredLength} max characters`,
  uniqueField: ({ key }) => `This ${key} already exists`,
  regExp: ({ key }) => `${key} is not valid`,
  notZero: ({ key }) => `${key} must not be zero`,
  beforeDate: ({ key }) => `Must be before ${key}`,
  dateNotInFuture: ({ key }) => `${key} must not be in future`,
  dateNotInPast: ({ key }) => `${key} must not be in past`,
  min: ({ min, actual }) => `Must be greater or equal to ${min}`,
  max: ({ max, actual }) => `Must be less or equal to ${max}`,
  custom: ({ message }) => message,
  custom2: ({ message }) => message,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
