/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

export interface NumberMask {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  integerLimit?: number;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
}

export interface TextMask {
  maxLength?: number;
  disableStartingSpace?: boolean;
  regex?: string;
  isSsnMask?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FormControlsService {
  pipeComparator(a, b) {
    return 0; // Display the values in order how they are defined in object
  }
}
