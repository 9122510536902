/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { cloneDeep, isEqual } from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ClaimantSearchResponse } from 'src/app/cms/models/claimant-search-response.model';
import { CmsClaimantService } from 'src/app/cms/services/cms-claimant.service';
import { SSN4_REGEX } from '../../app.const';

@Component({
  selector: 'cms-ssn-autocomplete',
  templateUrl: './ssn-autocomplete.component.html',
  styleUrls: ['./ssn-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SsnAutocompleteComponent),
      multi: true,
    },
    CmsClaimantService,
  ],
})
export class SsnAutocompleteComponent implements ControlValueAccessor, OnInit {
  @ViewChild('autocompleteTriggerRef', { static: true })
  autocompleteTriggerRef: MatAutocompleteTrigger;

  @Input() panelWidth = 'auto';

  @Input() disabled = false;

  @Input() autocompleteDisabled = false;

  @Input() readonlySsn4 = false;

  @Output() optionSelected = new EventEmitter<ClaimantSearchResponse>();

  myControl = new FormControl('');

  filteredOptions: Observable<any[]>;

  val: string;

  mask: any[];

  private readonly readonlySsn4Mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-'];

  private readonly placeholderChar = '_';

  private readonly separator = '-';

  private readonly emptyStr = '___-__-';

  private readonly fullMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(private cmsClaimantSvc: CmsClaimantService) {
    this.mask = cloneDeep(this.fullMask);
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  set value(val: string) {
    this.val = val;
    this.updateControlValue();
    this.onChange(this.formatedValToObj(val));
    this.onTouch(this.formatedValToObj(val));
  }

  get value() {
    return this.val;
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      filter((value: any) => !this.autocompleteDisabled),
      distinctUntilChanged((prev: any, curr: any) => isEqual(prev, curr)),
      debounceTime(200),
      switchMap((value: SsnOrSsn4 | ClaimantSearchResponse) => {
        if ((!value.ssn && !value.ssn4) || (value.ssn && value.ssn.length < 3)) {
          return of([]);
        }

        const newVal = value.ssn || value.ssn4;
        const ssnParam: 'ssn' | 'ssn4' = value.ssn ? 'ssn' : 'ssn4';

        return this.cmsClaimantSvc
          .searchClaimant({ [ssnParam]: newVal })
          .pipe(map((items: ClaimantSearchResponse[]) => this.formatOptions(items)));
      }),
    );
  }

  writeValue(value: SsnOrSsn4) {
    if (!value) {
      this.val = '';
      this.updateControlValue();
      return;
    }

    if (typeof value.ssn === 'string' && value.ssn.length) {
      this.useSsnKey(value.ssn);
      return;
    }

    if (typeof value.ssn4 === 'string' && value.ssn4.length) {
      this.useSsn4Key(value.ssn4);
      return;
    }

    this.val = '';
    this.updateControlValue();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  openPanelOptions(event: any) {
    if (this.autocompleteDisabled) {
      return;
    }

    event.stopPropagation();
    this.autocompleteTriggerRef.openPanel();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const optionVal: ClaimantSearchResponse = event.option.value;
    this.optionSelected.emit(optionVal);

    if (optionVal.ssn) {
      this.value = this.ssnToFromatSsn(optionVal.ssn);
    } else {
      this.value = this.ssn4ToFormatSsn4(optionVal.ssn4);
    }
  }

  private useSsnKey(ssn: string) {
    this.val = this.ssnToFromatSsn(ssn);
    this.updateControlValue();
  }

  private useSsn4Key(ssn4: string) {
    if (this.readonlySsn4) {
      this.mask = cloneDeep(this.readonlySsn4Mask);

      for (let i = 0; i < ssn4.length; i++) {
        this.mask.push(ssn4.charAt(i));
      }
    } else {
      this.mask = cloneDeep(this.fullMask);
    }

    this.val = this.ssn4ToFormatSsn4(ssn4);
    this.updateControlValue();
  }

  private updateControlValue() {
    const ssnOrSsn4 = this.formatedValToObj(this.val);
    this.myControl.setValue(ssnOrSsn4);
  }

  private formatOptions(items: ClaimantSearchResponse[]) {
    items.forEach((item: ClaimantSearchResponse) => {
      item.formatOption = `${
        item.ssn ? this.formatSsnForOption(item.ssn) : this.formatSsn4ForOption(item.ssn4)
      } | ${item.firstName} ${item.lastName}`;
    });

    return items;
  }

  private formatSsnForOption(ssn: string) {
    return `${ssn.substring(0, 3)}${this.separator}${ssn.substring(3, 5)}${
      this.separator
    }${ssn.substring(5, 9)}`;
  }

  private formatSsn4ForOption(ssn4: string) {
    return `${this.emptyStr}${ssn4}`;
  }

  private ssnToFromatSsn(ssn: string) {
    return `${ssn.substring(0, 3)}${this.separator}${ssn.substring(3, 5)}${
      this.separator
    }${ssn.substring(5, 9)}`;
  }

  private ssn4ToFormatSsn4(ssn4: string) {
    return `${this.emptyStr}${ssn4}`;
  }

  private formatedValToObj(formatedVal: string): SsnOrSsn4 {
    const ssnOrSsn4: SsnOrSsn4 = { ssn: '', ssn4: '' };

    const valArray = formatedVal.split(this.emptyStr);

    if (valArray.length === 2) {
      ssnOrSsn4.ssn = '';

      if (SSN4_REGEX.test(valArray[1])) {
        ssnOrSsn4.ssn4 = valArray[1];
      }
    } else {
      ssnOrSsn4.ssn4 = '';

      let str = '';

      for (let i = 0; i < formatedVal.length; i++) {
        const char = formatedVal.charAt(i);

        if (char !== this.separator && char !== this.placeholderChar) {
          str += char;
        }
      }

      ssnOrSsn4.ssn = str;

      if (str.length === 9) {
        ssnOrSsn4.ssn4 = formatedVal.split(this.separator)[2];
      }
    }

    return ssnOrSsn4;
  }
}

export interface SsnOrSsn4 {
  ssn?: string;
  ssn4?: string;
}
