<div class="datepicker-wrapper">
  <input
    class="lib-cm-input"
    matInput
    [ngxMatDatetimePicker]="picker"
    placeholder="Choose a date"
    (dateChange)="onDateChange($event)"
    (click)="picker.open()"
    [disabled]="disabled"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [min]="minDate"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #picker [defaultTime]="defaultTime" [enableMeridian]="enableMeridian">
  </ngx-mat-datetime-picker>
</div>
