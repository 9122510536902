import { Subject } from 'rxjs';

export class ComponentDestroyClass {
  protected componentDestroyed$: Subject<boolean>;

  constructor() {
    this.componentDestroyed$ = new Subject();
  }

  protected onDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.componentDestroyed$.unsubscribe();
  }
}
