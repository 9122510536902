/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Injectable, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PopupService } from 'form-controls';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { auditTime, take } from 'rxjs/operators';
import { LogoutService } from './logout.service';
import { ProfileService } from './profile.service';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private loginTimeoutMs = null;

  private cronInterval = null;

  private loginTimeout$: Subject<Date>;

  private warningLoginTimeout$: Subject<Date>;

  private warningPopup: MatDialogRef<any, any>;

  private eventListenersSubscription: Subscription;

  private readonly LS_LAST_CRON_TIME_KEY = 'last.time.user.active';

  private readonly CRON_CHECK_STEP = 1000; // ms

  private readonly WARNING_TIME = 5 * 60 * 1000; // ms

  private readonly SESSION_TIMEOUT = 'timeout';

  constructor(
    private ngZone: NgZone,
    private storageSvc: StorageService,
    private sessionSvc: SessionService,
    private logoutSvc: LogoutService,
    private popupSvc: PopupService,
    private profileSvc: ProfileService,
  ) {}

  start() {
    const loginTimeoutSeconds = this.profileSvc.user.loginTimeout;

    if (!loginTimeoutSeconds) {
      throw new Error(`LOGIN TIMEOUT INVALID: ${loginTimeoutSeconds}`);
    }

    this.loginTimeoutMs = loginTimeoutSeconds * 1000;
    this.startCron();
  }

  stop() {
    this.loginTimeoutMs = null;
    this.stopCron();
  }

  private startCron() {
    this.canCronStart();
    this.initLoginTimeoutSubjects();
    this.addEventListeners();
    this.cron();
  }

  private stopCron() {
    clearInterval(this.cronInterval);
    this.removeEventListeners();
    this.destroyLoginTimeoutSubjects();
  }

  private resetCron() {
    this.stopCron();
    this.startCron();
  }

  private get lastCronTime() {
    const lastCronTime = Number(this.storageSvc.get(this.LS_LAST_CRON_TIME_KEY, 'LS'));

    if (lastCronTime > 0) {
      return lastCronTime;
    }

    return null;
  }

  private setLastCronTime() {
    this.storageSvc.set(this.LS_LAST_CRON_TIME_KEY, new Date().getTime(), 'LS');
  }

  private initLoginTimeoutSubjects() {
    this.canInitLoginTimeoutSubjects();

    this.loginTimeout$ = new Subject();
    this.warningLoginTimeout$ = new Subject();

    this.loginTimeout$.pipe(take(1)).subscribe((date: Date) => {
      this.ifLoginTimeout(date);
    });

    this.warningLoginTimeout$.pipe(take(1)).subscribe((date: Date) => {
      this.ifWarningLoginTimeout(date);
    });
  }

  private ifLoginTimeout(date: Date) {
    this.ngZone.run(() => {
      if (this.warningPopup) {
        this.warningPopup.close(this.SESSION_TIMEOUT);
        return;
      }

      this.logoutUser();
    });
  }

  private ifWarningLoginTimeout(date: Date) {
    this.ngZone.run(() => {
      this.removeEventListeners();
      this.showWarningPopup();
    });
  }

  private destroyLoginTimeoutSubjects() {
    if (this.loginTimeout$) {
      this.loginTimeout$.complete();
    }

    if (this.warningLoginTimeout$) {
      this.warningLoginTimeout$.complete();
    }

    this.loginTimeout$ = null;
    this.warningLoginTimeout$ = null;
  }

  private cron() {
    this.setLastCronTime();

    this.ngZone.runOutsideAngular(() => {
      this.cronInterval = setInterval(() => {
        this.canCronWork();

        const currentTime = new Date().getTime();

        if (currentTime - this.lastCronTime >= this.loginTimeoutMs) {
          clearInterval(this.cronInterval);
          this.loginTimeout$.next(new Date());
          return;
        }

        if (currentTime - this.lastCronTime >= this.loginTimeoutMs - this.WARNING_TIME) {
          this.warningLoginTimeout$.next(new Date());
        }
      }, this.CRON_CHECK_STEP);
    });
  }

  private addEventListeners() {
    this.ngZone.runOutsideAngular(() => {
      this.eventListenersSubscription = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'mousedown'),
        fromEvent(window, 'click'),
        fromEvent(window, 'scroll'),
        fromEvent(window, 'keypress'),
      )
        .pipe(auditTime(1500))
        .subscribe({
          next: () => {
            this.setLastCronTime();
          },
        });
    });
  }

  private removeEventListeners() {
    if (this.eventListenersSubscription) {
      this.eventListenersSubscription.unsubscribe();
    }
  }

  private showWarningPopup() {
    this.warningPopup = this.popupSvc.openWarningDialog(
      `You will be logged out after ${this.WARNING_TIME / 1000 / 60} minutes due to inactivity.`,
    );

    this.warningPopup
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          if (result !== this.SESSION_TIMEOUT) {
            this.resetCron();
          } else {
            this.logoutUser();
          }
        },
      });
  }

  private logoutUser() {
    this.logoutSvc.logout(`Session timeout ${this.sessionSvc.token}`);
  }

  private canCronStart() {
    if (this.loginTimeoutMs) {
      return;
    }

    throw new Error(`CAN'T START CRON WITHOUT LOGIN TIMEOUT!`);
  }

  private canInitLoginTimeoutSubjects() {
    if (!this.loginTimeout$ && !this.warningLoginTimeout$) {
      return;
    }

    throw new Error('LOGIN TIMEOUT SUBJECTS must be destroyed before initialization!');
  }

  private canCronWork() {
    if (this.sessionSvc.token && this.lastCronTime && this.loginTimeoutMs) {
      return;
    }

    throw new Error(`THE CRON CANNOT WORK!`);
  }
}
