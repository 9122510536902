<div class="textarea-wrapper" #inputFef>
  <textarea
      *ngIf="!isAutoHeight"
      type="text"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      class="lib-cm-textarea"
      spellcheck="false"
      [disabled]="disabled"
      [ngClass]="{ clearable: clearable,'border-highlighted': !!val && highlightIfSet }"
      [attr.rows]="rows"
      [maxlength]="maxlength"
  >
  </textarea>
    <textarea
        *ngIf="isAutoHeight"
        type="text"
        cdkTextareaAutosize
        #cfcAutosize="cdkTextareaAutosize"
        [(ngModel)]="value"
        (ngModelChange)="resize()"
        [placeholder]="placeholder"
        class="lib-cm-textarea"
        spellcheck="false"
        [disabled]="disabled"
        [ngClass]="{ clearable: clearable, 'border-highlighted': !!val && highlightIfSet}"
        [ngStyle]="{'overflow': 'hidden', 'resize': 'vertical'}"
    >
  </textarea>
    <span class="reset-btn" id="atqa-reset-btn" *ngIf="!!val && clearable" (click)="reset()">
    <i class="material-icons" [class.highlighted]="highlightIfSet">close</i>
  </span>
</div>
