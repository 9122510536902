/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { ActionButtonColor } from 'projects/form-controls/src/lib/utmc-button/utmc-button.component';

export interface ActionButton {
  label: string;
  icon?: string;
  color: ActionButtonColor | string;
  class?: string;
  disabled?: boolean | (() => any);
  hidden?: boolean | (() => boolean);
  id?: string;
  loading?: boolean;
  title?: string;
  onClick(): void;
  titlecase?: boolean;
}

export interface InfoField {
  title: string | (() => string);
  value: string | (() => string);
  isCurrency?: boolean;
}

@Injectable()
export class DetailsHeaderService {
  currentTitle: string;

  titleChain: string[];

  actionButtons: ActionButton[] = [];

  infoFields: InfoField[] = [];

  afterTitleInfo: string;

  titlecase = true;

  constructor() {}

  setTitle(title: string) {
    this.currentTitle = title;
  }

  setTitleChain(...titles: string[]) {
    this.titleChain = titles;
  }
}
