/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';

type Errors = {
  [key: string]: (item: FileLikeObject, filter: any, uploader: FileUploader) => string;
};

type ErrorItems = { [key: string]: { msg: string; fNames: string[] } };

type Config = { uploader: FileUploader; errors?: Errors };

export const FUDErrors = {
  FZ: {
    fileSize: (item: FileLikeObject, filter: any, uploader: FileUploader) =>
      `The size of file(s) is more than ${uploader.options.maxFileSize / 1024 / 1024} MB.`,
  },
  FT: {
    mimeType: (item: FileLikeObject, filter: any, uploader: FileUploader) =>
      `Invalid file type. Please upload PDF file.`,
  },
  MFC: {
    maxFileCount: (item: FileLikeObject, filter: any, uploader: FileUploader) =>
      `You have already reached the limit of (${uploader.options.queueLimit}) attachments`,
  },
};

@Component({
  selector: 'cms-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  @Input() set response(response: { reqDone: boolean }) {
    if (!response) {
      return;
    }

    this.reqLoading = false;

    if (response.reqDone) {
      this.fileList = [];
      this.errorItems = null;
    }
  }

  @Input() accept = 'application/pdf';

  @Input() set config(config: Config) {
    this.initFileUploader(config);
  }

  @Output() submitFiles = new EventEmitter<File[]>();

  fileList: File[] = [];

  fileUploader: FileUploader;

  errorItems: ErrorItems;

  reqLoading = false;

  constructor() {}

  ngOnInit(): void {}

  get confirmBtnDisabled() {
    return !this.fileList.length || this.reqLoading;
  }

  openFileInput() {
    this.errorItems = null;
    this.fileInput.nativeElement.click();
  }

  removeFile(index: number) {
    this.fileList.splice(index, 1);
  }

  onSubmit() {
    this.submitFiles.emit(this.fileList);
  }

  private initFileUploader(config: Config) {
    this.fileUploader = config.uploader;
    this.fileUploader.setOptions({
      filters: [
        ...(this.fileUploader.options.queueLimit
          ? [
              {
                name: 'maxFileCount',
                fn: () => {
                  return this.fileList.length < this.fileUploader.options.queueLimit;
                },
              },
            ]
          : []),
      ],
    });
    this.fileUploader.onAfterAddingFile = (fileItem: FileItem) => {
      this.fileList.push(fileItem._file);
      this.fileUploader.clearQueue();
      this.onSubmit();
    };

    this.fileUploader.onWhenAddingFileFailed = (item, filter) => {
      const filterName = filter.name;
      const defErrors = Object.values(FUDErrors).find((e) => e[filterName]);

      if (defErrors) {
        this.setErrItems(item, filter, defErrors);
        return;
      }

      if (config.errors && config.errors[filterName]) {
        this.setErrItems(item, filter, config.errors);
      }
    };
  }

  private setErrItems(item: FileLikeObject, filter: any, errors: Errors) {
    if (!this.errorItems) {
      this.errorItems = {};
    }

    if (!this.errorItems[filter.name]) {
      this.errorItems[filter.name] = { msg: '', fNames: [] };
    }

    this.errorItems[filter.name].msg = errors[filter.name](item, filter, this.fileUploader);
    this.errorItems[filter.name].fNames.push(item.name);
  }
}
