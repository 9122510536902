/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { delay, finalize, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TextMask } from '../services/form-controls.service';

@Component({
  selector: 'lib-cm-autocomplete',
  templateUrl: './cm-autocomplete.component.html',
  styleUrls: ['./cm-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmAutocompleteComponent),
      multi: true,
    },
  ],
})
export class CmAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  options: string[] = [
    'One',
    'Two',
    'Three',
    'One',
    'Two',
    'Three',
    'One',
    'Two',
    'Three',
    'One',
    'Two',
    'Three',
  ];

  myControl = new FormControl();

  filteredOptions: Observable<any>;

  vm: any;

  @Input() orgType;

  @Input() inService;

  @Input() clientHaveActionItem = false;

  @Input() observableSourceFunction: (param: {}) => Observable<any>;

  @Input() login;

  @Input() source;

  @Input() keyValue: string;

  @Input() viewValue = 'name';

  @Input() valueForRequest;

  @Input() showAliases = false;

  @Input() params = {};

  @Input() disabled: boolean;

  @Input() clearable: boolean;

  @Input() highlightIfSet: boolean;

  @Input() placeholder = 'Pick One';

  @Input() filterList: Array<any>;

  @Input() cursorToBegin = false;

  @Input() inputMask: TextMask;

  @Input() resetOptions: boolean;

  @Output() valueChange = new EventEmitter(true);

  @Output() inputEvent = new EventEmitter<any>();

  @Output() blurEvent = new EventEmitter<any>();

  reqLoading = false;

  private isDomInitialized: boolean;

  private onTouched: () => void;

  private propagateChange: (value) => void;

  private withAccountNumber: boolean;

  aliases: Array<string>;

  @ViewChild('trigger', { static: true }) trigger: MatAutocompleteTrigger;

  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.vm = '';
    this.isDomInitialized = false;
    this.onTouched = () => {};
    this.propagateChange = () => {};

    this.withAccountNumber = false;

    const regex = /report-benefit-charges/;

    if (regex.test(this.router.url)) {
      this.withAccountNumber = true;
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(this.vm || ' '),
      switchMap((keyword) => {
        setTimeout(() => {
          this.reqLoading = true;
        });

        const params = {
          [this.valueForRequest || this.viewValue]:
            keyword[this.valueForRequest] ||
            keyword.name ||
            keyword[this.viewValue] ||
            keyword ||
            ' ', // <- backend crutch, must be {name: keyword}
          // orgType: this.orgType || null,
          // inService: this.inService || null,
          // haveActionItem: this.clientHaveActionItem,
          ...this.params,
        };

        return (this.resetOptions ? of([]) : this.observableSourceFunction(params)).pipe(
          delay(100), // to make the spinner to work
          finalize(() => {
            this.reqLoading = false;
          }),
        );
      }),
    );
  }

  ngAfterViewInit(): void {
    this.isDomInitialized = true;
  }

  get showSpinner() {
    return this.reqLoading;
  }

  onCompanyChanged(company): void {
    console.log('onCompanyChanged', company);
    if (!this.isDomInitialized) {
      return;
    }

    this.vm = company === null ? '' : company;
    if (this.showAliases) {
      // eslint-disable-next-line no-prototype-builtins
      if (!!this.vm && this.vm.hasOwnProperty('aliases')) {
        this.aliases = !!this.vm.aliases && !!this.vm.aliases.length ? this.vm.aliases : null;
      } else {
        this.aliases = null;
      }
    }
    // Pass data to form!
    this.propagateChange(company);
    this.onTouched(); // add .ng-touched class
    // this.change.emit(company); // (change) event
  }

  setInputMask(value: any) {
    if (!this.inputMask?.isSsnMask) {
      return;
    }

    if (!value.ssnFormated) {
      if (value?.slice(-1) === '-') {
        value = value?.slice(0, -1);
      }
      if (value.length === 4 || value.length === 7) {
        value = this.addDash(value);
      }
    }
    this.input.nativeElement.value = value.ssnFormated ? value.ssnFormated : value;
    this.viewValue = value.ssnFormated ? 'ssnFormated' : 'fullName';
  }

  addDash(value: string) {
    const lastSymbol = value?.slice(-1);
    value = `${value?.slice(0, -1)}-${lastSymbol}`;
    return value;
  }

  writeValue(value): void {
    this.vm = value || '';
    if (this.showAliases) {
      if (this.vm) {
        this.aliases =
          !!this.vm.aliases && !!this.vm.aliases.length ? this.vm.aliases : this.aliases;
      } else {
        this.aliases = null;
      }
    }
    this.myControl.patchValue(this.vm);
  }

  /**
   * Form ControlValueAccessor interface
   * Set the function to be called when the control receives a change event.
   */
  registerOnChange(cb): void {
    this.propagateChange = cb;
  }

  /**
   * Form ControlValueAccessor interface
   * Set the function to be called when the control receives a touch event.
   */
  registerOnTouched(cb): void {
    this.onTouched = cb;
  }

  optionSelected({ option: { value } }): void {
    if (!this.isDomInitialized) {
      return;
    }
    this.vm = value === null ? '' : value;
    if (this.showAliases) {
      // eslint-disable-next-line no-prototype-builtins
      if (!!this.vm && this.vm.hasOwnProperty('aliases')) {
        this.aliases = !!this.vm.aliases && !!this.vm.aliases.length ? this.vm.aliases : null;
      } else {
        this.aliases = null;
      }
    }
    // Pass data to form!
    this.propagateChange(value);
    this.onTouched(); // add .ng-touched class
    this.valueChange.emit(value);
    this.setCursorToBegin();
  }

  displayProperty(subj): string {
    return subj ? subj[this.viewValue] : undefined;
  }

  onBlur(event: FocusEvent) {
    if (this.trigger.autocomplete.isOpen) {
      return;
    }

    this.blurEvent.emit({ event, model: this.vm });
    this.writeValue(this.vm);
  }

  reset() {
    this.writeValue('');
    this.propagateChange(this.vm);
  }

  getFormattedAliases(aliases) {
    if (!aliases || !aliases.length) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return aliases.join(';  ');
  }

  focus() {
    setTimeout(() => {
      this.trigger.openPanel();
      this.input.nativeElement.focus();
    });
  }

  onInput(event: InputEvent) {
    this.inputEvent.emit({ event, model: this.vm });
  }

  changedFun(item: { option: { value } }) {
    this.optionSelected(item);
  }

  private setCursorToBegin() {
    if (!this.cursorToBegin) {
      return;
    }
    this.input.nativeElement.blur();
    this.input.nativeElement.setSelectionRange(0, 0);
    this.input.nativeElement.focus();
  }
}
