<ng-container *ngFor="let filter of filters">
  <ng-container *ngIf="isHiddenField(filter); else noHiddenTpl">
    <input type="hidden" [formControl]="filter.formControl.value" />
  </ng-container>

  <ng-template #noHiddenTpl>
    <div class="cm-row" [class.no-flex]="sidebarService.isLarge" [ngClass]="filter.cssClass || ''">
      <ng-container *ngIf="filter.type == 'template'; else noTemplate">
        <ng-container
          *ngTemplateOutlet="filter.template; context: { filter: filter }"
        ></ng-container>
      </ng-container>

      <ng-template #noTemplate>
        <div
          *ngIf="!filter.hideLabel"
          class="cm-label"
          [class.required]="filter?.formControl?.value?.status == 'INVALID' && filter?.label != ' '"
        >
          {{ filter.label || (filter.formControl.key | camelToWords | titlecase) }}
        </div>

        <div class="cm-field">
          <lib-cm-input
            *ngIf="filter.type == 'text'"
            [titlecase]="isTitlecase(filter)"
            [formControl]="filter.formControl.value"
            placeholder="Enter {{
              filter.label || (filter.formControl.key | camelToWords | titlecase)
            }}"
            [highlightIfSet]="true"
            [clearable]="true"
            [disabled]="filter.disabled"
            id="{{ filter.id }}"
          ></lib-cm-input>

          <lib-cm-input
            *ngIf="filter.type == 'number'"
            [type]="filter.type"
            [formControl]="filter.formControl.value"
            [placeholder]="filter.placeholder"
            [highlightIfSet]="true"
            [clearable]="true"
            [disabled]="filter.disabled"
            id="{{ filter.id }}"
          ></lib-cm-input>

          <lib-cm-select
            *ngIf="filter.type == 'select'"
            [formControl]="filter.formControl.value"
            [collection]="filter.data"
            [clearable]="isClearable(filter)"
            [highlightIfSet]="true"
            [placeholder]="filter.placeholder"
            [deselectable]="isDeselectable(filter)"
            [viewValue]="filter.viewValue"
            [defaultValue]="filter.defaultValue"
            [valueForRequest]="filter.valueForRequest"
            [panelClass]="filter.panelClass"
            [keyValue]="filter.keyValue"
            [disabled]="filter.disabled"
            id="{{ filter.id }}"
          >
          </lib-cm-select>

          <lib-cm-datepicker
            *ngIf="filter.type == 'datepicker'"
            [formControl]="filter.formControl.value"
            [placeholder]="filter.placeholder"
            [disabled]="filter.disabled"
            [clearable]="true"
            [highlightIfSet]="true"
            id="{{ filter.id }}"
          >
          </lib-cm-datepicker>

          <lib-cm-datetimepicker
            *ngIf="filter.type == 'datetimepicker'"
            [formControl]="filter.formControl.value"
            [placeholder]="filter.placeholder"
            [disabled]="filter.disabled"
            id="{{ filter.id }}"
          >
          </lib-cm-datetimepicker>

          <lib-cm-daterangepicker
            *ngIf="filter.type == 'daterangepicker'"
            [group]="filter.formControl.value"
            (dateChange)="dateRangeChange($event, filter.formControl.value)"
            [disabled]="filter.disabled"
            id="{{ filter.id }}"
          >
          </lib-cm-daterangepicker>

          <lib-cm-autocomplete
            *ngIf="filter.type == 'autocomplete'"
            [formControl]="filter.formControl.value"
            [observableSourceFunction]="filter.data.sourceFun"
            [params]="filter.data.params"
            [placeholder]="filter.placeholder || 'Enter Name'"
            [highlightIfSet]="true"
            [clearable]="true"
            [viewValue]="filter.viewValue || 'name'"
            [valueForRequest]="filter.valueForRequest"
            [inputMask]="filter.inputMask"
            id="{{ filter.id }}"
            [disabled]="filter.disabled"
            [resetOptions]]="filter.resetOptions"
          ></lib-cm-autocomplete>

          <lib-cm-checkbox
            *ngIf="filter.type == 'checkbox'"
            [formControl]="filter.formControl.value"
            [disabled]="filter.disabled"
            [label]="filter.label"
            id="{{ filter.id }}"
          ></lib-cm-checkbox>

          <cms-ssn-autocomplete
            *ngIf="filter.type == 'ssnAutocomplete'"
            [formControl]="filter.formControl.value"
            [panelWidth]="null"
            id="{{ filter.id }}"
          ></cms-ssn-autocomplete>

          <cms-labels
            *ngIf="filter.type == 'labels'"
            [control]="filter.formControl.value"
          ></cms-labels>

          <span *ngIf="filter?.subInfo && filter?.formControl?.value?.value">{{
            filter?.formControl?.value.value[filter?.subInfo]
          }}</span>
        </div>
      </ng-template>
    </div>
  </ng-template>
</ng-container>
