<cms-modal-layout>
  <span title>{{ this.projectInfo.name }} Private Policy</span>
  <div content>
    <p class="p-header">PRIVACY POLICY</p>
    <p class="effective-date">Last revised: May 2, 2019</p>
    <ol class="list">
      <li>
        <p>INTRODUCTION</p>
        <p class="header-level-2">Purpose.</p>
        <p>
          The purpose of this Privacy Policy is to describe how Unemployment Tax Management
          Corporation (“Company”) collects, uses and shares information about you through our online
          interfaces (e.g., websites and mobile applications) owned and controlled by us, including
          <a href="https://cms.utmcorp.com">cms.utmcorp.com</a> (collectively referred to herein as
          the “Site”). Please read this notice carefully to understand what we do. If you do not
          understand any aspects of our Privacy Policy, please feel free to Contact Us here or as
          described at the end of this Policy.
        </p>
        <ul>
          <b>Our Privacy Policy explains</b>
          <li>Information That We Collect</li>
          <li>How We Use and Share Your Information</li>
          <li>Access to Your Information and Choices</li>
          <li>Security of Your Information</li>
          <li>Consent to Processing of Personal Data in United States</li>
          <li>Changes to Our Privacy Policy</li>
          <li>Questions and How Contact Us</li>
        </ul>
        <p class="header-level-2">Scope, Third Party Sites.</p>
        <p>
          This Privacy Policy applies only to information we collect at and through the Site. Our
          Site also contains links to third party sites that are not owned or controlled by Company.
          Please be aware that we are not responsible for the privacy practices of such other sites.
          We encourage you to be aware when you leave our Site and to read the privacy statements of
          each and every website that collects personal information.
        </p>
        <p class="header-level-2">Terms of Use.</p>
        <p>Please note that your use of our Site is also subject to our Terms of Use.</p>
      </li>
      <li>
        <p>INFORMATION THAT WE COLLECT.</p>
        <p class="header-level-2">Information You Provide to Us.</p>
        <p>
          We collect information you provide to us, for example when you create or modify your
          account, register to use our Site, purchase services from us, request information from us,
          contact customer support, or otherwise communicate with us. This information may include
          name, physical address, mailing address, State Employer Identification Number, Federal
          Employer Identification Number, email address.
        </p>
        <p class="header-level-2">Information Collected Through Cookies and Similar Technologies</p>
        <p>
          We and our service providers use cookies, web beacons, and other technologies to receive
          and store certain types of information whenever you interact with our Site through your
          computer or mobile device. A cookie is a small file containing a string of characters that
          is sent to your computer when you visit a website. When you visit the Site again, the
          cookie allows the Site to recognize your browser. Cookies may store unique identifiers,
          user preferences and other information. You can reset your browser to refuse all cookies
          or to indicate when a cookie is being sent. However, some Site features or services may
          not function properly without cookies. We use cookies to improve the quality of our
          service, including for storing user preferences.
        </p>
      </li>
      <li>
        <p>HOW WE USE AND SHARE YOUR INFORMATION</p>
        <p class="header-level-2">To Provide Products, Services, and Information.</p>
        <p>
          We collect information from you in order to register and service your online account; send
          you promotional materials or advertisements about our products and services, as well as
          new features and offerings.
        </p>
        <p class="header-level-2">Sharing with Third Parties.</p>
        <p>
          We may provide information to third party service providers that help us operate and
          manage our Site. These service providers will have access to your personal information in
          order to provide these services, but when this occurs we implement reasonable contractual
          and technical protections to limit their use of that information to helping us provide the
          service.
        </p>
        <p class="header-level-2">Your Consent.</p>
        <p>
          In addition to the sharing described elsewhere in this Policy, we will share personal
          information with companies, organizations or individuals outside of Company when we have
          your consent to do so.
        </p>
        <p class="header-level-2">Legal Proceedings.</p>
        <ul>
          We will share personal information with third party companies, organizations or
          individuals outside of Company if we have a good-faith belief that access, use,
          preservation or disclosure of the information is reasonably necessary to:
          <li>
            Meet any applicable law, regulation, subpoena, legal process or enforceable governmental
            request.
          </li>
          <li>Enforce applicable Terms of Use, including investigation of potential violations.</li>
          <li>Detect, prevent, or otherwise address fraud, security or technical issues.</li>
          <li>
            Protect against harm to the rights, property or safety of Company, our users, customers
            or the public as required or permitted by law.
          </li>
        </ul>
        <p class="header-level-2">Transfer in the Event of Sale or Change of Control.</p>
        <p>
          If the ownership of all or substantially all of our business changes or we otherwise
          transfer assets relating to our business or the Site to a third party, such as by merger,
          acquisition, bankruptcy proceeding or otherwise, we may transfer or sell your personal
          information to the new owner. In such a case, unless permitted otherwise by applicable
          law, your information would remain subject to the promises made in the applicable privacy
          policy unless you agree differently.
        </p>
      </li>
      <li>
        <p>ACCESS TO YOUR INFORMATION AND CHOICES</p>
        <p>
          If you have questions about personal information we have about you or need to update your
          information, you can Contact Us or call us at {{ this.projectInfo.phone }}.
        </p>
        <p>
          Upon mutual agreement of discontinuance of account with {{ this.projectInfo.name }}, we will no longer use your
          online account information or share it with third parties. We may, however, retain a copy
          of the information for archival purposes, and to avoid identity theft or fraud.
        </p>
      </li>
      <li>
        <p>SECURITY OF YOUR INFORMATION</p>
        <p>
          We use industry standard physical, technical and administrative security measures and
          safeguards to protect the confidentiality and security of your personal information.
          However, since the Internet is not a 100% secure environment, we cannot guarantee, ensure,
          or warrant the security of any information you transmit to us. There is no guarantee that
          information may not be accessed, disclosed, altered, or destroyed by breach of any of our
          physical, technical, or managerial safeguards. It is your responsibility to protect the
          security of your login information.
        </p>
      </li>
      <li>
        <p>CONSENT TO PROCESSING OF PERSONAL DATA IN UNITED STATES</p>
        <p>
          This Site and Services are not intended for and are not directed to residents of the
          European Union. By using and accessing our Site, users who reside or are located in
          countries outside of the United States agree and consent to the transfer to and processing
          of personal information on servers located outside of the country where they reside, and
          that the protection of such information may be different than required under the laws of
          their residence or location.
        </p>
      </li>
      <li>
        <p>CHANGES TO OUR PRIVACY POLICY</p>
        <p>
          Our Privacy Policy may change from time to time. We will not reduce your rights under this
          Privacy Policy without your consent in accordance with applicable law. We will post any
          privacy policy changes on this page and, if the changes are significant, we will provide a
          more prominent notice (including, for certain services, email notification of privacy
          policy changes). We will also keep prior versions of this Privacy Policy in an archive for
          your review.
        </p>
      </li>
      <li>
        <p>QUESTIONS AND HOW TO CONTACT US</p>
        <p>
          If you have any questions, concerns, complaints or suggestions regarding our Privacy
          Policy or otherwise need to contact us, please Contact Us, email us at
          {{ this.projectInfo.email }}, call us at {{ this.projectInfo.phone }}, or contact us by US postal mail at the
          following address:
        </p>
        <p>{{ this.projectInfo.address_line_one }}</p>
        <p>{{ this.projectInfo.address_line_two }}</p>
      </li>
    </ol>
  </div>
  <span closeBtnTitle>Close</span>
</cms-modal-layout>
