<div class="dialog-title">
  <h2 mat-dialog-title>
    <ng-content select="[title]"></ng-content>
  </h2>

  <button mat-button mat-dialog-close class="close-btn-icon">
    <i class="material-icons">close</i>
  </button>
</div>

<hr *ngIf="!data?.withoutTopHr"/>

<mat-dialog-content [ngClass]="data?.dialogContentClass || ''">
  <ng-content select="[content]"></ng-content>
</mat-dialog-content>

<hr *ngIf="!data?.likeAPopup" class="mb-0" />

<mat-dialog-actions align="end" *ngIf="!data?.withoutAction">
  <button *ngIf="data?.closeBtn" mat-button mat-dialog-close>
    <ng-content select="[closeBtnTitle]"></ng-content>
  </button>

  <ng-content select="[actionBtns]"></ng-content>
</mat-dialog-actions>
