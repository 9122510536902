import { NgModule } from '@angular/core';
import { CamelToWordsPipe } from 'src/app/shared/pipes/camel-to-words.pipe';

@NgModule({
  declarations: [CamelToWordsPipe],
  imports: [],
  exports: [CamelToWordsPipe],
  providers: [],
})
export class SharedModule {}
