import { Component, Input } from '@angular/core';
import { ActionButton } from '../services/details-header.service';

@Component({
  selector: 'cm-action-buttons-bar',
  templateUrl: './action-buttons-bar.component.html',
  styleUrls: ['./action-buttons-bar.component.scss'],
})
export class ActionButtonsBarComponent {
  @Input() buttons: ActionButton[];

  @Input() isLarge: boolean;
}
