import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalVideoComponent } from './modal-video.component';

@NgModule({
  declarations: [ModalVideoComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [ModalVideoComponent],
})
export class ModalVideoModule {}
