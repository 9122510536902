<button #button
  [ngClass]="class"
  [attr.id]="id"
  [attr.type]="type ? type : 'button'"
  [attr.size]="size"
  [attr.color]="color ? color : 'default'"
  [attr.disabled]="loading || disabled || null"
  [attr.loading]="loading ? true : null"
  [attr.title]="title ? title : null"
  [attr.tabindex]="0"
  (click)="triggerClick()"
>
  <div class="content">
    <ng-content> </ng-content>
    <mat-spinner *ngIf="loading" [diameter]="22"></mat-spinner>
  </div>
</button>
