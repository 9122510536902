import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeployService } from 'src/app/services/deploy.service';
import { IdleService } from 'src/app/services/idle.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { UserRevokeService } from '../../services/general/user-revoke.service';

@Component({
  selector: 'cms-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
  providers: [UserRevokeService],
})
export class AuthorizationComponent implements OnInit, OnDestroy {
  constructor(
    private webSocketSvc: WebSocketService,
    private idleSvc: IdleService,
    private userRevokeService: UserRevokeService,
    private deploySvc: DeployService,
  ) {}

  ngOnInit(): void {
    this.idleSvc.start();
    this.webSocketSvc.connect();
    this.userRevokeService.subscribe();
    this.deploySvc.startTracking();
  }

  ngOnDestroy(): void {
    this.idleSvc.stop();
    this.userRevokeService.unsubscribe();
    this.deploySvc.stopTracking();
    this.webSocketSvc.close();
  }
}
