<div class="input-wrapper">
  <input
    type="text"
    [(ngModel)]="value"
    class="ssn-input"
    spellcheck="false"
    [textMask]="{ mask: mask, guide: true, showMask: true, keepCharPositions: true }"
    (click)="openPanelOptions($event)"
    [disabled]="disabled"
  />
  <input
    type="text"
    [matAutocomplete]="auto"
    #autocompleteTriggerRef="matAutocompleteTrigger"
    [matAutocompleteDisabled]="autocompleteDisabled"
    [formControl]="myControl"
    class="hidden-input"
  />

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    [panelWidth]="panelWidth"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.formatOption }}
    </mat-option>
  </mat-autocomplete>
</div>
