<div class="input-wrapper">
  <input
    [type]="type"
    #inputFef
    (blur)="blur.emit()"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    class="lib-cm-input"
    spellcheck="false"
    [disabled]="disabled"
    [ngClass]="{
      clearable: clearable,
      'border-highlighted': !!val && highlightIfSet
    }"
    [textMask]="{ mask: mask, showMask: false, guide: false }"
    (focus)="onfocus($event)"
    [maxlength]="maxlength"
    [restrictPattern]="restrictPattern"
  />

  <span class="reset-btn" id="atqa-reset-btn" *ngIf="!!val && clearable" (click)="reset()" id="atqa-reset-btn">
    <i class="material-icons" [class.highlighted]="highlightIfSet">close</i>
  </span>
</div>
