import { Component, Input } from '@angular/core';
import { UserControlOption } from '../../services/header.service';
import { MeResponse } from '../../../app/models/profile/me-response.model';

@Component({
  selector: 'cm-user-controls',
  templateUrl: './user-controls.component.html',
  styleUrls: ['./user-controls.component.scss'],
})
export class UserControlsComponent {
  @Input() user: MeResponse;

  @Input() userControls: UserControlOption[];

  expanded: boolean;
}
