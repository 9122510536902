import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CMS_TITLE } from 'src/app/shared/app.const';

export interface TitleItem {
  title: string;

  onClick();
}

export interface UserControlOption {
  label: string;

  onClick();
}

@Injectable({
  providedIn: 'platform',
})
export class HeaderService {
  title$ = new BehaviorSubject(CMS_TITLE);

  items$ = new Subject<TitleItem[]>();

  userControls: UserControlOption[];
}
