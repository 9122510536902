import { Component, Input } from '@angular/core';
import { TitleItem } from '../../services/header.service';

@Component({
  selector: 'cm-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent {
  @Input() menuItems: TitleItem[];

  @Input() title: string;

  expanded: boolean;

  onMenuItemClick(item: any) {
    item.onClick();
    this.expanded = false;
  }
}
