/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

const SockJS = require('sockjs-client');

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  sockJS;

  sockJsSubject;

  private readonly WS_URL = '/api/ws';

  constructor(private sessionSvc: SessionService) {
    this.sockJsSubject = new Subject();
    this.sockJS = null;
  }

  close() {
    if (this.sockJS) this.sockJS.close();
  }

  log(...args) {
    if (environment && environment.production === true) return;

    if (!args || (args && !args.length)) return;
    args.forEach((item) => {
      console.log(item);
    });
  }

  connect() {
    this.sockJS = new SockJS(`${this.WS_URL}?token=${this.sessionSvc.token}`);

    this.sockJS.onopen = () => {
      this.log('SockJS: Connection established');
    };

    this.sockJS.onmessage = (e) => {
      console.log('SockJS:', e.data);
      setTimeout(() => {
        this.sockJsSubject.next(e.data);
      }, 2 * 1000);
    };

    this.sockJS.onclose = (event) => {
      this.log('Connection closed');
    };

    this.sockJS.onerror = (evt) => {
      this.log('SockJS ERROR:', evt);
    };
  }
}
