<div class="input-wrapper">
  <div class="d-flex">
    <input
      #input
      class="lib-cm-input"
      type="text"
      [placeholder]="placeholder"
      [attr.disabled]="disabled || null"
      aria-label="Number"
      [formControl]="myControl"
      [(ngModel)]="vm"
      (ngModelChange)="setInputMask($event)"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      (blur)="onBlur($event)"
      (input)="onInput($event)"
      spellcheck="false"
      [ngClass]="{
        clearable: clearable,
        'border-highlighted': !!vm && highlightIfSet
      }"
    />

    <span
      *ngIf="showAliases && aliases && aliases.length"
      class="icon icon-alias"
      [tooltip]="ContentAlias"
      animation-duration="150"
      content-type="template"
      placement="right"
    >
      <i class="material-icons" style="color: #0071bc">content_paste</i>
    </span>

    <ng-template #ContentAlias>
      <div class="tooltip-content">
        <div class="title">Aliases</div>
        <span *ngFor="let alias of aliases">{{ alias }}</span>
      </div>
    </ng-template>

    <span
      class="reset-btn"
      id="atqa-reset-btn"
      *ngIf="!!vm && clearable && !disabled"
      (click)="reset()"
    >
      <i class="material-icons" [class.highlighted]="highlightIfSet">close</i>
    </span>
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    (optionSelected)="optionSelected($event)"
    [displayWith]="displayProperty.bind(this)"
  >
    <mat-option disabled *ngIf="showSpinner">
      <div class="autocomplete-spinner">
        <mat-spinner [diameter]="22" class="circular-black-spinner"></mat-spinner>
      </div>
    </mat-option>

    <ng-container *ngFor="let option of filteredOptions | async">
      <mat-option *ngIf="!showSpinner" [value]="option">
        <div>{{ option[viewValue] }}</div>
        <div *ngIf="showAliases" class="option-sub-text">
          {{ getFormattedAliases(option?.aliases) }}
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
