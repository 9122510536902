<ng-container *ngIf="!reqLoading">
    <div *ngIf="errorItems" class="error-block">
        <ng-container *ngFor="let err of errorItems | keyvalue">
            <div class="error">
                <div class="err-msg">{{ err.value.msg }}</div>

                <div class="err-file-names">
                    <div *ngFor="let fName of err.value.fNames">{{ fName }}</div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="file-block">
        <div class="sub-title">
            <span>Attachments </span><span *ngIf="fileList.length">({{ fileList.length }})</span
        ><span>:</span>
        </div>

        <div *ngIf="fileList.length" class="file-list">
            <ng-container *ngFor="let file of fileList; index as i">
                <div class="file">
                    <div class="content">
                        <div class="f-icon">
                  <span class="icon">
                    <i class="material-icons">description</i>
                  </span>
                        </div>

                        <div class="f-spec">
                            <div class="name">{{ file.name }}</div>
                            <div class="size">{{ file.size / 1024 / 1024 | number: '.2' }} MB</div>
                        </div>
                    </div>

                    <div class="remove-icon">
                <span class="icon">
                  <i class="material-icons" (click)="removeFile(i)">close</i>
                </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="upload-btn">
        <div class="btn-area" (click)="openFileInput()">
            <div class="content">
                <div class="btn-icon">
              <span class="icon">
                <i class="material-icons">attach_file</i>
              </span>
                </div>

                <div class="btn-placeholder">
                    <ng-content select="[placeholder]"></ng-content>
                </div>
            </div>
        </div>
        <input
            class="d-none"
            #fileInput
            type="file"
            ng2FileSelect
            multiple
            [uploader]="fileUploader"
            [accept]="accept"
        />
    </div>
</ng-container>

<ng-container *ngIf="reqLoading">
    <spinner-rect></spinner-rect>
</ng-container>
