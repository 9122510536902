import { Inject, Injectable } from '@angular/core';
import MatomoTracker from '@datapunt/matomo-tracker-js';
import { TrackEventParams } from '@datapunt/matomo-tracker-js/es/types';

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  constructor(@Inject('env') private env) {}

  tracker = new MatomoTracker({
    urlBase: this.env.matomoUrlBase,
    siteId: this.env.matomoSiteId,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
      // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
      // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  });

  trackPageView(title: string) {
    this.tracker.trackPageView({
      documentTitle: title, // optional
    });
  }

  trackEvent(params: TrackEventParams) {
    this.tracker.trackEvent(params);
  }

  setUserId(userId: string) {
    this.tracker.pushInstruction('setUserId', userId);
  }

  setCustomVariable(index: number, name: string, value: string, scope: string = 'visit') {
    this.tracker.pushInstruction('setCustomVariable', index, name, value, scope);
  }

  public sendNotificationEvent(type: string, message: string): void {
    this.trackEvent({
      category: `Notification type: ${type}`,
      action: message,
    });
  }
}
