/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { share } from 'rxjs/operators';
import { FormControlsService } from '../services/form-controls.service';

@Component({
  selector: 'lib-cm-radio-group',
  templateUrl: './cm-radio-group.component.html',
  styleUrls: ['./cm-radio-group.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmRadioGroupComponent),
      multi: true,
    },
  ],
})
export class CmRadioGroupComponent implements OnInit, ControlValueAccessor {
  val: any;

  @Input() options: any;

  @Input() advancedOptions: AdvancedOption[];

  @Input() disabled: string;

  @Output() change = new EventEmitter<MatRadioChange>();

  valueChange = this.change.pipe(share());

  onChange: (val) => {};

  onTouched: (val) => {};

  constructor(public formControlsService: FormControlsService) {}

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      if (this.onChange) {
        this.onChange(val);
      }
      if (this.onTouched) {
        this.onTouched(val);
      }
    }
  }

  get value() {
    return this.val;
  }

  ngOnInit() {
    this.writeValue('false');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  radioGroupChange($event: MatRadioChange) {
    this.change.emit($event);
  }
}

export interface AdvancedOption {
  label: string;
  value: boolean;
  disabled?: boolean;
}
