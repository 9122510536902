import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cm-utmc-users-modal',
  templateUrl: './utmc-users-modal.component.html',
  styleUrls: ['./utmc-users-modal.component.scss'],
})
export class UtmcUsersModalComponent implements OnInit {
  internalUsers: any[];

  projectInfo = environment.project;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.internalUsers = this.data.internalUsers;
  }
}
