<div class="content-block">
  <div class="title">Error 404 - Page Not Found</div>

  <div class="return-back">
    <span>To return back to the previous page, click</span>
    <span class="here-link" (click)="goBack()">here</span>
  </div>

  <div class="dashboard-link"><a [routerLink]="'/' + cmsBasePath">Back to CMS Dashboard</a></div>
</div>
