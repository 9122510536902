<cms-modal-layout>
  <span title class="title">Warning</span>

  <div content>
    Your client authorities have been changed. You will be logged off in {{ secondsLeft }} seconds!
  </div>


    <span actionBtns>
    <button mat-button    [cdkTrapFocusAutoCapture]="true" [cdkTrapFocus]="true" (click)="closeModal()">Ok</button>
  </span>
</cms-modal-layout>
