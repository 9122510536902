/* eslint-disable no-useless-escape */
/* eslint-disable no-empty-pattern */
/* eslint-disable max-classes-per-file */
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import * as moment from 'moment';
import { SsnOrSsn4 } from '../components/ssn-autocomplete/ssn-autocomplete.component';
import { removeControlError } from './jsUtils';

export const RegExps = {
  email: /^[\w-\.!#$%&'*+-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}$/,
  initials: /^$|^[A-Z1-9]{1,3}$/,
};

export class CustomValidators {
  static regexpValidator(regExp: RegExp, key: string, nameField?: string): ValidatorFn {
    return (control: AbstractControl) => {
      const value = nameField ? control.value[nameField] : control.value;

      if (value === undefined) {
        return null;
      }

      const valid = regExp.test(value);
      return valid ? null : { regExp: { key } };
    };
  }

  static dateNotInFutureValidator(key: string): ValidatorFn {
    return (control: AbstractControl) => {
      const value = moment(control.value);
      return value.isAfter(moment()) ? { dateNotInFuture: { key } } : null;
    };
  }

  static dateNotInPastValidator(key: string): ValidatorFn {
    return (control: AbstractControl) => {
      const value = moment(control.value);
      return value.isBefore(moment()) ? { dateNotInPast: { key } } : null;
    };
  }

  static maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      return value && value.length > maxLength ? { maxLength: { requiredLength: maxLength, actualLength: value.length } } : null;
    };
  }

  static noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }
}

export class CustomAsyncValidators {
  static uniquenessEmailValidator(
    observable: ({}) => Observable<any>,
    key: string,
    resultKey = '',
    defaultValue: string = null,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> =>
      observable({ [key]: control.value }).pipe(
        debounceTime(300),
        map((res) => {
          if (res[resultKey]) {
            return null;
          }
          if (defaultValue) {
            return control.value !== defaultValue ? { uniqueField: { key } } : null;
          }
          return { uniqueField: { key } };
        }),
      );
  }
}

export const customRequiredValidator = (errorMessage: string): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!Validators.required(control)) {
      return null;
    }

    return { custom: { message: errorMessage, required: true } };
  };
};

export const customPatternValidator = (
  pattern: string | RegExp,
  errorMessage: string,
): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!Validators.pattern(pattern)(control)) {
      return null;
    }

    return { custom: { message: errorMessage, pattern: true } };
  };
};

export function checkUserInitialsValidator(control: AbstractControl): ValidationErrors | null {
  let { value } = control;

  if (!value) {
    return null;
  }

  if (!/^$|^[1-9a-zA-Z]*$/.test(value)) {
    control.setValue((value = value.slice(0, -1)));
  }

  const validLen = /^$|^.{1,3}$/.test(value);
  return validLen ? null : { custom: { message: 'Not more than 3 characters are allowed' } };
}
const PIN_REGEX = /^\d{4}$/;

export const isValidPin = (control: FormControl) => {
  const { value } = control;

  if (!value) {
    return null;
  }

  const isValid = PIN_REGEX.test(value);
  return isValid ? null : { custom: { message: 'Invalid PIN' } };
};

export const checkLoginMail = (group: FormControl) => {
  if (!(group && group.root && (group.root as FormGroup).controls)) {
    return null;
  }

  const { controls } = group.root as FormGroup;
  const login = controls.login.value;
  const email = controls.email.value;
  const password = controls.password.value;
  return (password.indexOf(login) !== -1 && login !== '') ||
    (password.indexOf(email) !== -1 && email !== '')
    ? { containLoginMail: true }
    : null;
};

export const checkPasswordSpaces = (group: FormControl) => {
  if (!(group && group.root && (group.root as FormGroup).controls)) {
    return null;
  }

  const pass = (group.root as FormGroup).controls.password.value;
  if (!pass) return null;

  const err = /\s/.test(pass);
  return err ? { containsSpaces: true } : null;
};

export const checkPasswords = (group: FormControl) => {
  if (!(group && group.root && (group.root as FormGroup).controls)) {
    return null;
  }

  const { controls } = group.root as FormGroup;
  const pass = controls.password.value;
  const confirmPass = controls.repeatpass.value;

  if (!confirmPass) {
    return null;
  }

  return pass === confirmPass ? null : { custom: { message: 'Passwords do not match' } };
};

const requiredObj = { required: true };

export const ssnRequiredValidator: ValidatorFn = (control: FormControl) => {
  if (Validators.required(control)) {
    return requiredObj;
  }

  const val: SsnOrSsn4 = control.value;

  if (!val.ssn && !val.ssn4) {
    return requiredObj;
  }

  return null;
};

export const ssnMaxLengthValidator: ValidatorFn = (control: FormControl) => {
  const val: SsnOrSsn4 = control.value;

  if (val && (val.ssn || val.ssn4) && val.ssn?.length < 9) {
    return { custom: { message: 'SSN must contain 9 digits' } };
  }

  return null;
};

export const ssnOrSsn4FormatValidator = (control: FormControl) => {
  const val: SsnOrSsn4 = control.value;

  if (val && val.ssn && val.ssn.length < 9) {
    return { custom: { message: 'Wrong format' } };
  }

  return null;
};

export const fullSsnRequiredValidator: ValidatorFn = (control: FormControl) => {
  if (Validators.required(control)) {
    return requiredObj;
  }

  const val: SsnOrSsn4 = control.value;

  if (!val || !val.ssn) {
    return requiredObj;
  }

  return null;
};

export const percentageValidator = (control: FormControl) => {
  const min = 0;
  const max = 100;
  const input = control.value;
  const isValid = input >= min && input <= max;
  return isValid ? null : { custom: { message: 'Wrong percentage format' } };
};

export const dateFromDateToValidator = (
  fromName: string,
  toName: string,
  toLabel: string,
): ValidatorFn => {
  return (formGroup: FormGroup) => {
    const dateFromControl = formGroup.get(fromName) as FormControl;
    const dateToControl = formGroup.get(toName) as FormControl;

    if (!dateFromControl || !dateToControl) {
      return null;
    }

    const dateFrom = moment(dateFromControl.value || null, 'YYYY-MM-DD');
    const dateTo = moment(dateToControl.value || null, 'YYYY-MM-DD');

    if (!dateFrom.isValid() || !dateTo.isValid()) {
      removeControlError(dateFromControl, 'custom');
      return null;
    }

    if (!dateFrom.isSameOrBefore(dateTo, 'day')) {
      dateFromControl.markAsTouched();
      dateFromControl.markAsDirty();

      dateFromControl.setErrors({
        custom: { message: `Must be before or equal to ${toLabel}` },
      });

      return null;
    }

    removeControlError(dateFromControl, 'custom');
    return null;
  };
};

export const statementsFromStatementsToValidator = (
  fromMonthName: string,
  fromYearName: string,
  toMonthName: string,
  toYearName: string,
  toLabel: string,
): ValidatorFn => {
  return (formGroup: FormGroup) => {
    const sFromMonthControl = formGroup.get(fromMonthName) as FormControl;
    const sFromYearControl = formGroup.get(fromYearName) as FormControl;
    const sToMonthControl = formGroup.get(toMonthName) as FormControl;
    const sToYearControl = formGroup.get(toYearName) as FormControl;

    if (!sFromMonthControl || !sFromYearControl || !sToMonthControl || !sToYearControl) {
      return null;
    }

    const sFromMonthControlVal = Number(sFromMonthControl.value);
    const sFromYearControlVal = Number(
      typeof sFromYearControl.value === 'object'
        ? (sFromYearControl.value || {}).year
        : sFromYearControl.value,
    );
    const sToMonthControlVal = Number(sToMonthControl.value);
    const sToYearControlVal = Number(
      typeof sToYearControl.value === 'object'
        ? (sToYearControl.value || {}).year
        : sToYearControl.value,
    );

    if (
      !sFromMonthControlVal ||
      !sFromYearControlVal ||
      !sToMonthControlVal ||
      !sToYearControlVal
    ) {
      removeControlError(sFromMonthControl, 'custom');
      removeControlError(sFromYearControl, 'custom');
      return null;
    }

    const statementsFrom = moment(`${sFromYearControlVal}-${sFromMonthControlVal}`, 'YYYY-MM');
    const statementsTo = moment(`${sToYearControlVal}-${sToMonthControlVal}`, 'YYYY-MM');

    if (!statementsFrom.isSameOrBefore(statementsTo, 'month')) {
      sFromMonthControl.markAsDirty();
      sFromMonthControl.markAsTouched();

      sFromYearControl.markAsDirty();
      sFromYearControl.markAsTouched();

      sFromMonthControl.setErrors({
        custom: { message: `Must be before or equal to ${toLabel}` },
      });

      sFromYearControl.setErrors({
        custom: { message: '' },
      });

      return null;
    }

    removeControlError(sFromMonthControl, 'custom');
    removeControlError(sFromYearControl, 'custom');
    return null;
  };
};
