<cm-menu [ngClass]="{ minimized: menuService.isMinimized }" [isPrimary]="true"></cm-menu>
<div class="content-body">
  <cm-details-header></cm-details-header>

  <div class="content-container">
    <div class="content-wrapper">
      <div cdkScrollable class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <cm-left-sidebar
      cdkScrollable
      [ngClass]="{ minimized: sidebarService.isMinimized, large: sidebarService.isLarge }"
      *ngIf="sidebarService.sidebarEnabled"
    ></cm-left-sidebar>
  </div>
</div>
