/* eslint-disable class-methods-use-this */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { NumberMask, TextMask } from '../services/form-controls.service';

@Component({
  selector: 'lib-cm-input',
  templateUrl: './cm-input.component.html',
  styleUrls: ['./cm-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmInputComponent),
      multi: true,
    },
  ],
})
export class CmInputComponent implements ControlValueAccessor {
  val: string;

  isCurrencyMask: boolean;

  onChange: (val) => {};

  onTouched: (val) => {};

  @Input() disabled: boolean;

  @Input() type: string;

  @Input() clearable: boolean;

  @Input() highlightIfSet: boolean;

  @Input() placeholder = 'Enter Your Value';

  @Input() formControl: FormControl;

  @Input() maxlength: number;

  @Input() restrictPattern = false;

  @Input() set otherMask(mask: any) {
    this.mask = mask || this.createInputMask();
  }

  @ViewChild('inputFef', { static: true }) inputElement: ElementRef;

  mask: any[] | boolean | ((rawValue) => boolean | any[]) = this.createInputMask();

  @Output() blur = new EventEmitter();

  @Output() focus = new EventEmitter();

  @Input() set value(val) {
    if (val !== undefined && val !== null) {
      if (this.val !== val) {
        this.val = `${val}`;
        const rawVal = this.isCurrencyMask ? this.val.replace(/([$,%])/g, '') : this.val;
        if (this.onChange) {
          this.onChange(rawVal);
        }
        if (this.onTouched) {
          this.onTouched(rawVal);
        }
      }
    } else {
      this.val = '';
    }
  }

  get value() {
    return this.val;
  }

  @Input() set inputMask(value: any) {
    this.isCurrencyMask = false;
    this.mask = this.createInputMask({ ...value });
  }

  @Input() set maskArray(value: any[]) {
    this.mask = value;
  }

  @Input() set currencyMask(value: NumberMask | boolean) {
    this.isCurrencyMask = true;
    this.mask = createNumberMask(
      typeof value === 'boolean'
        ? { allowDecimal: true, decimalLimit: 2, decimalSymbol: '.' }
        : { ...value },
    );
  }

  onfocus(event: any) {
    this.focus.emit(event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  reset() {
    if (!this.disabled) {
      this.writeValue('');
      this.inputElement.nativeElement.focus();
    }
  }

  createInputMask(
    { maxLength = 60, disableStartingSpace = true, regex = '[\\s\\S]' }: TextMask = {
      maxLength: 60,
      disableStartingSpace: true,
    },
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (rawValue) => {
      let mask: boolean | any[] = false;
      if (maxLength) {
        mask = [];
        for (let i = 0; i < maxLength; i++) {
          // mask[i] = /[\s\S]/
          if (regex) {
            mask[i] = new RegExp(regex);
          } else if (disableStartingSpace) {
            mask[0] = /\S/;
          }

          // if (specialCharacters.length>0) {
          //     let reg = '[\\d\\w]'
          //     specialCharacters.forEach(x=> {
          //         reg= reg + x;
          //     })
          //     mask[i] = new RegExp(reg)
          // } else {

          // }
        }
      }
      return mask;
    };
  }
}
