export const UserIs = Object.freeze({
  INTERNAL: Object.freeze({
    ADMIN: 'ROLE_ADMIN',
    ANALYST: 'ROLE_ANALYST',
    ANALYST_VIEW_ONLY: 'ROLE_ANALYST_VIEW_ONLY',
  }),
  CLIENT: Object.freeze({
    AM: 'ROLE_CLIENT_AM',
    GC: 'ROLE_CLIENT_GC',
    TX: 'ROLE_CLIENT_TX',
  }),
  PARTNER: 'ROLE_PARTNER',
  // Client Management user roles
  CM: Object.freeze({
    CLIENT_MGR: 'ROLE_CM_CLIENT_MGR',
    BILL_MGR: 'ROLE_CM_BILL_MGR',
    TLE: 'ROLE_CM_TLE',
    CLIENT_VIEW_ONLY: 'ROLE_CM_CLIENT_VIEW_ONLY',
  }),
});

export const UserTags = Object.freeze({
  AM: 'AM',
  TX: 'TX',
  TX_: 'TX_',
  DC: 'DC',
  DC_: 'DC_',
  BC: 'BC',
  BILL: 'BILL',
  BILL_: 'BILL_',
  LTR: 'LTR',
  REF: 'REF',
  VIP: 'VIP',
});

export interface UserIsAndHasTag {
  roles: (string | object)[];
  tags: string[];
}
