import { Injectable, NgZone } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { LogoutService } from './logout.service';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class UserTabsService {
  private readonly CRON_STEP: number; // ms

  private readonly MAX_DOWN_TIME: number; // ms

  private readonly LS_LAST_ACTIVE_TIME_KEY: string;

  constructor(
    private storageSvc: StorageService,
    private ngZone: NgZone,
    private logoutSvc: LogoutService,
    private sessionSvc: SessionService,
  ) {
    this.MAX_DOWN_TIME = 20 * 1000;
    this.LS_LAST_ACTIVE_TIME_KEY = 'last.time.application.active';
    this.CRON_STEP = 1000;
  }

  destroyPreviousSession() {
    const matomoMessage = '20 seconds expired';

    if (!this.sessionSvc.token) {
      this.startCron();
      return;
    }

    if (!this.lastActiveTime) {
      this.logoutSvc.logout(matomoMessage);

      this.startCron();
      return;
    }

    const currentTime = new Date().getTime();

    if (currentTime - this.lastActiveTime > this.MAX_DOWN_TIME) {
      this.logoutSvc.logout(matomoMessage);
    }

    this.startCron();
  }

  private get lastActiveTime(): number {
    const lastActiveTime = Number(this.storageSvc.get(this.LS_LAST_ACTIVE_TIME_KEY));

    if (lastActiveTime > 0) {
      return lastActiveTime;
    }

    return null;
  }

  private setLastActiveTime() {
    this.storageSvc.set(this.LS_LAST_ACTIVE_TIME_KEY, new Date().getTime());
  }

  private startCron() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.setLastActiveTime();
      }, this.CRON_STEP);
    });
  }
}
