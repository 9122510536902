/* eslint-disable import/no-extraneous-dependencies */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { TextMaskModule } from 'angular2-text-mask';
import { A11yModule } from '@angular/cdk/a11y';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { CmRadioGroupComponent } from './cm-radio-group/cm-radio-group.component';
import { CmAutocompleteComponent } from './cm-autocomplete/cm-autocomplete.component';
import { CmCheckboxComponent } from './cm-checkbox/cm-checkbox.component';
import { CmDatepickerComponent } from './cm-datepicker/cm-datepicker.component';
import { CmPopupComponent } from './cm-popup/cm-popup.component';
import { PopupService } from './services/popup.service';
import { CmTextareaComponent } from './cm-textarea/cm-textarea.component';
import { CmDateTimepickerComponent } from './cm-datetimepicker/cm-datetimepicker.component';
import { CmSelectComponent } from './cm-select/cm-select.component';
import { CmInputComponent } from './cm-input/cm-input.component';
import { UtmcButtonComponent } from './utmc-button/utmc-button.component';
import { FormControlsComponent } from './form-controls.component';
import { MatomoService } from './services/matomo.service';
import { CmDateRangepickerComponent } from './cm-daterangepicker/cm-daterangepicker.component';
import { CmChipsComponent } from './cm-chips/cm-chips.component';
import { RestrictPatternDirectiveModule } from './directives/restrict-pattern-directive.module';

const CUSTOM_DATETIME_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'L, LT',
  },
  display: {
    dateInput: 'L, LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const CUSTOM_MOMENT_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    FormControlsComponent,
    UtmcButtonComponent,
    CmInputComponent,
    CmSelectComponent,
    CmRadioGroupComponent,
    CmAutocompleteComponent,
    CmCheckboxComponent,
    CmDatepickerComponent,
    CmPopupComponent,
    CmTextareaComponent,
    CmDateTimepickerComponent,
    CmDateRangepickerComponent,
    CmChipsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    // NgxMaskModule,
    TextMaskModule,
    MatButtonModule,
    A11yModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    TooltipModule,
    NgxMatMomentModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatIconModule,
    RestrictPatternDirectiveModule,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATETIME_MOMENT_FORMATS },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_MOMENT_FORMATS,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { minWidth: '400px', hasBackdrop: true } },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
    PopupService,
    MatomoService,
  ],
  exports: [
    FormControlsComponent,
    UtmcButtonComponent,
    CmInputComponent,
    CmSelectComponent,
    CmRadioGroupComponent,
    CmAutocompleteComponent,
    CmCheckboxComponent,
    CmDatepickerComponent,
    CmPopupComponent,
    CmTextareaComponent,
    CmDateTimepickerComponent,
    CmDateRangepickerComponent,
    CmChipsComponent,
    NgxMatMomentModule,
    CmChipsComponent,
  ],
  entryComponents: [CmPopupComponent],
})
export class FormControlsModule {
  public static forRoot(environment: any): ModuleWithProviders<FormControlsModule> {
    return {
      ngModule: FormControlsModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}
