/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlsService } from '../services/form-controls.service';

@Component({
  selector: 'lib-cm-daterangepicker',
  templateUrl: './cm-daterangepicker.component.html',
  styleUrls: ['./cm-daterangepicker.component.css'],
})
export class CmDateRangepickerComponent implements OnInit {
  @Input() options: any;

  @Input() disabled: boolean;

  @Input() group: FormGroup;

  @Output() dateChange = new EventEmitter<any>();

  constructor(public formControlsService: FormControlsService) {}

  ngOnInit() {}

  defaultValue(): void {
    this.group.patchValue({ dateFrom: null, dateTo: null });
  }
}
