/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { CmInputComponent } from 'form-controls';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ForgotPasswordService } from 'src/app/services/general/forgot-password.service';
import { MetaService } from 'src/app/services/meta.service';
import { ComponentDestroyClass } from 'src/app/shared/classes/component-destroy.class';
import {
  checkPasswords,
  customPatternValidator,
  customRequiredValidator,
  isValidPin,
} from 'src/app/shared/utils/form-validators';

@Component({
  selector: 'cms-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
  providers: [ForgotPasswordService],
})
export class CreatePasswordComponent extends ComponentDestroyClass implements OnInit, OnDestroy {
  @ViewChild('passwordControl', { static: false }) passwordControl: CmInputComponent;

  @ViewChild('repeatpassControl', { static: false }) repeatpassControl: CmInputComponent;

  form: FormGroup;

  loading = false;

  error: string = null;

  passwordVisibility = false;

  repeatpassVisibility = false;

  private errorTimeout = null;

  private readonly routeQueryParams: Params;

  private readonly REQUIRED_MESSAGE = 'The field should be filled in';

  private readonly HIDE_ERROR_DELAY = 7 * 1000; // ms

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private metaSvc: MetaService,
    private notificationSvc: NotificationsService,
    private router: Router,
    private forgotPasswordSvc: ForgotPasswordService,
    private apiSvc: ApiService,
  ) {
    super();
    this.routeQueryParams = this.route.snapshot.queryParams;
  }

  ngOnInit(): void {
    this.metaSvc.setTitle('Create New Password');
    this.buildForm();
    this.subscribePasswordChange();
    this.formPatchValue();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get submitBtnDisabled() {
    return this.loading || !this.form.valid;
  }

  get email() {
    return this.routeQueryParams.email;
  }

  onSubmit() {
    this.loading = true;
    const formValue = this.form.value;

    this.createPassword(this.formToPayload(formValue))
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (response: any) => {
          this.notificationSvc.success('Success', 'Password created successfully');
          this.router
            .navigate(['login'])
            .then(() => (this.loading = false))
            .catch((err) => console.error(err));
        },
        error: (err: any) => {
          this.showError(err);
          this.loading = false;
          console.error(err);
        },
      });
  }

  resendPin() {
    this.forgotPasswordSvc
      .forgotPassword(this.email)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (response: any) => {
          this.notificationSvc.success('Success', 'PIN sent');
          this.router.navigate(['login']);
        },
        error: (err: any) => {
          this.showError(err);
          console.error(err);
        },
      });
  }

  onChangePasswordVisibility() {
    this.passwordVisibility = !this.passwordVisibility;
    const passwordEl: HTMLInputElement = this.passwordControl.inputElement.nativeElement;
    passwordEl.focus();
    this.putCursorEndText(passwordEl, this.form.get('password'));
  }

  onChangeRepeatpassVisibility() {
    this.repeatpassVisibility = !this.repeatpassVisibility;
    const passwordEl: HTMLInputElement = this.repeatpassControl.inputElement.nativeElement;
    passwordEl.focus();
    this.putCursorEndText(passwordEl, this.form.get('repeatpass'));
  }

  private putCursorEndText(element: HTMLInputElement, formControl: AbstractControl) {
    const { value } = formControl;
    const len = (value && value.length) || 0;
    setTimeout(() => {
      element.setSelectionRange(len, len);
    }, 0);
  }

  private buildForm() {
    this.form = this.fb.group({
      pin: ['', { validators: [customRequiredValidator(this.REQUIRED_MESSAGE), isValidPin] }],
      password: [
        '',
        {
          validators: [
            customRequiredValidator(this.REQUIRED_MESSAGE),
            customPatternValidator(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#!%*?&])([A-Za-z\d$@#!%*?&]*[^\s]*)+$/,
              'Wrong format',
            ),
            Validators.minLength(8),
            Validators.maxLength(24),
          ],
        },
      ],
      repeatpass: [
        '',
        { validators: [customRequiredValidator(this.REQUIRED_MESSAGE), checkPasswords] },
      ],
    });
  }

  private formPatchValue() {
    this.form.patchValue({
      pin: this.routeQueryParams.pin || '',
    });
  }

  private showError(err: any) {
    this.clearErrorTimeout();
    this.error = err.message || 'Error';

    this.errorTimeout = setTimeout(() => {
      this.hideError();
    }, this.HIDE_ERROR_DELAY);
  }

  private hideError() {
    this.error = null;
  }

  private clearErrorTimeout() {
    if (!this.errorTimeout) {
      return;
    }

    clearTimeout(this.errorTimeout);
  }

  private createPassword(body: CreatePasswordPayload) {
    const url = 'users/login';
    return this.apiSvc.put(url, body, {}, false);
  }

  private formToPayload(formValue: any): CreatePasswordPayload {
    return {
      pin: formValue.pin,
      newPassword: formValue.password,
      login: this.routeQueryParams.login,
    };
  }

  private subscribePasswordChange() {
    this.form
      .get('password')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: () => {
          this.form.get('repeatpass').updateValueAndValidity({ onlySelf: true });
        },
      });
  }
}

interface CreatePasswordPayload {
  pin: string;
  newPassword: string;
  login: string;
}
