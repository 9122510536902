import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextMaskModule } from 'angular2-text-mask';
import { SsnAutocompleteComponent } from './ssn-autocomplete.component';

@NgModule({
  declarations: [SsnAutocompleteComponent],
  imports: [CommonModule, MatAutocompleteModule, FormsModule, TextMaskModule, ReactiveFormsModule],
  exports: [SsnAutocompleteComponent],
})
export class SsnAutocompleteModule {}
