<!--<p>-->
<!--  menu works!-->
<!--</p>-->
<nav>
  <ng-container *ngFor="let item of items">
    <cm-menu-item
      *ngIf="hasAccess(item)"
      [item]="item"
      [ngClass]="{ minimized: menuService.isMinimized && isPrimary }"
      [adaptiveSubmenu]="isPrimary"
    ></cm-menu-item>
  </ng-container>
</nav>
