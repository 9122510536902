/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, delay, toArray } from 'rxjs/operators';
import { ApiService } from '../../app/services/api.service';

@Injectable()
export class ReleaseService {
  constructor(private apiService: ApiService) {}

  getNotifications(params): Observable<any> {
    const url = `notifications`;
    return this.apiService.get<any>(url, { params });
  }

  markAsRead(id) {
    const url = `notifications/${id}/read`;
    return this.apiService.put(url, '');
  }

  markAllAsRead(ids: number[] = []) {
    return new Promise((resolve, reject) => {
      from(ids)
        .pipe(
          concatMap((id) => {
            return this.markAsRead(id).pipe(delay(500));
          }),
          toArray(),
        )
        .subscribe(
          (uploadResponses) => {
            resolve(true);
          },
          (errorResponses) => {
            console.error(errorResponses);
            reject();
          },
        );
    });
  }
}
