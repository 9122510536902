/* eslint-disable class-methods-use-this */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { NotificationsService } from 'angular2-notifications';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

import { environment } from 'src/environments/environment';
import { ContactUsService } from '../../services/contact-us.service';

@Component({
  selector: 'cms-contact-us-modal-for-internal',
  templateUrl: './contact-us-modal-for-internal.component.html',
  styleUrls: ['./contact-us-modal-for-internal.component.scss'],
  providers: [ContactUsService],
})
export class ContactUsModalForInternalComponent implements OnInit {
  config: any;

  form: FormGroup;

  projectName = environment.project.name;

  readonly type: 'support' | 'utmc';

  private initTokens = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private contactUsSvc: ContactUsService,
    private notificationsSvc: NotificationsService,
  ) {
    this.type = this.data.type;
  }

  ngOnInit(): void {
    if (this.isSupportType) {
      this.contactUsSvc.initTokens().subscribe({
        next: () => {
          this.initTokens = true;
        },
        error: (err: any) => {
          this.notificationsSvc.error('Error', this.getErrorMessage(err));
        },
      });
    }

    this.buildForm();
    this.initConfig();
  }

  get documentsControl() {
    return this.form.get('documents');
  }

  get isSupportType() {
    return this.type === 'support';
  }

  get sendBtnDisabled() {
    return this.form.invalid || (this.isSupportType && !this.initTokens);
  }

  setFilesInDocumentsControl(files: any) {
    this.documentsControl.setValue(files);
  }

  sendMessageTo() {
    const body = this.formValuesToRequest();

    (this.isSupportType
      ? this.contactUsSvc.sendMessageToSupport(body)
      : this.contactUsSvc.sendMessageToAdmin(body)
    ).subscribe({
      next: () => {
        this.dialogRef.close();
        this.notificationsSvc.success('Success', 'Messages has been sent');
      },
      error: (err) => {
        this.notificationsSvc.error('Error', err.message);
      },
    });
  }

  private formValuesToRequest() {
    const formValue = cloneDeep(this.form.value);

    const payload = {
      subject: formValue.subject,
      body: formValue.body,
      attachments: [],
    };

    (formValue.documents || []).forEach((attachment) => payload.attachments.push(attachment));

    return payload;
  }

  private buildForm() {
    this.form = this.fb.group({
      subject: [null, Validators.required],
      body: [null, Validators.required],
      documents: [],
      selectedDocuments: [],
    });
  }

  private initConfig() {
    this.config = {
      uploader: new FileUploader({
        maxFileSize: 15 * 1024 * 1024,
        queueLimit: 5,
      }),
    };
  }

  private getErrorMessage(err: any): string {
    if (err.status === 400) {
      return err.message;
    }

    return `Something went wrong. Please contact ${this.projectName} Support.`;
  }
}
