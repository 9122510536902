<cms-modal-layout>
  <span title class="title"
    >{{ isSupportType ? 'Contact ' + this.projectName + ' Support' : 'Contact  ' + this.projectName + '  Specialist' }}
  </span>

  <div content class="position-relative" [formGroup]="form">
    <div class="section">
      <span class="title-section">Subject:</span>

      <lib-cm-input
        placeholder="Enter a subject..."
        formControlName="subject"
        [maxlength]="60"
      ></lib-cm-input>
    </div>

    <div class="section mt-3">
      <span class="title-section"> Message:</span>

      <lib-cm-textarea
        placeholder="Enter a message..."
        [isAutoHeight]="true"
        formControlName="body"
      ></lib-cm-textarea>
    </div>

    <div class="section mt-2">
      <cms-attachments
        [config]="config"
        (submitFiles)="setFilesInDocumentsControl($event)"
        [accept]="''"
        ><span placeholder>Add up to 5 files</span></cms-attachments
      >
    </div>
  </div>

  <span closeBtnTitle>Cancel</span>

  <span actionBtns>
    <button mat-button [disabled]="sendBtnDisabled" (click)="sendMessageTo()">Send</button>
  </span>
</cms-modal-layout>
