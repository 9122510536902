export class RootModule {
  readonly CM: boolean;

  readonly CMS: boolean;

  constructor(module: RootModuleType) {
    this[module] = true;
  }
}

export enum RootModuleType {
  CM = 'CM',
  CMS = 'CMS',
}
