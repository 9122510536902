import { Component, Input } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { environment } from 'src/environments/environment';
import { MenuItem } from '../../services/menu.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'cm-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent {
  @Input() items: MenuItem[];

  constructor(private projectService: ProjectService) {}

  hasAccess(item: MenuItem) {
    const accessProjects = item.accessRules;

    if (accessProjects){
      return this.projectService.validAccessRuleByProject(accessProjects);
    }

    return true;
  }
}
