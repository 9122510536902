export enum ProjectIs {
  UTMC = 'UTMC',
  STOP_CLAIMS = 'STOP_CLAIMS',
  ACME = 'ACME',
}

export interface Environment {
  production: boolean;
  isStage: boolean;
  coreUrl: string;
  matomoSiteId: number;
  matomoUrlBase: string;
  userId: string;
  project: ProjectInfo;
}

export interface ProjectInfo {
  name: string;
  abbreviation: ProjectIs;
  phone: string;
  fax: string;
  email: string;
  address_line_one: string;
  address_line_two: string;
  url: string;
  parentProjectAbbreviation: string;
}
