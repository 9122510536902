/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { UserIsAndHasTag } from '../models/user-is.model';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private profileSvc: ProfileService) {}

  userIs(roles: (string | object)[]): boolean {
    if (!roles || !roles.length) {
      return true;
    }

    const rolesNames = [];

    for (const role of roles) {
      if (typeof role === 'object') {
        rolesNames.push(...this.getDeepValues(role));
        continue;
      }

      rolesNames.push(role);
    }

    return this.checkUserHasSomeRole(rolesNames);
  }

  userIsAndHasTag(rolesAndTagsPairs: UserIsAndHasTag[]) {
    if (!rolesAndTagsPairs || !rolesAndTagsPairs.length) {
      return true;
    }

    return rolesAndTagsPairs.some(
      (pair) => this.userIs(pair.roles) && this.userHasSomeTag(pair.tags),
    );
  }

  userHasSomeTag(tags: string[]) {
    if (!tags || !tags.length) {
      return true;
    }

    return tags.some((tag) => this.profileSvc.tags.find((item) => tag === item));
  }

  private checkUserHasSomeRole(rolesNames: string[]): boolean {
    return rolesNames.some((roleName: string) =>
      this.profileSvc.rolesNames.find((item: string) => item === roleName),
    );
  }

  private getDeepValues(obj: any) {
    const values = [];

    for (const val of Object.values(obj)) {
      if (typeof val !== 'string') {
        values.push(...this.getDeepValues(val));
      } else {
        values.push(val);
      }
    }

    return values;
  }
}
