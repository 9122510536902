import { Component, Input } from '@angular/core';
import { InfoField } from '../services/details-header.service';

@Component({
  selector: 'cm-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent {
  @Input() fields: InfoField[];
}
