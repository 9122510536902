/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { takeUntil } from 'rxjs/operators';
import { ForgotPasswordService } from 'src/app/services/general/forgot-password.service';
import { MetaService } from 'src/app/services/meta.service';
import { ComponentDestroyClass } from 'src/app/shared/classes/component-destroy.class';
import {
  customPatternValidator,
  customRequiredValidator,
} from 'src/app/shared/utils/form-validators';

@Component({
  selector: 'cms-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ForgotPasswordService],
})
export class ForgotPasswordComponent extends ComponentDestroyClass implements OnInit, OnDestroy {
  form: FormGroup;

  loading = false;

  error: string = null;

  private errorTimeout = null;

  private readonly REQUIRED_MESSAGE = 'The field should be filled in';

  private readonly HIDE_ERROR_DELAY = 4 * 1000; // ms

  constructor(
    private fb: FormBuilder,
    private notificationSvc: NotificationsService,
    private router: Router,
    private metaSvc: MetaService,
    private forgotPasswordSvc: ForgotPasswordService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.metaSvc.setTitle('Restore Password');
    this.buildForm();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  get submitBtnDisabled() {
    return this.loading || !this.form.valid;
  }

  onSubmit() {
    this.loading = true;
    const formValue = this.form.value;

    this.forgotPasswordSvc
      .forgotPassword(formValue.email)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (response: any) => {
          this.notificationSvc.success(
            'Information',
            'Please check your email for instructions on resetting your password',
          );
          this.router
            .navigate(['login'])
            .then(() => (this.loading = false))
            .catch((err) => console.error(err));
        },
        error: (err: any) => {
          this.showError(err);
          this.loading = false;
          console.error(err);
        },
      });
  }

  private buildForm() {
    this.form = this.fb.group({
      email: [
        null,
        {
          validators: [
            customRequiredValidator(this.REQUIRED_MESSAGE),
            customPatternValidator(
              /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
              'Wrong format',
            ),
          ],
        },
      ],
    });
  }

  private showError(err: any) {
    this.clearErrorTimeout();
    this.error = err.message;

    this.errorTimeout = setTimeout(() => {
      this.hideError();
    }, this.HIDE_ERROR_DELAY);
  }

  private hideError() {
    this.error = null;
  }

  private clearErrorTimeout() {
    if (!this.errorTimeout) {
      return;
    }

    clearTimeout(this.errorTimeout);
  }
}
