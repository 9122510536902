/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserIs } from '../models/user-is.model';
import { AuthorizationService } from '../services/authorization.service';
import { CM_BASE_PATH, CMS_BASE_PATH } from '../shared/app.const';
import { ProjectService } from 'src/app/services/project.service';
import { MenuService } from 'src/app-base/services/menu.service';
import { menuItems } from 'src/app/app.menu';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  constructor(
    private authorizationSvc: AuthorizationService,
    private router: Router,
    private projectService: ProjectService,
    private menuService: MenuService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    if (route.data.menuType) {
      const accessRulesChain = this.menuService.getAccessRulesByType(route.data.menuType, menuItems);

      if (accessRulesChain) {
        for (const accessRule of accessRulesChain) {
          if (!this.projectService.validAccessRuleByProject(accessRule)) {
            return this.redirectToDefaultPage();
          }
        }
      }
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    return this.canActivate(route, state);
  }

  private redirectToDefaultPage(): UrlTree | boolean {
    if (this.authorizationSvc.userIs([UserIs.CM.CLIENT_MGR, UserIs.CM.CLIENT_VIEW_ONLY])) {
      return this.router.createUrlTree([`/${CM_BASE_PATH}/clients`]);
    }

    if (this.authorizationSvc.userIs([UserIs.CM.BILL_MGR])) {
      return this.router.createUrlTree([`/${CM_BASE_PATH}/client-reports`]);
    }

    if (this.authorizationSvc.userIs([UserIs.CM.TLE])) {
      return this.router.createUrlTree([`/${CM_BASE_PATH}/invoices`]);
    }

    if (this.authorizationSvc.userIs([UserIs.PARTNER])) {
      return this.router.createUrlTree([`/${CMS_BASE_PATH}/cases`]);
    }

    return false;
  }
}
