<div class="datepicker-wrapper">
  <input
    class="lib-cm-input"
    [matDatepicker]="picker"
    placeholder="Choose a date"
    (dateChange)="onDateChange($event)"
    (click)="picker.open()"
    [disabled]="disabled"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [min]="minDate"
    [max]="maxDate"
    [matDatepickerFilter]="dateFilterFn"
    [ngClass]="{ 'clearable': clearable, 'border-highlighted': !!stringVal && highlightIfSet }"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [startAt]="startAt"></mat-datepicker>

  <span class="reset-btn" id="atqa-reset-btn" *ngIf="!!stringVal && clearable" (click)="reset()">
    <i class="material-icons" [class.highlighted]="highlightIfSet">close</i>
  </span>
</div>
