import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { CMS_BASE_PATH } from 'src/app/shared/app.const';

@Component({
  selector: 'cms-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  cmsBasePath = CMS_BASE_PATH;

  constructor(private location: Location) {}

  goBack() {
    this.location.historyGo(-2);
  }
}
