<div class="datepicker-wrapper" [formGroup]="group">
  <mat-date-range-input
    [rangePicker]="picker"
    class="lib-cm-input"
    [ngClass]="{ 'border-highlighted': group.value.dateFrom || group.value.dateTo }"
  >
    <input matStartDate [placeholder]="'From'" formControlName="dateFrom" />
    <input matEndDate [placeholder]="'To'" formControlName="dateTo" />
  </mat-date-range-input>

  <mat-datepicker-toggle
    matSuffix
    [for]="picker"
    *ngIf="!(group.value.dateFrom || group.value.dateTo)"
  ></mat-datepicker-toggle>
  
  <div
    class="datepicker-cancel"
    *ngIf="group.value.dateFrom || group.value.dateTo"
    (click)="defaultValue()"
  >
    x
  </div>
  <mat-date-range-picker #picker></mat-date-range-picker>
</div>
