import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OnlineUsersService } from '../../services/online-users.service';
import { ComponentDestroyClass } from '../../../app/shared/classes/component-destroy.class';
import { WebSocketService } from '../../../app/services/web-socket.service';
import { UtmcUsersModalComponent } from './modal/utmc-users-modal/utmc-users-modal.component';

@Component({
  selector: 'cm-online-users',
  templateUrl: './online-users.component.html',
  styleUrls: ['./online-users.component.scss'],
  providers: [OnlineUsersService],
})
export class OnlineUsersComponent extends ComponentDestroyClass implements OnInit, OnDestroy {
  @Input() onlineUsers: any;

  expanded: boolean;

  constructor(
    private onlineUsersSvc: OnlineUsersService,
    private socketSvc: WebSocketService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngOnInit(): void {
    this.getOnlineUsers();
    this.initSocketSub();
  }

  openOnlineUserList() {
    this.dialog.open(UtmcUsersModalComponent, {
      id: 'utmcUsersModal',
      maxWidth: '460px',
      data: { internalUsers: this.onlineUsers.internalUsers, closeBtn: true },
    });
  }

  getOnlineUsers() {
    this.onlineUsersSvc.getOnlineUsers().subscribe({
      next: (response) => {
        this.onlineUsers = response;
      },
    });
  }

  initSocketSub() {
    this.socketSvc.sockJsSubject
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((socketMessageJson) => {
        if (!socketMessageJson) return;

        const socketMessage = JSON.parse(socketMessageJson);

        if (socketMessage.type !== 'USER_SESSION_UPDATE') {
          return;
        }

        this.getOnlineUsers();
      });
  }
}
