<div class="user-controls">
  <img class="user-avatar" src="{{ user?.avatar?.base64Data || user?.defaultAvatar}}" />
  <div class="user-data" style="" [class.expandable]="userControls" (click)="expanded = !expanded">
    <span>{{ user.firstName }} {{ user.lastName }}</span>
    <span id="matomo-variable-user" style="display: none"> {{ user.login }}</span>
    <i class="material-icons" [class.rotated-up]="expanded">arrow_drop_down</i>
  </div>
  <div class="controls-panel" *ngIf="userControls && expanded">
    <div *ngFor="let item of userControls" class="control-item" (click)="item.onClick(); expanded = !expanded;">
      {{ item.label }}
    </div>
  </div>
</div>
