<div class="cm-row">
  <ng-container *ngFor="let tab of actionTabs">
    <hr *ngIf="tab?.hasTopLine" style="width: 100%" />
    <a
      *ngIf="!tab.subTabs"
      class="tab-item"
      [class.disabled]="tab.disabled"
      [routerLink]="tab.disabled ? 'not-found' : tab.route"
      [queryParams]="tab.queryParams"
      [routerLinkActive]="tab.activeFn ? '' : 'active'"
      [ngClass]="{'active': tab.activeFn && tab.activeFn()}"
      (click)="tab.onClick ? tab.onClick() : ''"
    >
      <span [class]="tab.cssClass">{{ tab.label }}</span>
    </a>

    <a *ngIf="tab.subTabs" class="text-tab-item">
      <span>{{ tab.label }}</span>
    </a>
    <div class="sub-tabs-block">
      <ng-container *ngFor="let subTab of tab.subTabs">
        <div class="d-flex align-items-center">
          <a
            class="sub-tab-item"
            [class.disabled]="subTab.disabled"
            [routerLink]="subTab.route"
            [queryParams]="subTab.queryParams"
            [routerLinkActive]="subTab.activeFn ? '' : 'active'"
            [ngClass]="{'active': subTab.activeFn && subTab.activeFn()}"
            (click)="subTab.onClick ? subTab.onClick() : ''"
          >
            <span>{{ subTab.label }}</span>
          </a>
          <i *ngIf="subTab.icon && !subTab.disabled && (subTab.icon.visibility ?? true)"
             class="material-icons {{ subTab.icon.type }}"
             (click)="subTab.icon.onClick ? subTab.icon.onClick() : ''"
             style="margin: 0 3px"
          >{{ subTab.icon.type }}</i>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

