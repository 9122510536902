import { Component } from '@angular/core';
import { DetailsHeaderService } from '../services/details-header.service';
import { ScreenService } from '../services/screen.service';

@Component({
  selector: 'cm-details-header',
  templateUrl: './details-header.component.html',
  styleUrls: ['./details-header.component.scss'],
})
export class DetailsHeaderComponent {
  constructor(public headerService: DetailsHeaderService, public screenService: ScreenService) {}
}
