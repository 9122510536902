import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BodyComponent } from '../app-base/body/body.component';
import { AuthorizationComponent } from './components/authorization/authorization.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterResolver } from './components/register/register.resolver';
import { AuthorizationGuard } from './guards/authorization.guard';
import { CheckProjectVersionGuard } from './guards/check-project-version.guard';
import { NotAllowWithActiveSessionGuard } from './guards/not-allow-with-active-session.guard';
import { ProfileGuard } from './guards/profile.guard';
import { UserIs } from './models/user-is.model';
import { CMS_BASE_PATH, CM_BASE_PATH } from './shared/app.const';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: CMS_BASE_PATH,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAllowWithActiveSessionGuard],
  },
  {
    path: 'registration',
    component: RegisterComponent,
    resolve: {
      registered: RegisterResolver,
    },
  },
  {
    path: 'login-email',
    component: ForgotUsernameComponent,
    canActivate: [NotAllowWithActiveSessionGuard],
  },
  {
    path: 'password-email',
    component: ForgotPasswordComponent,
    canActivate: [NotAllowWithActiveSessionGuard],
  },
  {
    path: 'password',
    component: CreatePasswordComponent,
    canActivate: [NotAllowWithActiveSessionGuard],
  },
  {
    path: '',
    canActivate: [ProfileGuard],
    component: AuthorizationComponent,
    children: [
      {
        path: '',
        component: BodyComponent,
        children: [
          {
            path: CMS_BASE_PATH,
            loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
            canLoad: [CheckProjectVersionGuard],
          },
          {
            path: CM_BASE_PATH,
            canActivate: [AuthorizationGuard],
            loadChildren: () => import('./cm/cm.module').then((m) => m.CmModule),
            data: {
              accessRoles: [UserIs.CM],
            },
            canLoad: [CheckProjectVersionGuard],
          },
        ],
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'ignore',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
