import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '../../../../services/api.service';

@Injectable()
export class ContactUsService {
  constructor(private apiSvc: ApiService) {}

  sendMessageToAdmin(body) {
    const url = 'contact';
    const params: Params = {};
    params.body = body.body;
    params.subject = body.subject;

    const formData = new FormData();
    body.attachments.forEach((file) => {
      formData.append('files', file);
    });

    return this.apiSvc.post(url, formData, { params, observe: 'response' });
  }

  sendMessageToSupport(body) {
    const url = `support/tickets`;

    const params: Params = {};
    params.body = body.body;
    params.subject = body.subject;

    const formData = new FormData();
    body.attachments.forEach((file) => {
      formData.append('files', file);
    });

    return this.apiSvc.post(url, formData, { params, responseType: 'text', observe: 'response' });
  }

  initTokens() {
    return this.apiSvc.get('support/init', { responseType: 'text', observe: 'response' });
  }
}
