<div class="select-wrapper">
  <mat-select
    [(ngModel)]="value"
    panelClass="panel-class"
    [ngClass]="{
      placeholder: isPlaceholder,
      clearable: clearable,
      'border-highlighted': !!value && highlightIfSet,
      disabled: disabled
    }"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (selectionChange)="selectionChange.emit($event)"
    [panelClass]="panelClass || ''"
    [compareWith]="compareWith"
  >
    <mat-option *ngIf="!!placeholder && deselectable" [disabled]="placeholderDisabled" value="">{{
      placeholder
    }}</mat-option>
    <ng-template [ngIf]="viewValue">
      <mat-option
        *ngFor="let item of itemsList"
        [value]="getOptionValue(item)"
        [disabled]="item.disabled || null"
        [class.d-none]="item.hidden"
      >
        {{ item[viewValue] }}
      </mat-option>
    </ng-template>
    <ng-template [ngIf]="!viewValue">
      <mat-option
        *ngFor="let item of itemsList | keyvalue: formControlsService.pipeComparator"
        [value]="valueForRequest ? item[valueForRequest] : item.key"
      >
        {{ item.value }}
      </mat-option>
    </ng-template>
  </mat-select>

  <span class="reset-btn" id="atqa-reset-btn" *ngIf="showCloseIcon" (click)="reset()">
    <i class="material-icons" [class.highlighted]="highlightIfSet">close</i>
  </span>
</div>
