/* eslint-disable no-underscore-dangle */
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'cms-user-revoke-modal',
  templateUrl: './user-revoke-modal.component.html',
  styleUrls: ['./user-revoke-modal.component.scss'],
})
export class UserRevokeModalComponent implements OnInit, OnDestroy {
  @ViewChild('closeBtn', { static: true }) closeBtn: MatButton;

  secondsLeft: number;

  private logoutCountdownInterval: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>) {}

  ngOnInit(): void {
    this.startInterval();
    setTimeout(() => this.closeBtn._elementRef);
    console.log(this.closeBtn._elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.logoutCountdownInterval) {
      clearInterval(this.logoutCountdownInterval);
    }
  }

  get logoutDelayInSeconds(): number {
    return this.data.logoutDelayInSeconds;
  }

  closeModal() {
    this.dialogRef.close();
  }

  startInterval() {
    const logoutDelayInMilliseconds = this.logoutDelayInSeconds * 1000;
    let logoutTime = Date.now() + logoutDelayInMilliseconds;

    const intervalTime = 1000;

    // adjust logout time
    logoutTime += intervalTime;

    this.logoutCountdownInterval = setInterval(() => {
      const time = new Date().getTime();
      const timeLeft = logoutTime - time;
      this.secondsLeft = Math.floor((timeLeft % (1000 * 60)) / 1000);

      if (timeLeft <= 0) {
        clearInterval(this.logoutCountdownInterval);
      }
    }, intervalTime);
  }
}
