<div class="info-container">
  <div *ngFor="let field of fields" class="info-field">
    <ng-container *ngIf="!field.isCurrency">
      <span>{{ field.title }}</span
      >: <span class="font-weight-bold">{{ field.value() }}</span>
    </ng-container>
    <ng-container *ngIf="field.isCurrency">
      <span>{{ field.title }}</span
      >: <span class="font-weight-bold">{{ field.value() | currency }}</span>
    </ng-container>
  </div>
</div>
