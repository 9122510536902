<cms-modal-layout>
    <span title class="title">Online Now</span>

    <div content class="d-flex flex-column align-items-center">
        <span>  {{internalUsers.length > 0 ? projectInfo.name + ' Users:' : 'No ' + projectInfo.name + ' Users'}} </span>
        <ng-container *ngIf="internalUsers.length > 0 ">  <span *ngFor="let user of internalUsers">{{user.fullName}}</span></ng-container>


    </div>

    <span closeBtnTitle>Cancel</span>


</cms-modal-layout>
