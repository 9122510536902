import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'cms-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.scss'],
})
export class LabelsComponent implements OnInit {
  private mailingLabels = [];

  allLabels = false;

  @Input() control: FormControl;

  constructor() {
    for (let i = 0; i <= 13; i++) {
      this.mailingLabels[i] = false;
    }
  }

  ngOnInit() {}

  changeAllLabels(value: MatCheckboxChange) {
    for (let i = 0; i <= 13; i++) {
      this.mailingLabels[i] = value.checked;
    }
    this.control.setValue(this.mailingLabels);
  }

  selectLabel(index: number) {
    this.allLabels = false;
    this.mailingLabels[index] = !this.mailingLabels[index];
    if (this.mailingLabels.find((x) => x === false) === undefined) {
      this.allLabels = true;
    } else {
      this.allLabels = false;
    }
    this.control.setValue(this.mailingLabels);
  }
}
