<div class="input-wrapper">
  <div
    class="lib-cm-input"
    [ngClass]="{
      clearable: clearable,
      'border-highlighted': !!vm && highlightIfSet,
      'view-mode': isViewMode
    }"
    (click)="openAutoCompletePanel()"
  >
<!--      row #9 need because, if you click on div.mat-chip-list-wrapper first time,then autocomplete options  will be close at once -->
    <mat-chip-list #chipList >
      <mat-chip *ngFor="let chip of chips; let i = index" (removed)="remove(i)">
        {{ chip[viewValue] || chip }}
        <button matChipRemove *ngIf="!isViewMode">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        [placeholder]="isViewMode ? '' : placeholder"
        #input
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        #trigger="matAutocompleteTrigger"
      />
    </mat-chip-list>
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    (optionSelected)="optionSelected($event)"
    [displayWith]="displayProperty.bind(this)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      <div>{{ option[viewValue] || option }}</div>
    </mat-option>
  </mat-autocomplete>
</div>

