/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../app/services/api.service';

@Injectable()
export class OnlineUsersService {
  constructor(private apiService: ApiService) {}

  getOnlineUsers(): Observable<OnlineUsers> {
    const url = `online-user`;
    return this.apiService.get<OnlineUsers>(url);
  }
}

export interface OnlineUsers {
  employerUsers: number;
  internalUsers: [{ fullName: string }];
  totalUsers: number;
  utmcUsers: number;
}
