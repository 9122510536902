<cms-modal-layout>
  <span title>Terms of Use Agreement</span>
  <div content>
    <p class="p-header">Case Management System Terms of Use</p>
    <p class="effective-date">Effective Date: April 15, 2019</p>
    <ol class="list">
      <li>
        <p>Introduction</p>
        <p>
          These are the Terms of Use (TOU) for the Case Management System (CMS) offered by
          Unemployment Tax Management Corporation ({{ this.projectInfo.name }}) of Wakefield, Massachusetts to U.S.-based
          employers {{ this.projectInfo.name }} serves and their authorized, U.S.-based personnel. This document stipulates
          what each user must agree to in order to access and use CMS, including any content,
          functionality and services offered on or through CMS. Section 2 focuses on aspects that
          are particular to CMS. Section 3 includes typical TOU clauses adjusted to apply to the CMS
          case. “You” and “your” may refer to yourself personally or to the organization you
          represent. These TOU also include all terms and conditions expressly incorporated herein
          by reference.
        </p>
      </li>
      <li>
        <p>Case Management System Services and CMS-Specific Terms of Use</p>
        <p class="header-level-2">2.1. CMS services</p>
        <p class="header-level-3">2.1.1. Eligible Users</p>
        <p>
          In order to use CMS, you must be a U.S.-based employee or contractor working for a
          U.S.-based employer {{ this.projectInfo.name }} serves.
        </p>
        <p class="header-level-3">2.1.2. User Types</p>
        <p>
          Users (you) are assigned one of three access levels. Your access may be limited to
          accessing and getting communications about your tax rate and tax rate notices. The other
          two levels, General Contact (GC) and Authorization Manager (AM), are described below. A
          user’s access level is defined outside CMS through communication between an appropriate
          high-level manager at your organization and {{ this.projectInfo.name }}.
        </p>
        <p class="header-level-3">2.1.3. Multiple Organizations</p>
        <p>
          A user may work for different organizations. That user’s access level to CMS information
          for each organization may differ. This applies as well when the organizations are related.
        </p>
        <p class="header-level-3">2.1.4. Authorization Manager</p>
        <p>
          The Authorization Manager is a high-level manager at your organization who will extend or
          retract authorization to use CMS from a list individuals within the organization. Each
          family of related organizations must have one and only one Authorization Manager. Within
          CMS, the AM will see the list of all potential CMS users within that family. The AM
          optionally approves each user in a yes or no manner. The details of access extent is
          defined outside of CMS before this step is taken.
        </p>
        <p>
          The Authorization Manager’s access includes all the functions available to the GC as well.
          So in the discussion below, any reference to GC access is meant to include the
          Authorization Manager.
        </p>
        <p class="header-level-3">2.1.5. Functionality</p>
        <p>The typical user, or General Contact, may use CMS to:</p>
        <ul>
          <li>Provide information about a separation from employment.</li>
          <li>Monitor when separation information requirements are coming due.</li>
          <li>Communicate securely with {{ this.projectInfo.name }} staff.</li>
          <li>Provide needed documentation.</li>
          <li>
            Review your organization’s separation information, case status and hearing results.
          </li>
          <li>Access your organization’s benefit charge statements.</li>
          <li>Access your organization’s tax rate notices.</li>
          <li>Receive backup notifications by email in case you are not logged in.</li>
          <li>Review summary activity reports.</li>
        </ul>
        <p class="header-level-3">2.1.6. By Invitation Only</p>
        <p>
          Access to CMS is by invitation only. {{ this.projectInfo.name }} must have your name, email address, organization
          and access level assignment. The organization you represent must be a paid client of {{ this.projectInfo.name }}.
          Use of CMS is included as part of the service {{ this.projectInfo.name }} provides.
        </p>
        <p>
          When the Authorization Manager authorizes your use, you will receive an invitation by
          email.
        </p>
        <p class="header-level-3">2.1.7. How to Access</p>
        <p>
          To access CMS’ features and services, sign in directly through {{ this.projectInfo.name }}’s website at
          {{ this.projectInfo.url }} from a personal computer with a screen resolution of at least 1366 x 768.
          Click the Client Sign In link in the upper right.
        </p>
        <p class="header-level-3">2.1.8. Dashboard</p>
        <p>
          CMS provides a dashboard showing what needs your attention and listed as Action Items. The
          Action Item list presented will be the same for everyone with the same access rights
          within the same organization.
        </p>
        <p class="header-level-2">2.2. User responsibilities</p>
        <p class="header-level-3">2.2.1. Credentials and Tracking</p>
        <p>
          Each user will have unique log-in credentials. The CMS user agrees to be responsible for
          all CMS activities performed using those credentials. The user understands that CMS will
          often record which user has provided certain information or communications. Please keep
          your password private.
        </p>
        <p class="header-level-3">2.2.2. Unique Email Address Required</p>
        <p>
          Each individual using CMS must have a unique email address that is used exclusively within
          one family of related organizations. The email address and log-in credentials are tied
          together. Doing so allows CMS to restore access if you have forgotten your password. It
          also allows CMS to know whom {{ this.projectInfo.name }} is communicating with when sending emails. The user
          agrees to maintain the designated email address in that user’s regular use and for
          intended future regular use. The user must communicate with {{ this.projectInfo.name }} promptly if and when that
          address needs to be changed. Users agree to receipt of emails from CMS, including
          automated emails.
        </p>
        <p class="header-level-3">2.2.3. Sensitive Information</p>
        <p>
          CMS contains sensitive information. All users agree to this understanding. This expressly
          includes, but is not limited to, handling of claimant names and social security numbers,
          and any other sensitive or personally identifiable information. The users agree to follow
          best practices for privacy and adherence to federal and state laws addressing privacy in
          general and social security numbers in particular. All information {{ this.projectInfo.name }} collects on CMS is
          subject to {{ this.projectInfo.name }}’s Privacy Policy.
        </p>
        <p class="header-level-3">2.2.4. Document Handling</p>
        <p>
          CMS provides for uploading of documents from you and classification of these documents as
          to be shared only with {{ this.projectInfo.name }} or also with the state (“visibility level”). While {{ this.projectInfo.name }} may
          adjust the classification you provide at its discretion, it is your responsibility to
          assign the proper visibility level at upload.
        </p>
        <p class="header-level-3">2.2.5. HIPPA and Confidentiality</p>
        <p>
          Certain information employers have should not be shared with {{ this.projectInfo.name }} or CMS. Employers are
          responsible to redact, hide or prevent transmission of such information to {{ this.projectInfo.name }}/CMS. This
          is primarily intended to comply with the Health Insurance Portability and Accountability
          Act of 1996 (HIPPA) and similar or related laws, but also covers any confidentially that
          is legally required or otherwise appropriate. {{ this.projectInfo.name }} is not responsible for the transmission
          of confidential data that you have sent, neglected to redact or redacted improperly. {{ this.projectInfo.name }}
          points out that redaction over sections of a PDF document may not block that information
          from a deeper digital inquiry.
        </p>
        <p class="header-level-3">2.2.6. Meeting Deadlines</p>
        <p>
          While {{ this.projectInfo.name }} provides tools to assist in meeting deadlines for responses to state
          requirements, you are responsible to meet those deadlines even if those tools are
          approximate or fail to provide you with the guidance you find adequate. {{ this.projectInfo.name }} may reach out
          to you to meet a deadline, but it is not responsible for failure to do so.
        </p>
        <p class="header-level-3">2.2.7. Managing Shared Access</p>
        <p>
          CMS users understand that the dashboard of Action Items this is shown to
          similarly-authorized users in your organization will look the same for those users. It is
          the responsibility of your organization and its users to manage who takes charge of
          individual Action Items. Typically an organization might divide responsibility by
          location, which would frequently be distinguished by the name of the unemployment benefit
          claimant in the Action Item. The users are responsible to pass work to one another
          appropriately and avoid making edits to work that is not correctly theirs. On the other
          hand, the pool of GC users must pick up for one another if there is an absence or
          departure of a responsible GC.
        </p>
        <p class="header-level-3">2.2.8. Gatekeeping</p>
        <p>
          The Authorization Manager agrees to promptly authorize and de-authorize GC and tax rate
          users using the facility within CMS.
        </p>
        <p class="header-level-3">2.2.9. Secure Communication</p>
        <p>
          Unlike CMS, emails are not secure vehicles. CMS will not send full social security numbers
          in an email. {{ this.projectInfo.name }} discourages users from sending full social security numbers by email and
          requests such communications, and all communications related to unemployment management,
          be performed through CMS. When possible, such as in reporting, CMS will use the last four
          digits of an SSN instead.
        </p>
        <p class="header-level-3">2.2.10. Accessing CMS and Account Security</p>
        <p>
          {{ this.projectInfo.name }} reserves the right to withdraw or amend CMS, and any service or material {{ this.projectInfo.name }}
          provides on CMS, in {{ this.projectInfo.name }}’s sole discretion without notice. {{ this.projectInfo.name }} will not be liable if for
          any reason all or any part of CMS is unavailable at any time or for any period. From time
          to time, {{ this.projectInfo.name }} may restrict access to some parts of CMS, or the entire CMS, to users.
        </p>
        <p>You are responsible for:</p>
        <ol>
          <li>Making all arrangements necessary for you to have access to CMS.</li>
          <li>
            Ensuring that all persons who access CMS through your internet connection are aware of
            these TOU and comply with them.
          </li>
        </ol>
        <p>
          To access CMS, you may be asked to provide certain registration details or other
          information. It is a condition of your use of CMS that all information you provide to {{ this.projectInfo.name }}
          and/or on CMS is correct, current and complete. You agree that all information you provide
          to register with CMS or otherwise, including but not limited to through the use of any
          interactive features on CMS, is governed by {{ this.projectInfo.name }}’s Privacy Policy, and you consent to all
          actions {{ this.projectInfo.name }} takes with respect to your information consistent with its Privacy Policy.
        </p>
      </li>
      <li>
        <p>Typical Terms of Use as Applied to CMS</p>
        <p class="header-level-2">3.1. Definitions</p>
        <p>
          The terms “Case Management System” and “CMS” apply to the software, design and data used
          or provided for by CMS as offered by {{ this.projectInfo.name }}. You can reach {{ this.projectInfo.name }}
          at {{ this.projectInfo.address_line_one }},{{ this.projectInfo.address_line_two }},
          {{ this.projectInfo.phone }} or {{ this.projectInfo.email }}.
          CMS is an essential web-based tool developed by {{ this.projectInfo.name }} to provide its U.S.-based client
          organizations with the best possible management of their unemployment taxes and related issues.
        </p>
        <p class="header-level-2">3.2. Accepting the Agreement</p>
        <p>
          When you access or use CMS or any CMS content, functionality or services, or authorize
          others to access or use them, you and the organization you represent agree to accept and
          agree to be bound by the terms and conditions of these TOU and the Privacy Policy in its
          entirety. {{ this.projectInfo.name }} may modify the TOU and any policies incorporated herein by reference at any
          time in its sole discretion without notice. You are responsible for periodically checking
          for changes and are bound by them when you continue to use CMS following such changes. The
          TOU in effect are the ones posted at the time you access CMS. A link to the TOU can be
          found in the About section. Users must be of sufficient age (at least 18 years of age) and
          capacity to legally enter into an agreement and must reside in the United States or any of
          its territories or possessions. By accessing or using CMS, you represent and warrant that
          you are of legal age to form a binding contract with {{ this.projectInfo.name }} and meet all of the foregoing
          eligibility requirements. If you do not meet all of these requirements, you must not
          access or use CMS or any CMS content, functionality, or services.
        </p>
        <p class="header-level-2">3.3. Intellectual Property Rights</p>
        <p>
          Other than content you own, which you may have opted to include in CMS, under these TOU,
          {{ this.projectInfo.name }} and/or its licensors own all rights to CMS and the intellectual property, features
          and functionality, and material contained in CMS, and all such rights are reserved and are
          protected by United States and international copyright, trademark, patent, trade secret,
          and other intellectual property or proprietary rights laws. This intellectual property
          definition includes all information and any design, text, graphics, video, audio, software
          code, logo, and trademark or service mark in CMS. If you qualify to access and use CMS and
          agree to the TOU, {{ this.projectInfo.name }} grants you a limited, revocable, non-exclusive, non-assignable, and
          non-sublicensable license to use CMS in compliance with the TOU and subject to the
          restrictions below.
        </p>
        <p>
          Furthermore, the {{ this.projectInfo.name }} name, the terms and all related names, logos, product and service
          names, designs and slogans are trademarks of {{ this.projectInfo.name }} or its affiliates or licensors. You must
          not use such marks without the prior written permission of {{ this.projectInfo.name }}. All other names, logos,
          product and service names, designs and slogans on CMS are the trademarks of their
          respective owners.
        </p>
        <p class="header-level-2">3.4. Restrictions</p>
        <p>You are expressly and emphatically restricted from all of the following:</p>
        <ol>
          <li>Publishing any CMS material in any media</li>
          <li>Selling, sublicensing and/or otherwise commercializing any CMS material</li>
          <li>Publicly performing and/or showing any CMS material</li>
          <li>Using CMS in any way that is, or may be, damaging to CMS</li>
          <li>
            Using CMS in any manner that could disable, overburden, damage or impair CMS or that in
            any way impacts user access to CMS
          </li>
          <li>
            Using any robot, spider, or other automatic device, process or means to access CMS for
            any purpose, including monitoring or copying any of the material on CMS
          </li>
          <li>
            Introducing any viruses, Trojan horses, worms, logic bombs or other material which is
            malicious or technologically harmful
          </li>
          <li>
            Using CMS contrary to applicable laws and regulations, or in a way that causes, or may
            cause, harm to CMS, or to any person or business entity
          </li>
          <li>
            Engaging in any data mining, data harvesting, data extracting or any other similar
            activity in relation to CMS, or while using CMS
          </li>
          <li>Using CMS to engage in any advertising or marketing</li>
          <li>Linking to a {{ this.projectInfo.name }} URL other than {{ this.projectInfo.url }}</li>
          <li>
            Suggesting that {{ this.projectInfo.name }} endorses, sponsors or is affiliated with any non-{{ this.projectInfo.name }} website,
            entity, service or product
          </li>
          <li>
            Using CMS for other than its intended purpose, which is to assist in management of your
            unemployment costs and related issues
          </li>
          <li>Using any information from CMS that is not intended for your consumption</li>
          <li>
            Attempting to gain unauthorized access to, interfere with, damage, or disrupt any parts
            of CMS, the servers on which CMS is stored, or any server, computer or database
            connected to CMS
          </li>
        </ol>
        <p>
          Certain areas of CMS are restricted from access by you and {{ this.projectInfo.name }} may further restrict
          access by you to any areas of CMS, at any time, in its sole and absolute discretion.  You
          agree to notify {{ this.projectInfo.name }} if CMS is not properly restricting your access to appropriate
          information.
        </p>
        <p class="header-level-2">3.5. Your Credentials</p>
        <p>
          Any user ID and password you may have for CMS are confidential and you must maintain the
          confidentiality of those credentials. You also acknowledge that your account is personal
          to you and agree not to provide any other person with access to CMS or portions of it
          using your user ID, password or other security information. Your password is stored
          encrypted and {{ this.projectInfo.name }} does not have access to it. If you have forgotten your password, CMS
          provides a procedure to establish a new one through the use of the email address on file
          for you. Passwords cannot be re-used within one year. Outside of the registration and
          login procedures, {{ this.projectInfo.name }} will never ask for your password. If you are contacted by anyone
          requesting this information, please contact {{ this.projectInfo.name }} immediately.
        </p>
        <p>
          You agree to notify {{ this.projectInfo.name }} immediately of any unauthorized access to or use of your user ID
          or password or any other breach of security. You also agree to ensure that you exit from
          your account at the end of each session. You should use particular caution when accessing
          your account from a public or shared computer so that others are not able to view or
          record your user ID, password or other personal information.
        </p>
        <p>
          {{ this.projectInfo.name }} has the right to disable any user ID, password or other identifier, whether chosen by
          you or provided by {{ this.projectInfo.name }}, at any time in {{ this.projectInfo.name }}’s sole discretion for any or no reason,
          including if, in {{ this.projectInfo.name }}’s opinion, you have violated any provision of these TOU.
        </p>
        <p class="header-level-2">3.6. Your Content</p>
        <p>
          In these TOU, “Your Content” shall mean any audio, video, text, images or other material
          you choose to upload into CMS. With respect to Your Content, by uploading it, you grant
          {{ this.projectInfo.name }} a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use,
          reproduce, adapt, publish, translate and distribute it in any and all media.
        </p>
        <p>
          Your Content must be your own and must not be infringing on any third party’s rights. {{ this.projectInfo.name }}
          reserves the right to remove any of Your Content from CMS at any time and for any reason
          without notice.
        </p>
        <p class="header-level-2">3.7. Information About You</p>
        <p>
          You recognize that in addition to information you provide about yourself, your
          organization and others at your organization, CMS may collect information about these
          entities from others, in particular from claimants.
        </p>
        <p class="header-level-2">3.8. Termination</p>
        <p>
          You agree that {{ this.projectInfo.name }} may revoke your license and access to CMS at its sole discretion at
          any time and without notice. You understand that you must maintain your status as a paid
          client in order to maintain access.
        </p>
        <p>
          You agree to maintain your own records. In the event of a pending termination, you will
          capture all content that you need, and that you created or provided, before such
          termination. {{ this.projectInfo.name }} is not responsible to provide or convey such content afterwards.
        </p>
        <p class="header-level-2">3.9. Third Party Content</p>
        <p>
          {{ this.projectInfo.name }} is not responsible for the accuracy or veracity of third-party content. Examples of
          third party content include, but are not limited to, forms from state jurisdictions and
          statements by claimants or state representatives. {{ this.projectInfo.name }} does not warrant the accuracy,
          completeness or usefulness of this information. Any reliance you place on such information
          is strictly at your own risk. {{ this.projectInfo.name }} disclaims all liability and responsibility arising from
          any reliance placed on such content by you or any other user of CMS, or by anyone who may
          be informed of any of its contents. All third party statements and/or opinions expressed
          in CMS content, other than the content provided by {{ this.projectInfo.name }}, are solely the opinions and the
          responsibility of the person or entity providing the content. Such third party content
          does not necessarily reflect the opinion of {{ this.projectInfo.name }}. {{ this.projectInfo.name }} is not responsible, or liable to
          you or any third party, for the content or accuracy of any materials provided by any third
          parties.
        </p>
        <p class="header-level-2">3.10. End-of-Session Security</p>
        <p>
          You understand that in order to maintain the appropriate level of security CMS may sign
          you out after a period of inactivity resulting from an absence of keystrokes or mouse
          clicks. Similarly for your protection, you should sign off after every CMS session and
          close your browser.
        </p>
        <p class="header-level-2">3.11. Timeliness of Display</p>
        <p>
          The security timeout, along with the refresh action each time you change what is
          displayed, will help maintain the currency of the information CMS displays. However, there
          is a chance that updates elsewhere will make that information out of date. {{ this.projectInfo.name }} suggests
          refreshing your screen if there is any question.
        </p>
        <p class="header-level-2">3.12. Saving Your Work</p>
        <p>
          {{ this.projectInfo.name }} recommends saving your work frequently. Any number of events can cause loss of work
          in progress. {{ this.projectInfo.name }} will not be able to restore work that has not been saved.
        </p>
        <p class="header-level-2">3.13. No Warranties</p>
        <p>
          CMS is provided “as is,” with all faults, and {{ this.projectInfo.name }} makes no express or implied
          representations or warranties, of any kind related to CMS or the materials contained
          therein. {{ this.projectInfo.name }} hereby disclaims all warranties of any kind, whether express or implied,
          statutory or otherwise, including but not limited to any warranties of merchantability,
          non-infringement and fitness for particular purpose. Additionally, nothing contained in
          CMS shall be construed as providing consult or advice to you. {{ this.projectInfo.name }} does not warrant that
          the service will be uninterrupted, timely, secure or error-free, or that defects will be
          corrected. This limitation applies even if {{ this.projectInfo.name }} is aware that such defects exist. {{ this.projectInfo.name }} may
          update the content on CMS from time to time, but its content is not necessarily complete
          or up-to-date. Any of the material on CMS may be out of date at any given time, and {{ this.projectInfo.name }}
          is under no obligation to update such material.
        </p>
        <p>
          You understand that {{ this.projectInfo.name }} cannot and does not guarantee or warrant that content available
          from CMS will be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your particular requirements
          for anti-virus and anti-malware protection and accuracy of data input and output, and for
          maintaining a means external to CMS for any reconstruction of any lost data. {{ this.projectInfo.name }} will not
          be liable for any loss or damage caused by a distributed denial-of-service attack, viruses
          or other technologically harmful material that may infect your computer equipment,
          computer programs, data or other proprietary material due to your use of CMS or any
          services or items obtained through CMS.
        </p>
        <p class="header-level-2">3.14. Limitation of Liability</p>
        <p>
          In no event shall {{ this.projectInfo.name }}, nor any of its affiliates or their licensors, service providers,
          officers, directors, employees, agents or contractors be liable to you for anything
          arising out of or in any way connected with your use of CMS, whether such liability is
          under contract, tort or otherwise, and {{ this.projectInfo.name }}, including its affiliates or their licensors,
          service providers, officers, directors, employees, agents and contractors shall not be
          liable for any indirect, consequential or special liability arising out of or in any way
          related to your use of CMS. This limitation applies even if {{ this.projectInfo.name }} is aware that such losses
          are possible.
        </p>
        <p>
          While {{ this.projectInfo.name }} will make every attempt to provide accurate communications on a timely basis,
          it is not responsible for inaccuracies, errors, omissions or delays. For example, {{ this.projectInfo.name }} is
          not responsible for communication limitations or distortions of any common carrier or by
          your own communication services. And though {{ this.projectInfo.name }} may correct them at its discretion, it is
          not responsible to correct typos or any other errors you may send it. {{ this.projectInfo.name }} is expressly
          not liable for anything over which it does not have direct control.
        </p>
        <p>
          In those jurisdictions where complete exclusion of liability is disallowed, the total
          liability to you from all liability types shall be limited to one hundred dollars
          ($100.00).
        </p>
        <p class="header-level-2">3.15. Indemnification</p>
        <p>
          You hereby indemnify to the fullest extent {{ this.projectInfo.name }}, its affiliates, licensors and service
          providers, and its and their respective officers, directors, employees, agents, licensors,
          suppliers, contractors, successors and assigns from and against any and all liabilities,
          costs, demands, causes of action, damages and expenses (including reasonable attorney’s
          fees) arising out of or in any way related to your use, misuse, errors or inability to use
          CMS, your use of any information obtained from CMS, or your breach of any of the
          provisions of these TOU.
        </p>
        <p class="header-level-2">3.16. Severability</p>
        <p>
          If any provision of these TOU is found to be unenforceable or invalid under any applicable
          law, such unenforceability or invalidity shall not render these TOU unenforceable or
          invalid as a whole, and such provision shall be deleted without affecting the remaining
          provisions herein.
        </p>
        <p class="header-level-2">3.17. Service Variation</p>
        <p>
          The services provided by CMS may be modified without notice. There is no guarantee that a
          function present at one time will be available at a later time. While {{ this.projectInfo.name }} expects most
          changes to represent improvements, it may be constrained by state or other requirements.
        </p>
        <p class="header-level-2">3.18. Assignment</p>
        <p>
          {{ this.projectInfo.name }} shall be permitted to assign, transfer, and subcontract its rights and/or obligations
          under these TOU without any notification or consent required. However, you shall not be
          permitted to assign, transfer, or subcontract any of your rights and/or obligations under
          these TOU.
        </p>
        <p class="header-level-2">3.19. Entire Agreement</p>
        <p>
          These TOU, including any legal notices and disclaimers contained in CMS, constitute the
          entire agreement between {{ this.projectInfo.name }} and you in relation to your use of CMS, and supersede all
          prior agreements and understandings with respect to the same. {{ this.projectInfo.name }}’s actions or silence
          toward you or anyone else does not waive, modify, or limit the TOU or its ability to
          enforce it.
        </p>
        <p class="header-level-2">3.20. Survivability</p>
        <p>
          All sections of the TOU not pertaining to current CMS use survive termination of CMS use,
          and you will remain bound by those sections. This includes, but is not limited to,
          attempted use and confidentiality.
        </p>
        <p class="header-level-2">3.21. Governing Law & Jurisdiction</p>
        <p>
          These TOU will be governed by and construed in accordance with the laws of the State of
          Massachusetts, and you submit to the non-exclusive jurisdiction of the state and federal
          courts located in Massachusetts for the resolution of any disputes
        </p>
        <p class="header-level-2">3.22. Technical Access Requirements</p>
        <p>
          CMS can be accessed by the following web browsers: Google Chrome, Mozilla Firefox,
          Internet Explorer, Safari and Microsoft Edge.
        </p>
        <p>
          While {{ this.projectInfo.name }} does not charge separately for access to CMS, please be advised that mobile
          data, email or internet access charges may be incurred through whoever is providing those
          services to you.
        </p>
        <p class="header-level-2">3.23. Monitoring and Enforcement</p>
        <p>
          {{ this.projectInfo.name }} may take appropriate legal action, including without limitation, referral to law
          enforcement, for any illegal or unauthorized use of CMS. Without limiting the foregoing,
          {{ this.projectInfo.name }} has the right to fully cooperate with any law enforcement authorities or court order
          requesting or directing {{ this.projectInfo.name }} to disclose the identity or other information of any user who
          accesses or uses CMS. YOU WAIVE AND HOLD HARMLESS {{ this.projectInfo.name }} AND ITS AFFILIATES, LICENSEES AND
          SERVICE PROVIDERS FROM ANY CASES RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
          PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A
          CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p>© 2019 Unemployment Tax Management Corporation.  All rights reserved.</p>
      </li>
    </ol>
  </div>
  <span closeBtnTitle>Close</span>
</cms-modal-layout>
