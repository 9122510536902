<mat-radio-group
  [(ngModel)]="value"
  aria-label="Select an option"
  (change)="radioGroupChange($event)"
  [disabled]="disabled"
>
  <ng-container *ngIf="options">
    <mat-radio-button
      *ngFor="let option of options | keyvalue: formControlsService.pipeComparator"
      color="primary"
      [value]="option.key"
      >{{ option.value }}</mat-radio-button
    >
  </ng-container>

  <ng-container *ngIf="advancedOptions">
    <mat-radio-button
      *ngFor="let option of advancedOptions"
      color="primary"
      [disabled]="option.disabled || false"
      [value]="option.value"
      >{{ option.label }}</mat-radio-button
    >
  </ng-container>
</mat-radio-group>
