import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DefaultUrlSerializer, Router } from '@angular/router';
import { RootModule } from '../models/root-module.model';
import { CM_BASE_PATH, CMS_BASE_PATH } from '../shared/app.const';
import { SessionService } from './session.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  rootModule: RootModule;

  private readonly SS_LAST_PAGE_KEY: string;

  constructor(
    private router: Router,
    private storageSvc: StorageService,
    private sessionSvc: SessionService,
    private dialog: MatDialog,
  ) {
    this.SS_LAST_PAGE_KEY = 'last.page';
  }

  goToCM() {
    return this.router.navigate([`/${CM_BASE_PATH}`]);
  }

  goToCMS() {
    return this.router.navigate([`/${CMS_BASE_PATH}`]);
  }

  goToLogin() {
    this.sessionSvc.destroySession();
    this.setLastPage();
    this.dialog.closeAll();
    return this.router.navigate([`/login`]);
  }

  goToLastPage() {
    const lastPageUrl: string = this.storageSvc.get(this.SS_LAST_PAGE_KEY, 'SS');

    if (lastPageUrl) {
      const lastPagePath = lastPageUrl.split('?')[0];
      const defaultUrlSerializer = new DefaultUrlSerializer();
      const lastPageQParams = defaultUrlSerializer.parse(lastPageUrl).queryParams;

      return this.router
        .navigate([lastPagePath], { queryParams: lastPageQParams })
        .then((value: boolean) => {
          // If the user does not have the valid roles
          if (!value) {
            return this.goToCMS();
          }

          return value;
        });
    }

    return this.goToCMS();
  }

  goToPageNotFound() {
    return this.router.navigate(['/page-not-found']);
  }

  openPageIsDashboard() {
    const { url } = this.router;
    const urlSegs = url.split('/');

    if (urlSegs.length !== 2) {
      return false;
    }

    const activeUrl = urlSegs[1].split('?')[0];

    return activeUrl === CMS_BASE_PATH;
  }

  private setLastPage() {
    const lastPageUrl = window.location.href.split(`${window.location.origin}/#`)[1];

    if (lastPageUrl) {
      this.storageSvc.set(this.SS_LAST_PAGE_KEY, lastPageUrl, 'SS');
    }
  }
}
