/* eslint-disable class-methods-use-this */
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterType, SidebarFilter } from '../../../app/models/sidebar-filter.model';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'cm-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent {
  @Input() formControls: { K: FormControl };

  @Input() filters: SidebarFilter[];

  constructor(public sidebarService: SidebarService) {}

  dateRangeChange(value: { dateFrom; dateTo }, control: FormControl): void {
    control.setValue(value);
  }

  isDeselectable(filter: SidebarFilter) {
    return 'deselectable' in filter ? filter.deselectable : true;
  }

  isClearable(filter: SidebarFilter) {
    return 'clearable' in filter ? filter.clearable : true;
  }

  isTitlecase(filter: SidebarFilter) {
    return 'titlecase' in filter ? filter.titlecase : true;
  }

  isHiddenField(filter: SidebarFilter) {
    return filter.type === FilterType.HIDDEN;
  }
}
