import { Component } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { SidebarService } from '../services/sidebar.service';

@Component({
  selector: 'cm-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  constructor(public menuService: MenuService, public sidebarService: SidebarService) {}
}
