import { Component, HostListener, OnInit } from '@angular/core';
import { MatomoService } from 'form-controls';
import { MenuService } from '../app-base/services/menu.service';
import { menuItems } from './app.menu';
import { HeaderService } from '../app-base/services/header.service';
import { LogoutService } from './services/logout.service';

@Component({
  selector: 'cm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoading = true;

  constructor(
    private menuService: MenuService,
    private headerService: HeaderService,
    private logoutSvc: LogoutService,
    private matomoSvc: MatomoService,
  ) {
    this.menuService.items = menuItems;
  }

  ngOnInit() {
    const linkEl = document.head.querySelector('link[href*="styles"]');
    this.isLoading = !(linkEl as HTMLLinkElement).sheet;
    (linkEl as HTMLLinkElement).addEventListener('load', () => {
      this.isLoading = false;
    });

    this.headerService.userControls = [
      {
        label: 'Log Out',
        onClick: () => this.logoutSvc.logout('Manual Log Out'),
      },
    ];
  }

  createNotification($event) {
    if ($event.type === 'error') {
      this.matomoSvc.sendNotificationEvent('Error', $event.content || '-');
    }
  }
}
