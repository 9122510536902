import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '../../../services/storage.service';
import { CMS_BASE_PATH } from '../../../shared/app.const';

@Component({
  selector: 'cms-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss'],
})
export class FirstLoginComponent implements OnInit {
  constructor(
    private storageSvc: StorageService,
    private router: Router,
    private dialogRef: MatDialogRef<any>,
  ) {}

  ngOnInit(): void {}

  goToHelpCenter() {
    this.router.navigate([CMS_BASE_PATH, 'articles']);
    this.dialogRef.close();
  }

  changeDontShowAgain(event: MatCheckboxChange) {
    this.storageSvc.set('tutorial', event.checked);
  }
}
