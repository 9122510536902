<h2 mat-dialog-title style="display: flex; justify-content: space-between">
    <span [class]="data?.titleClass">{{ data.title || 'Title Sample' }}</span>
    <button class="dialog-close-icon" mat-button mat-dialog-close (click)="trackAction({title: 'Close'})">
        <i class="material-icons">close</i>
    </button>
</h2>
<hr *ngIf="!data?.isNotification"/>

<mat-dialog-content>
    <div [innerHTML]="data.content || 'Content Sample'"></div>
    <!--    {{data.content || 'Content Sample'}}-->
</mat-dialog-content>
<!--<hr>-->
<mat-dialog-actions align="end">
    <button
        *ngFor="let button of data.actions"
        mat-button
        [mat-dialog-close]="button.action"
        (click)="button.action(); trackAction(button)"
        [cdkTrapFocusAutoCapture]="button?.isFocus"
        [cdkTrapFocus]="button?.isFocus"
    >
        {{ button.title }}
    </button>
    <button *ngIf="!data.actions" mat-button mat-dialog-close (click)="trackAction({title: 'Close'})">Close</button>
</mat-dialog-actions>
