/* eslint-disable import/no-cycle */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalLayoutData } from 'src/app/models/modal/modal-layout-data.model';

@Component({
  selector: 'cms-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss'],
})
export class ModalLayoutComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalLayoutData) {}
}
