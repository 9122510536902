import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-terms-use-agreement',
  templateUrl: './terms-use-agreement.component.html',
  styleUrls: ['./terms-use-agreement.component.scss'],
})
export class TermsUseAgreementComponent {
  projectInfo = environment.project;
}
