/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RegisterService } from 'src/app/services/general/register.service';
import { IsUserRegistered } from '../../models/register/is-user-registered.model';

/* eslint-disable @typescript-eslint/no-unused-vars */
@Injectable()
export class RegisterResolver implements Resolve<IsUserRegistered> {
  constructor(private registerSvc: RegisterService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IsUserRegistered> {
    // eslint-disable-next-line prefer-destructuring
    const token = route.queryParams.token;

    if (!token) {
      return of({ isUserRegistered: false, status: null });
    }

    return this.registerSvc.isUserRegistered(token);
  }
}
