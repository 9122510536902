import { DueDateQueues, Statuses } from 'src/app/shared/app.const';
import { ProjectIs } from 'src/app/models/projects.model';

export enum AiCreatedBy {
  SIDES = 'SIDES',
  ANALYST = 'Analyst',
  BC = 'BC',
  UI_ONLINE = 'UI_ONLINE',
  MPC = 'MPC',
}

export enum AiCategories {
  INITIAL = 'CL',
  SUPPLEMENTAL = 'SR',
}

export const aiCategoriesArray = Object.freeze([
  { title: 'Initial Request', value: AiCategories.INITIAL },
  { title: 'Supplemental Request', value: AiCategories.SUPPLEMENTAL },
]);

export const internalStatusTitles = [
  { title: 'Client', value: Statuses.CLIENT },
  { title: 'Review', value: Statuses.REVIEW },
  { title: 'Queued', value: Statuses.QUEUED },
  { title: 'State', value: Statuses.STATE },
  { title: 'Closed', value: Statuses.CLOSED },
  { title: 'Analyst(Past Due)', value: Statuses.REVIEW_PAST_DUE },
];

export const statusTitles = [
  { title: 'Response Required', value: Statuses.CLIENT },
  { title: 'Review', value: Statuses.REVIEW_PAST_DUE },
  { title: 'Completed', value: Statuses.QUEUED },
];

export const dueDateQueueTitles = [
  { title: 'Past Due', value: DueDateQueues.PAST },
  { title: 'Due Today', value: DueDateQueues.TODAY },
  { title: 'Due Tomorrow', value: DueDateQueues.TOMORROW },
  { title: 'Next 5 Days', value: DueDateQueues.WEEK },
  { title: 'Total Due', value: DueDateQueues.TOTAL },
];

export const createdByVisibleValues = {
  [ProjectIs.UTMC]: {
    SIDES: 'SIDES',
    ANALYST: 'Analyst',
    BC: 'Benefit Charge',
    UI_ONLINE: 'UI Online',
    MPC: 'Monetary & Potential Charge',
  },
  [ProjectIs.STOP_CLAIMS]: {
    SIDES: 'SIDES',
    ANALYST: 'Analyst',
    BC: 'Benefit Charge',
  },
  [ProjectIs.ACME]: {
    SIDES: 'SIDES',
    ANALYST: 'Analyst',
    BC: 'Benefit Charge',
  },
};

export enum AiSocketMessages {
  ACTION_ITEM_CREATE = 'ACTION_ITEM_CREATE',
  ACTION_ITEM_DELETE = 'ACTION_ITEM_DELETE',
  ACTION_ITEM_RECEIVE = 'ACTION_ITEM_RECEIVE',
  ACTION_ITEM_UPDATE = 'ACTION_ITEM_UPDATE',
  CASE_UPDATE = 'CASE_UPDATE',
  ACTION_ITEM_SAVE_NOTE = 'ACTION_ITEM_SAVE_NOTE',
}

export enum CaseStates {
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}

export const DEFAULT_SUBMIT_TYPES = [
  { label: 'Employer', value: true, disabled: false },
  { label: 'Analyst', value: false, disabled: false },
];

export const duplicateStatuses = {
  SAME_DUE_DATE: 'SAME_DUE_DATE',
  DIFFERENT_DUE_DATE: 'DIFFERENT_DUE_DATE',
  NO_DUPLICATES: 'NO_DUPLICATES',
};

export const getAiStatusDefaultDescription = (status: Statuses) => {
  switch (status) {
    case Statuses.CLIENT:
      return 'Client';
    case Statuses.QUEUED:
      return 'Queued';
    case Statuses.STATE:
      return 'State';
    case Statuses.CLOSED:
      return 'Closed';
    default:
      return 'Review'; // If no other statuses, make Review for fix UTMC historical statuses
  }
};

export interface SupplementalSurveyItem {
  surveyId: string;
  request: string;
  response: string;
}
