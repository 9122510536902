import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { TextMask } from 'form-controls';
import { TemplateRef } from '@angular/core';

export enum FilterType {
  SELECT = 'select',
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  NUMBER = 'number',
  AUTOCOMPLETE = 'autocomplete',
  DATEPICKER = 'datepicker',
  DATETIMEPICKER = 'datetimepicker',
  DATERANGEPICKER = 'daterangepicker',
  LABELS = 'labels',
  HIDDEN = 'hidden',
  TEMPLATE = 'template',
  SSN_AUTOCOMPLETE = 'ssnAutocomplete',
}

export interface SidebarFilter {
  label?: string;
  formControl: { key: string; value: FormControl | FormGroup };
  type: FilterType;
  data?: object | Observable<any>;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: any;
  viewValue?: string;
  keyValue?: string;
  subInfo?: string;
  inputMask?: TextMask;
  valueForRequest?: string;
  deselectable?: boolean;
  clearable?: boolean;
  titlecase?: boolean;
  panelClass?: string;
  template?: TemplateRef<any>;
  id?: string;
  cssClass?: string;
  hideLabel?: boolean;
  resetOptions?: boolean;
}

export interface SidebarButton {
  title?: string;
  icon?: string;
  onClick?: () => void;
  color?: string;
  disabled?: () => boolean;
  id?: string;
  isNoTracking?: () => boolean;
}

export interface SidebarResetButton {
  onClick: () => void;
  disabled?: () => boolean;
  visible?: () => boolean;
}
