import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type StorageType = 'LS' | 'SS';

export interface StorageChange {
  storage: string;
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly STORAGE_KEY: string;

  private readonly STORAGE_NAME_KEY: string;

  private readonly LS: Storage;

  private readonly SS: Storage;

  private readonly DS: StorageType; // Default storage

  private storageSubject = new Subject<object>();

  constructor() {
    this.STORAGE_KEY = 'spa.utmc.';
    this.STORAGE_NAME_KEY = 'storage.name';
    this.LS = window.localStorage;
    this.SS = window.sessionStorage;
    this.DS = 'LS';
  }

  set storageName(name: string) {
    const storageName = btoa(name);
    this.set(this.STORAGE_NAME_KEY, storageName, 'SS');
  }

  get storageName() {
    const storageName = this.get(this.STORAGE_NAME_KEY, 'SS');

    if (storageName) {
      return atob(storageName);
    }

    return null;
  }

  get(key: string, storage: StorageType = this.DS) {
    const item = this[storage].getItem(this.sKey(key));

    try {
      return JSON.parse(item);
    } catch (err: any) {
      return item || null;
    }
  }

  set(key: string, value: any, storage: StorageType = this.DS) {
    let newValue = null;

    try {
      newValue = JSON.stringify(value);
    } catch (err: any) {
      newValue = value;
    }

    this[storage].setItem(this.sKey(key), newValue);

    this.storageSubject.next({
      storage,
      key,
      value,
    });
  }

  removeItem(key: string, storage: StorageType = this.DS) {
    this[storage].removeItem(this.sKey(key));
  }

  clear(storage: StorageType = this.DS) {
    this[storage].clear();
  }

  getStorageObservable(): Observable<object> {
    return this.storageSubject.asObservable();
  }

  private sKey(key: string) {
    return this.STORAGE_KEY + key;
  }
}
