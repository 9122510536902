export interface AiNotificationResponse {
  count: number;
  data: AiNotification[];
}

export interface AiNotification {
  created: string;
  customAttributes: any;
  id: number;
  message: string;
  read: boolean;
  type: string;
}

export enum AiNotificationType {
  SIDES_POST_FAILED = 'SIDES_POST_FAILED',
  GENERIC_DATA_UPDATE = 'GENERIC_DATA_UPDATE',
  PROTESTABLE_BENEFIT_CHARGE = 'PROTESTABLE_BENEFIT_CHARGE',
  MISSING_CASE = 'MISSING_CASE',
  NEW_BC_DOCS_AVAILABLE = 'NEW_BC_DOCS_AVAILABLE',
  SIDES_DUPLICATE_CASE = 'SIDES_DUPLICATE_CASE',
  ACTION_ITEM_SAVE_NOTE = 'ACTION_ITEM_SAVE_NOTE',
  RATE_NOTICES_EMAIL_NOTIFICATION = 'RATE_NOTICES_EMAIL_NOTIFICATION',
  BENEFIT_DENIED = 'BENEFIT_DENIED',
  RELEASE_DEPLOY = 'RELEASE_DEPLOY',
  NOTIFICATION_NOTIFIER = 'NOTIFICATION_NOTIFIER',
}
