/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-cm-checkbox',
  templateUrl: './cm-checkbox.component.html',
  styleUrls: ['./cm-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmCheckboxComponent),
      multi: true,
    },
  ],
})
export class CmCheckboxComponent implements ControlValueAccessor {
  @Input() label: string;

  @Output() change = new EventEmitter();

  @Input() disabled: boolean;

  @Input() checked: boolean;

  val: boolean;

  onChange: (val) => {};

  onTouched: (val) => {};

  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      if (this.onChange) {
        this.onChange(val);
      }
      if (this.onTouched) {
        this.onTouched(val);
      }
    }
  }

  get value() {
    return this.val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
