import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { SidebarService } from '../services/sidebar.service';
import { MenuService } from '../services/menu.service';
import { ScreenService } from '../services/screen.service';

@Component({
  selector: 'cm-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements AfterViewChecked {
  constructor(
    public sidebarService: SidebarService,
    private menuService: MenuService,
    private screenService: ScreenService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
    if (!this.sidebarService.isMinimized && !this.screenService.isLarge()) {
      this.menuService.isMinimized = true;
    }
  }

  isFiltersDirty() {
    return (
      JSON.stringify(Object.entries(this.sidebarService.defaultFormState)) !==
      JSON.stringify(Object.entries(this.sidebarService.filtersForm.value))
    );
  }
}
