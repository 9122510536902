<div [ngClass]="sidebarService.sidebarCssClass || ''">
  <div class="sidebar-toggle-button" (click)="toggleSidebar()">
    <i class="material-icons">{{
      sidebarService.isMinimized ? 'chevron_left' : 'chevron_right'
    }}</i>
  </div>

  <ng-container *ngIf="sidebarService.template">
    <ng-container *ngTemplateOutlet="sidebarService.template"></ng-container>
  </ng-container>

  <ng-container *ngIf="!sidebarService.template">
    <div class="sidebar-content" *ngIf="sidebarService.actionTabs?.length > 0">
      <div class="cm-title sidebar-head">{{ sidebarService.sidebarTitle || 'Contents' }}</div>
      <cm-action-tabs-panel [actionTabs]="sidebarService.actionTabs"></cm-action-tabs-panel>
    </div>

    <div
      class="sidebar-content filters"
      *ngIf="sidebarService.filterFormControls && sidebarService.sidebarFilters.length > 0"
    >
      <div class="cm-title sidebar-head">
        <span>{{
          sidebarService?.sidebarFilterTitle ||
            sidebarService?.sidebarTitle ||
            (sidebarService.sidebarButton ? 'Report' : 'Filters')
        }}</span>
        <div
          class="sidebar-footer-actions"
          style="margin-left: 0.5rem"
          *ngIf="sidebarService?.resetButton && sidebarService?.resetButton.visible()"
        >
          <span class="reset-action" (click)="sidebarService?.resetButton.onClick()">Reset</span>
        </div>
      </div>
      <cm-filter-panel [filters]="sidebarService.sidebarFilters"></cm-filter-panel>

      <div class="sidebar-footer-actions" *ngIf="!sidebarService.sidebarButton">
        <span
          class="reset-action"
          *ngIf="isFiltersDirty()"
          (click)="sidebarService.resetFiltersForm()"
          >Reset</span
        >
      </div>

      <div *ngIf="sidebarService.sidebarButton">
        <lib-utmc-button
          (onClick)="sidebarService.sidebarButton.onClick()"
          [color]="sidebarService.sidebarButton.color"
          [disabled]="
            sidebarService.sidebarButton.disabled ? sidebarService.sidebarButton.disabled() : false
          "
          [isNoTracking]="
            sidebarService.sidebarButton.isNoTracking
              ? sidebarService.sidebarButton.isNoTracking()
              : false
          "
          matomoTrigger
          [triggerId]="sidebarService.sidebarButton.id"
          style="margin-left: 3px"
        >
          <i class="material-icons" *ngIf="sidebarService.sidebarButton.icon">{{
            sidebarService.sidebarButton.icon
          }}</i>
          {{ sidebarService.sidebarButton.title }}
        </lib-utmc-button>
      </div>
    </div>
    <div class="sidebar-content" *ngIf="sidebarService?.rowsInformation && sidebarService?.rowsInformation?.length">
        <div class="cm-title sidebar-head">{{ sidebarService.sidebarTitle || 'Contents' }}</div>
      <cm-raws-info-panel
        [rawsInfo]="sidebarService.rowsInformation"
      ></cm-raws-info-panel>
    </div>
  </ng-container>
</div>
<!--<sidebar-content></sidebar-content>-->
