import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WebSocketService } from '../web-socket.service';
import { LogoutService } from '../logout.service';
import { UserRevokeModalComponent } from '../../shared/modals/user-revoke-modal/user-revoke-modal.component';

@Injectable()
export class UserRevokeService {
  private userRevokeSubscription: Subscription;

  private logoutTimeout: any;

  constructor(
    private webSocketSvc: WebSocketService,
    private logoutService: LogoutService,
    private dialog: MatDialog,
  ) {}

  subscribe() {
    this.userRevokeSubscription = this.webSocketSvc.sockJsSubject
      .pipe(distinctUntilChanged())
      .subscribe((msg: any) => {
        if (!msg) {
          return;
        }

        const obj = JSON.parse(msg);

        if (obj.type === 'USER_REVOKE') {
          const delayInSeconds = obj.countDown || 15;
          const delayInMilliseconds = delayInSeconds * 1000;

          // show logout warning message
          this.showWarningLogoutMessage(delayInSeconds);

          // schedule logout
          this.logoutTimeout = setTimeout(() => {
            this.logoutService.logout('USER_REVOKE');
          }, delayInMilliseconds);
        }
      });
  }

  unsubscribe() {
    this.userRevokeSubscription.unsubscribe();

    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
  }

  private showWarningLogoutMessage(logoutDelayInSeconds: number) {
    this.dialog.open(UserRevokeModalComponent, {
      maxWidth: '400px',
      closeOnNavigation: false,
      data: {
        closeBtn: true,
        likeAPopup: true,
        logoutDelayInSeconds,
      },
    });
  }
}
