<!--<p>-->
<!--  menu-item works!-->
<!--</p>-->

<a
  class="menu-item"
  [routerLink]="item.route"
  [ngClass]="item.cssClass || ''"
  [class.active]="checkActiveLink()"
  (click)="onMenuItemClick()"
  *ngIf="item.route"

>
  <ng-container
    *ngTemplateOutlet="content"
  ></ng-container>
</a>

<a
  class="menu-item"
  [ngClass]="item.cssClass || ''"
  [class.active]="checkActiveLink()"
  (click)="onMenuItemClick()"
  *ngIf="!item.route"

>
  <ng-container
    *ngTemplateOutlet="content"
  ></ng-container>
</a>

<ng-template #content>
  <span class="icon">
    <i class="material-icons">{{ item.materialIcon }}</i>
  </span>

  <span class="title"
  >{{ changeTitleForOtherUsers(item.text)
    }}<span *ngIf="item.subMenu" class="icon"
    ><i class="material-icons">chevron_right</i></span
    ></span
  >

  <div *ngIf="showNewAiCount" class="new-ai-count">
    <span>{{newActionItemsCount}}</span>
  </div>
</ng-template>

<cm-sub-menu
  *ngIf="item.subMenu"
  [items]="item.subMenu"
  class="sub-menu"
  [ngClass]="{ shifted: adaptiveSubmenu && menuService.isMinimized, hidden: !isSubmenuVisible }"
></cm-sub-menu>
