/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { startWith, switchMap } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'lib-cm-chips',
  templateUrl: './cm-chips.component.html',
  styleUrls: ['./cm-chips.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmChipsComponent),
      multi: true,
    },
  ],
})
export class CmChipsComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  myControl = new FormControl();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredOptions: Observable<any>;

  vm: any;

  @Input() observableSourceFunction: (param: {}) => Observable<any>;

  @Input() login;

  @Input() withAutocomplete = true;

  @Input() source;

  @Input() keyValue: string;

  @Input() viewValue = 'name';

  @Input() params = {};

  @Input() disabled: boolean;

  @Input() clearable: boolean;

  @Input() highlightIfSet: boolean;

  @Input() placeholder = 'Pick One';

  @Input() filterList: Array<any>;

  @Input() cursorToBegin = false;

  @Input() chips = [];

  @Input() isViewMode = false;

  @Output() valueChange = new EventEmitter(true);

  private isDomInitialized: boolean;

  private onTouched: () => void;

  private propagateChange: (value) => void;

  aliases: Array<string>;

  @ViewChild('trigger', { static: true }) trigger: MatAutocompleteTrigger;

  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.vm = '';
    this.isDomInitialized = false;
    this.onTouched = () => {};
    this.propagateChange = () => {};

    if (!this.isViewMode && this.withAutocomplete) {
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(this.vm || ' '),
        switchMap((keyword) => {
          const params = {
            [this.viewValue]:
              (keyword && (keyword.name || keyword[this.viewValue])) || keyword || ' ', // <- backend crutch, must be {name: keyword}
            // orgType: this.orgType || null,
            // inService: this.inService || null,
            // haveActionItem: this.clientHaveActionItem,
            ...this.params,
          };
          return this.observableSourceFunction(params);
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    this.isDomInitialized = true;
  }

  writeValue(value): void {
    this.vm = value || '';

    this.myControl.patchValue(this.vm);
  }

  /**
   * Form ControlValueAccessor interface
   * Set the function to be called when the control receives a change event.
   */
  registerOnChange(cb): void {
    this.propagateChange = cb;
  }

  /**
   * Form ControlValueAccessor interface
   * Set the function to be called when the control receives a touch event.
   */
  registerOnTouched(cb): void {
    this.onTouched = cb;
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    if (!this.isDomInitialized) {
      return;
    }
    this.vm = event.option.value === null ? '' : event.option.value;
    // Pass data to form!
    this.propagateChange(event.option.viewValue);
    this.onTouched(); // add .ng-touched class
    this.add({ input: this.input.nativeElement, value: event.option.viewValue });
    this.setCursorToBegin();
  }

  displayProperty(subj): string {
    return subj ? subj[this.viewValue] : undefined;
  }

  onBlur() {
    this.writeValue(this.vm);
  }

  reset() {
    this.writeValue('');
    this.propagateChange(this.vm);
  }

  focus() {
    setTimeout(() => {
      this.trigger.openPanel();
      this.input.nativeElement.focus();
    });
  }

  openAutoCompletePanel() {
    if (this.withAutocomplete) setTimeout(() => this.trigger.openPanel());
  }

  private setCursorToBegin() {
    if (!this.cursorToBegin) {
      return;
    }
    this.input.nativeElement.blur();
    this.input.nativeElement.setSelectionRange(0, 0);
    this.input.nativeElement.focus();
  }

  remove(index): void {
    if (index >= 0) {
      this.chips.splice(index, 1);

      this.myControl.updateValueAndValidity();
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      this.chips.push(value);
    }
    this.input.nativeElement.value = '';
    this.valueChange.emit(this.chips);
    this.myControl.setValue('');
    this.openAutoCompletePanel();
  }
}
