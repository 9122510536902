/* eslint-disable no-underscore-dangle */
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatomoService } from '../services/matomo.service';

export type ActionButtonColor =
  | 'primary'
  | 'primary-inverse'
  | 'danger'
  | 'danger-inverse'
  | 'warning'
  | 'warning-inverse'
  | 'success'
  | 'success-inverse'
  | 'success-inverse-green'
  | 'info'
  | 'info-inverse'
  | 'default';

@Component({
  selector: 'lib-utmc-button',
  templateUrl: './utmc-button.component.html',
  styleUrls: ['./utmc-button.component.css'],
  // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property
  outputs: ['onClick'],
})
export class UtmcButtonComponent implements AfterContentInit {
  // private disabled: boolean;
  // private loading: boolean;
  // private id: string;
  // private onClick: EventEmitter<unknown>;

  constructor(private _elementRef: ElementRef, private matomoService: MatomoService) {
    this.disabled = false;
    this.loading = false;

    this.onClick = new EventEmitter();
  }

  static contentSelector = 'div.content';

  @Input() class;

  @Input() id;

  @Input() type;

  @Input() size;

  @Input() color: ActionButtonColor;

  @Input() loading;

  @Input() disabled;

  @Input() title;

  @Input() isNoTracking;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any>;

  @ViewChild('button', { static: true }) buttonElement: ElementRef;

  triggerClick() {
    if (!this.isNoTracking) {
      this.matomoService.trackEvent({
        category: 'Button - click',
        action: `${document.title} Page - ${this.buttonElement.nativeElement.textContent}`,
      });
    }
    this.onClick.emit();
  }

  createIdFromInnerText(): void {
    if (this.id) {
      return;
    }

    // search span with label if it exist and exclude icons
    let contentElem = this._elementRef.nativeElement.querySelector(
      `${UtmcButtonComponent.contentSelector} :not(i)`,
    );
    // find default element
    if (!contentElem) {
      contentElem = this._elementRef.nativeElement.querySelector(
        UtmcButtonComponent.contentSelector,
      );
    }

    const { innerText } = contentElem;
    this.id = innerText.toLowerCase().split(/\s/).join('_');
  }

  ngAfterContentInit(): void {
    this.createIdFromInnerText();
  }
}
