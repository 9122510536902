/* eslint-disable import/no-cycle */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalVideoData } from 'src/app/models/modal/modal-video-data.model';

@Component({
  selector: 'cms-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss'],
})
export class ModalVideoComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalVideoData) {}
}
